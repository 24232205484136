import React, { useEffect, useContext, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  IconButton,
  Paper,
  Avatar,
  Button,
  Modal,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import { AppContext } from "../App";
import ts2 from "../assets/ts2.png";
import { db } from "../config/firebaseConfig";
import { Instagram, X } from "@mui/icons-material";
import EventScreen from "./EventScreen";

const ArtistProfileScreen = () => {
  const {
    defaultTheme,
    navigate,
    scrollToTop,
    userData,
    setUserData,
    dispatch,
    setSuccess,
    setInfo,
    setError,
    setMessage,
  } = useContext(AppContext);

  const [artist, setArtist] = useState(null);
  const [shows, setShows] = useState(null);
  const [viewShow, setViewShow] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const artistPathName = window.location.pathname.split("/")[2].toLowerCase();

  useEffect(() => {
    const fetchEvents = async (artistId) => {
      try {
        const eventsRef = db.collection("events");

        await eventsRef
          .orderBy("startDate", "asc")
          .get()
          .then((querySnapshot) => {
            let eventsWithArtist = [];
            querySnapshot.forEach((doc) => {
              const eventData = doc.data();
              const hasArtist =
                eventData.artists?.some((artist) => artist.uid === artistId) ||
                eventData.artistId === artistId;
              if (hasArtist) {
                eventsWithArtist.push(eventData);
              }
            });
            console.log("Events with the artist:", eventsWithArtist);
            let eventsData = eventsWithArtist.slice();
            let confirmedEventsData = eventsData.filter(
              (x) =>
                x.artists.find((artist) => artist.uid === artistId).confirmed
            );
            const nonTestEvents = confirmedEventsData.filter(
              (event) => !event.test
            );
            console.log("non test events =>", nonTestEvents);
            const nonHiddenEvents = nonTestEvents.filter(
              (event) => !event.hidden
            );
            console.log("non hidden events =>", nonHiddenEvents);
            const currentEvents = nonHiddenEvents.filter(
              (e) =>
                new Date(e.startDate.seconds * 1000).getTime() >
                new Date().getTime()
            );
            console.log("current events =>", currentEvents);

            setShows(currentEvents);
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      } catch (error) {
        console.log(error);
      }
    };

    if (!artist) {
      db.collection("artists")
      // .collection("users")
        .where("profilePath", "==", artistPathName)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            setInfo(true);
            setMessage("Artist not found");
          } else {
            setArtist(querySnapshot.docs[0].data());
          }
        });
    }

    if (artist && !shows) {
      fetchEvents(artist.uid);
    }
  }, [artist, shows]);

  return (
    <Box sx={{ mt: 8 }}>
      {artist && (
        <>
          <Box
            sx={{
              backgroundColor: defaultTheme.palette.grey.light,
              padding: "20px 0",
            }}
          >
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                  <Box>
                    <Avatar
                      src={artist.imageUrl}
                      alt="Profile Image"
                      sx={{ width: 150, height: 150, margin: "0 auto" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "center", md: "flex-start" },
                      gap: 1,
                    }}
                  >
                    <IconButton component="a" href="" target="_blank">
                      <Instagram />
                    </IconButton>
                    <IconButton component="a" href="" target="_blank">
                      <FacebookIcon />
                    </IconButton>
                    <IconButton component="a" href="" target="_blank">
                      <X />
                    </IconButton>
                    {/* <IconButton component="a" href="" target="_blank">
                      <YouTubeIcon />
                    </IconButton> */}
                    <IconButton component="a" href="" target="_blank">
                      <MusicNoteIcon />
                    </IconButton>
                  </Box>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      textAlign: { sx: "center", md: "left" },
                    }}
                  >
                    {artist.name}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      marginTop: 1,
                      justifyContent: { xs: "center", md: "flex-start" },
                    }}
                  >
                    <Paper
                      sx={{
                        padding: "4px 8px",
                        backgroundColor: defaultTheme.palette.primary.main,
                        color: "#fff",
                        borderRadius: 4,
                      }}
                    >
                      Genre: {artist.genre ? artist.genre : "Not Specified"}
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Container style={{ padding: 0, minHeight: "80dvh" }}>
            <Grid container>
              <Grid item xs={12} sm={5} md={4} lg={3} sx={{}}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: 2,
                    background: defaultTheme.palette.primary.main,
                    padding: 2,
                    color: "white",
                  }}
                >
                  SHOW DATES
                </Typography>
                <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        marginBottom: 1,
                        borderBottom: "1px solid lightgrey",
                      }}
                    >
                      GET TICKETS{" "}
                      <span
                        style={{ color: defaultTheme.palette.secondary.main }}
                      >
                        NOW!
                      </span>
                    </Typography>

                    {shows && shows.length > 0 ? (
                      shows.slice(0, 2).map((event) => {
                        const { title, date, location, image, id, teaser } =
                          event;
                        return (
                          <Grid
                            item
                            container
                            xs={12}
                            style={{
                              alignItems: "center",
                              borderBottom: "1px solid white",
                              padding: "10px",
                            }}
                          >
                            <Grid item container xs={12}>
                              <img
                                style={{
                                  maxHeight: 300,
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                                src={image}
                              />
                            </Grid>
                            <Grid item container xs={12}>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: defaultTheme.palette.black,
                                  fontWeight: "bold",
                                  width: "100%",
                                  textAlign: "left",
                                }}
                              >
                                {new Date(
                                  event.startDate.seconds * 1000
                                ).toLocaleDateString()}
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  color: defaultTheme.palette.black,
                                  fontWeight: "bold",
                                  textAlign: "left",
                                }}
                              >
                                {title}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  textAlign: { sx: "center", md: "left" },
                                }}
                              >
                                {teaser.slice(0, 50)}
                              </Typography>
                            </Grid>
                            <Grid item container xs={12}>
                              <Button
                                onClick={() => {
                                  setSelectedEvent(event);
                                  setViewShow(true);

                                  //   navigate(`/events/${url(title, id)}`);
                                  //   scrollToTop();
                                }}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="small"
                                style={{ borderRadius: 40, height: 36 }}
                              >
                                Details
                              </Button>
                            </Grid>
                          </Grid>
                        );
                      })
                    ) : (
                      <>
                        <Typography variant="h5" sx={{ marginBottom: 2 }}>
                          No upcoming shows.
                        </Typography>
                        <Box sx={{ textAlign: "center", marginBottom: 2 }}>
                          <img
                            src={ts2}
                            alt="No Shows"
                            style={{ width: "100%", opacity: 0.65 }}
                          />
                        </Box>
                        <Typography
                          variant="h5"
                          sx={{ textAlign: "center", marginBottom: 2 }}
                        >
                          PLEASE COME BACK SOON AND CHECK AGAIN
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={7} md={8} lg={9} sx={{ padding: 2 }}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexFlow: "column",
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            width: "100%",
                            textAlign: {
                              xs: "center",
                              md: "left",
                              fontWeight: "bold",
                            },
                          }}
                        >
                          OVERVIEW
                        </Typography>
                        <Typography variant="body1" sx={{ textAlign: "left" }}>
                          {artist.bio}
                        </Typography>
                      </Box>
                      <Box sx={{ marginTop: 3 }}>
                        <Typography
                          variant="h5"
                          sx={{
                            width: "100%",
                            textAlign: {
                              xs: "center",
                              md: "left",
                              fontWeight: "bold",
                            },
                          }}
                        >
                          VIDEOS
                        </Typography>
                        <Grid container spacing={2} sx={{ marginTop: 1 }}>
                          <Grid item xs={12} md={8}>
                            {artist?.videos?.length > 0 ? (
                              artist?.videos?.map((video, idx) => {
                                return (
                                  <Box>
                                    <iframe
                                      width="100%"
                                      height="315"
                                      src="https://www.youtube.com/embed/GnzFycWyRKk"
                                      title="YouTube Video"
                                      frameBorder="0"
                                      allowFullScreen
                                    ></iframe>
                                  </Box>
                                );
                              })
                            ) : (
                              <Typography
                                sx={{ textAlign: { xs: "center", md: "left" } }}
                              >
                                This artist has not uploaded videos yet.
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                      {/* <Box sx={{ marginTop: 3 }}>
                        <Typography variant="h4">Music</Typography>
                        
                      </Box> */}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </>
      )}

      <Modal open={viewShow} onClose={() => setViewShow(false)}>
        <Grid
          item
          container
          xs={11}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            borderRadius: 12,
            background: "white",
            padding: 20,
            overflowY: "scroll",
            maxHeight: "85dvh",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <EventScreen previewEvent={selectedEvent} />
        </Grid>
      </Modal>
    </Box>
  );
};

export default ArtistProfileScreen;
