import { InfoOutlined } from "@mui/icons-material";
import { Typography, Grid, Tooltip } from "@mui/material";
import React from "react";

const OrderSummary = ({ subTotal, fees, tax, items, ticketCounts }) => {
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: 20,
      }}
    >
      <Typography style={{ fontWeight: "bold", marginBottom: 20 }}>
        Order Summary
      </Typography>
      <Grid
        container
        xs={12}
        style={{
          borderBottom: "1px solid lightgrey",
        }}
      >
        {ticketCounts.map((count, idx) => {
          if (count > 0) {
            return (
              <Grid
                item
                container
                xs={12}
                style={{
                  padding: 10,
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  {count}x {items[idx].name}
                </Typography>
                <Typography>
                  ${(count * items[idx].price).toFixed(2)}
                </Typography>
              </Grid>
            );
          }
        })}
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          padding: 10,
          justifyContent: "space-between",
        }}
      >
        <Typography style={{}}>Subtotal</Typography>
        <Typography style={{}}>${subTotal.toFixed(2)}</Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          padding: 10,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant='caption' style={{}}>
          Fees{" "}
          <Tooltip title="TicketShare fees are non-refundable">
            <InfoOutlined style={{ marginLeft: 5, fontSize: 14 }} />
          </Tooltip>
        </Typography>
        <Typography variant='caption' style={{}}>${fees.toFixed(2)}</Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          padding: 10,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <Typography variant='caption' style={{}}>
          Taxes{" "}
          <Tooltip title="Tax rates based on location and sub total.">
            <InfoOutlined style={{ marginLeft: 5, fontSize: 14 }} />
          </Tooltip>
        </Typography>
        <Typography variant='caption' style={{}}>${tax?.toFixed(2)}</Typography> */}
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          padding: 10,
          justifyContent: "space-between",
          borderTop: "1px solid lightgrey",
          marginTop: 20,
        }}
      >
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          Total
        </Typography>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          ${(subTotal + fees 
            // + tax
            ).toFixed(2)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OrderSummary;
