import PropTypes from "prop-types";
import React from "react";
// material-ui
import {
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Grid,
  Tooltip,
  TablePagination,
} from "@mui/material";

// third-party
import { NumericFormat } from "react-number-format";

// project import
import Dot from "./@extended/Dot";

function createData(
  purchase_date,
  order_no,
  purchaser_name,
  show,
  total_tix,
  earnings
) {
  return { purchase_date, order_no, purchaser_name, show, total_tix, earnings };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  if (stabilizedThis) {
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  return [];
}

const headCells = [
  {
    id: "order_no",
    align: "left",
    disablePadding: false,
    label: "Order No.",
  },
  {
    id: "purchase_date",
    align: "left",
    disablePadding: false,
    label: "Date",
  },
  {
    id: "purchaser_name",
    align: "left",
    disablePadding: true,
    label: "Purchaser Name",
  },
  {
    id: "show",
    align: "left",
    disablePadding: false,
    label: "Show",
  },
  {
    id: "total_tix",
    align: "right",
    disablePadding: false,
    label: "Tickets",
  },
  {
    id: "earnings",
    align: "right",
    disablePadding: false,
    label: "Revenue",
  },
];

// ==============================|| ORDER TABLE - HEADER ||============================== //

function OrderTableHead({ order, orderBy }) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

OrderTableHead.propTypes = { order: PropTypes.any, orderBy: PropTypes.string };

function OrderStatus({ status }) {
  let color;
  let title;

  switch (status) {
    case 0:
      color = "warning";
      title = "Pending";
      break;
    case 1:
      color = "success";
      title = "Approved";
      break;
    case 2:
      color = "error";
      title = "Rejected";
      break;
    default:
      color = "primary";
      title = "None";
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Dot color={color} />
      <Typography>{title}</Typography>
    </Stack>
  );
}

OrderStatus.propTypes = { status: PropTypes.number };

// ==============================|| ORDER TABLE ||============================== //

export default function OrderTable({ orders }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const order = "desc";
  const orderBy = "purchase_date";

  console.log("ORDERS => ", orders);

  const rows = orders?.map((order) => {
    let purchaseDate = new Date(
      order.purchaseDate.seconds * 1000
    ).toLocaleDateString();

    let amtOfTix = order.tickets.length;

    return createData(
      purchaseDate,
      order.orderNumber,
      order.purchaserName,
      order.eventName,
      order.tickets.length,
      order.orderTotal
    );
  });

  console.log("ROWS => ", rows);

  return (
    <Box sx={{ height: "100%" }}>
      <Grid
        item
        container
        xs={12}
        style={{
          overflow: "hidden",
          height: "inherit",
          justifyContent: "flex-end",
        }}
      >
        <TableContainer
          sx={{
            // width: "100%",
            // overflowX: "auto",
            // position: "relative",
            // display: "block",
            // maxWidth: "100%",
            maxHeight: "calc(100% - 60px)",
            // "& td, & th": { whiteSpace: "nowrap" },
          }}
        >
          <Table stickyHeader aria-labelledby="tableTitle">
            <OrderTableHead order={order} orderBy={orderBy} />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      tabIndex={-1}
                      key={row.order_no}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        style={{
                          cursor: "pointer",
                          // strikethrough if row.refunded
                          color: row.refunded ? "red" : "inherit",
                        }}
                      >
                        <Link color="secondary" style={{ cursor: "pointer" }}>
                          {" "}
                          {row.order_no}
                        </Link>
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        style={{ cursor: "pointer" }}
                      >
                        {row.purchase_date}
                      </TableCell>
                      <TableCell>{row.purchaser_name}</TableCell>
                      <Tooltip title={row.show}>
                        <TableCell>
                          {row.show.length > 25
                            ? row.show.slice(0, 22) + "..."
                            : row.show}
                          {/* <OrderStatus status={row.show} /> */}
                        </TableCell>
                      </Tooltip>
                      <TableCell align="right">{row.total_tix}</TableCell>
                      <TableCell align="right">
                        <NumericFormat
                          value={row.earnings}
                          displayType="text"
                          thousandSeparator
                          prefix="$"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[8, 25, 100]}
          component="div"
          count={rows?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Box>
  );
}

OrderTable.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      orderNumber: PropTypes.string.isRequired,
      purchaserName: PropTypes.string.isRequired,
      eventName: PropTypes.string.isRequired,
      tickets: PropTypes.arrayOf(PropTypes.object).isRequired,
      orderTotal: PropTypes.number.isRequired,
    })
  ).isRequired,
};
