import React, {useContext} from 'react'
import {
    Typography,
    Grid,
    Card,
    CardContent,
    Button,
  } from "@mui/material";
  import { Link } from "react-router-dom";
import { AppContext } from '../App';

export const EventPreviewCard = ({event, lowestTicket}) => {
      const { defaultTheme } = useContext(AppContext);
    
  return (
    <Card>
                  <CardContent style={{ padding: 0 }}>
                    <Grid
                      item
                      container
                      xs={12}
                      sx={{ height: { xs: 150, md: "25dvh" } }}
                    >
                      <img
                        src={event.image}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      sx={{
                        p: 2,
                        height: { xs: "30dvh", md: "25dvh" },
                        position: "relative",
                      }}
                    >
                      <Grid item container xs={12} sx={{ height: "fit-content" }}>
                        <Typography variant="h6" style={{ textAlign: "left", width:'100%' }}>
                          {event.title}
                        </Typography>
                        <Typography variant="body2" style={{ textAlign: "left", width:'100%' }}>
                          {/* {new Date(event.startDate.seconds * 1000).toLocaleDateString()} | {event.location} */}
                          {event.startDate?.seconds ? new Date(event.startDate.seconds * 1000).toLocaleDateString("en-US",{month: "short", day: "numeric", year:'2-digit'}) : new Date(event.startDate).toLocaleDateString("en-US",{month: "short", day: "numeric", year:'2-digit'})} | {event.location}
                        </Typography>
                        
                        <Typography
                          style={{ textAlign: "left", fontWeight:'bold', color: defaultTheme.palette.secondary.main  }}
                        >
                          {lowestTicket === 0 ? 'FREE +' : `From $${lowestTicket} +`}
                        </Typography>
                      </Grid>
                      <Button
                        variant="contained"
                        size="small"
                        fullWidth
                        sx={{
                          marginTop: 1,
                          height: "38px",
                          
                          borderRadius: 40,
                        }}
                        component={Link}
                        to={`/events/${event.title
                          .toLowerCase()
                          .split(" ")
                          .join("-")}_ev_${event.id}`}
                      >
                        View Details
                      </Button>
                    
                    </Grid>
                  </CardContent>
                </Card>
  )
}
