import React, { useContext, useState } from "react";
import {
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import { Grid } from "@mui/material";
import { AppContext } from "../App";
import { db } from "../config/firebaseConfig";

const EventComments = ({ comments, event, setComments }) => {
  const { userData, setMessage, setError, setSuccess } = useContext(AppContext);
  const [text, setText] = useState("");

  console.log("EVENT", userData);

  const fetchNewComments = async () => {
    const commentsRef = db
      .collection("events")
      .doc(event.id)
      .collection("comments");
    await commentsRef
      .orderBy("createdAt", "desc")
      .get()
      .then((snapshot) => {
        const comments = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        setComments(comments);
      });
  };

  const handlePostComent = async () => {
    const comment = {
      post: text,
      name: userData.name,
      uid: userData.uid,
      imageUrl: userData.imageUrl,
      createdAt: Date.now(),
      thumbsUp: 0,
      thumbsDown: 0,
      replies: [],
    };

    await db
      .collection("events")
      .doc(event.id)
      .collection("comments")
      .add(comment)
      .then(() => {
        setSuccess(true);
        setMessage("Comment posted successfully");
        setText("");
      });

    fetchNewComments();
  };

  const deleteComment = async (doc) => {
    console.log("THIS IS THE DOC,", doc);
    await db
      .collection("events")
      .doc(event.id)
      .collection("comments")
      .doc(doc.id)
      .delete()
      .then(() => {
        setSuccess(true);
        setMessage("Comment deleted successfully");
      });
    fetchNewComments();
  };

  return (
    <Grid item container xs={12}>
      <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
        Comments
      </Typography>

      {userData?.guest ? (
        <Grid
          item
          container
          xs={12}
          style={{ justifyContent: "space-between" }}
        >
          <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
            Please sign in to leave a comment.
          </Typography>
          <Button variant="contained" onClick={() => {}}>
            Sign In
          </Button>
        </Grid>
      ) : (
        <Grid
          item
          container
          xs={12}
          style={{ marginBottom: "1rem", justifyContent: "flex-end" }}
        >
          <TextField
            name="comment"
            value={text}
            onChange={(e) => setText(e.target.value)}
            variant="outlined"
            fullWidth
            label="Comment"
            multiline
            rows={3}
            sx={{ marginBottom: "1rem" }}
          />
          <Button
            variant="contained"
            onClick={handlePostComent}
            disabled={!text || text.trim() === ""}
          >
            Post
          </Button>
        </Grid>
      )}

      {comments?.length > 0 ? (
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {comments.map((comment) => {
            return (
              <>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt={comment.name} src={comment.imageUrl} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={comment.name}
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {new Date(comment.createdAt).toDateString()}
                        </Typography>
                        <br />
                        <br />
                        <span style={{ whiteSpace: "pre-line" }}>
                          {comment.post}
                        </span>
                      </React.Fragment>
                    }
                  />
                  {userData?.uid === comment?.uid && (
                    <Button onClick={() => deleteComment(comment)}>
                      Delete
                    </Button>
                  )}
                </ListItem>
                <Divider />
              </>
            );
          })}
        </List>
      ) : (
        <Typography variant="body1" sx={{ marginBottom: "1rem" }}>
          No comments yet. Be the first to comment!
        </Typography>
      )}
    </Grid>
  );
};

export default EventComments;
