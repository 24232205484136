import React, { useState } from "react";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const faqs = [
  {
    question: "What is TicketShare?",
    answer:
      "TicketShare is a platform that allows artists and event organizers to sell tickets directly to fans, providing a streamlined and efficient way to manage ticket sales and track revenue.",
  },
  {
    question: "How do I sign up for TicketShare?",
    answer:
      "You can sign up for TicketShare by visiting our website and creating an account. You’ll need to provide some basic information to get started.",
  },
  {
    question: "What types of users does TicketShare support?",
    answer:
      "TicketShare supports performing artists & other organizers (event creators), promoters, and customers (event-goers).",
  },
  {
    question: "Is there a fee for creating events?",
    answer:
      "No, creating events on TicketShare is free. However, all non-free tickets are subject to a ticketing fee and a Stripe payment processing fee.",
  },
  {
    question: "Who pays the ticketing fee?",
    answer:
      "The ticketing fee can be either absorbed by the organizer or passed on to the customer during the ticket purchase process.",
  },
  {
    question: "How are payouts handled?",
    answer:
      "Payouts are initiated automatically between 1-5 business days post-event and are sent via direct deposit or check, depending on the organizer’s preference.",
  },
  {
    question: "What information is needed for payouts?",
    answer:
      "Organizers need to provide their preferred payout method (direct deposit or check) and the necessary banking or mailing details.",
  },
  {
    question: "How does the promoter revenue split work?",
    answer:
      "If an organizer works with a promoter, the revenue split is based on the percentage of tickets sold by the organizer: Organizer sells under 35% of all tickets: 8% of revenue, Organizer sells 35%-74% of all tickets: 14% of revenue, Organizer sells above 74% of all tickets: 20% of revenue.",
  },
  {
    question: "What tools do promoters have access to?",
    answer:
      "Promoters have access to dashboards with real-time data on ticket sales and event performance.",
  },
  {
    question: "How do I create an event on TicketShare?",
    answer:
      "Log in to your account, navigate to the event creation section, and fill out the required details, such as event name, date, location, and ticket types.",
  },
  {
    question: "Can I manage multiple events?",
    answer:
      "Yes, you can manage multiple events from your organizer dashboard.",
  },
  {
    question: "How do I track my ticket sales?",
    answer:
      "You can track your ticket sales in real-time through your organizer dashboard, which provides detailed analytics and reports.",
  },
  {
    question: "How do I check-in guest tickets?",
    answer:
      "The ticket holder will need to either present their ticket's qr code for the event coordinator to scan OR click the direct link in their email and present the check-in sceen from thier device. The person responsible for checking in guest will then check-in the guest via using the slidebar on the screen.",
  },
  {
    question: "How do I buy tickets on TicketShare?",
    answer:
      "Visit the event page, select the number of tickets you want, and proceed to checkout. You can pay using various payment methods supported by Stripe.",
  },
  {
    question: "Can I get a refund for my ticket?",
    answer:
      "Refund policies are determined by the event organizer. Please check the specific event's refund policy or contact the organizer directly for assistance.",
  },
  {
    question: "How will I receive my tickets?",
    answer:
      "Tickets are delivered electronically via email. You can also access your tickets through your TicketShare account.",
  },
  {
    question: "What happens if an event is canceled?",
    answer:
      "If an event is canceled, the organizer will typically provide a refund or reschedule the event. Please check the specific event's cancellation policy or contact the organizer for more details.",
  },
  {
    question: "How do I contact TicketShare support?",
    answer:
      "You can contact our support team through the Contact Us section on our website or via the email provided in your account settings.",
  },
  {
    question: "Are there any restrictions on the type of events I can create?",
    answer:
      "While TicketShare supports a wide range of events, all events must comply with our Terms of Service and local laws and regulations.",
  },
  {
    question:
      "Can I change the details of my event after it has been published?",
    answer:
      "Yes, you can update event details through your organizer dashboard. However, significant changes should be communicated to ticket holders promptly.",
  },
  {
    question: "How do I become a promoter on TicketShare?",
    answer:
      "To become a promoter, sign up on our platform and indicate your interest in promoting events. You will then have the ability to be linked to events and track ticket sales.",
  },
];

const FAQs = () => {
  const [userType, setUserType] = useState("");
  return (
    <Container maxWidth="md">
      <Grid container spacing={3} style={{ padding: "120px 0px 40px 0px" }}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center" gutterBottom>
            Frequently Asked Questions
          </Typography>
          <Typography
            align="center"
            gutterBottom
            style={{ margin: "20px 0px", width: "100%" }}
          >
            Learning how to use TicketShare is the first step in successfully
            planning your event. <br />
            Let these TicketShare questions and FAQs for events be your guide to
            everything that this platform has to offer.
            <br />
            Discover how to build your event website and sell and scan tickets
            to host a successful event.
          </Typography>
        </Grid>

        {faqs.map((faq, index) => (
          <Grid item xs={12} key={index}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography variant="h6">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default FAQs;
