import React, { useContext, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  ShoppingBasket,
  ShoppingCart,
} from "@mui/icons-material";
import { AppContext } from "../App";
import { db, auth } from "../config/firebaseConfig";
import logoWide from "../assets/TicketShareLogo2.png";
import logoIcon from "../assets/TicketShareLogo3.png";

const MenuBar = () => {
  const {
    userData,
    setCurrentUser,
    setUserData,
    dispatch,
    setInfo,
    setMessage,
    deviceType,
    scrollToTop,
    navigate,
    defaultTheme
  } = useContext(AppContext);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const pages = userData?.guest
    ? [
        "Find Events", "Create Events",
        // "Contact",
      ]
    : ["Find Events", "Create Events"];
  const settings = userData
    ? userData?.roles?.includes("artist") ||
      userData?.roles?.includes("promoter") ||
      userData?.roles?.includes("venue owner") ||
      userData?.roles?.includes("agent")
      ? [
          "Dashboard",
          //   "Tickets",
          "Account",
          "Logout",
        ]
      : deviceType === "mobile" && !userData.guest
      ? ["Account", "Logout"]
      : !userData.guest
      ? ["Tickets", "Account", "Logout"]
      : ["Login / Register"]
    : ["Login / Register"];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    if (typeof page === "string") {
      if (page.toLowerCase() === "home") {
        navigate(`/`);
      } else if (page.toLowerCase().includes("find")) {
          navigate(`/events`);
        }else if (page.toLowerCase().includes("create")){
          navigate(`/promoter`);
        } else {
          navigate(`/${page.toLowerCase()}`);
        }
    }
    scrollToTop();
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (page) => {
    if (typeof page === "string") {
      if (!userData) {
        navigate(`/auth`);
        // navigate(`/auth-artist`);
        scrollToTop();
      } else if (page === "Logout") {
        auth.signOut().then(() => {
          setInfo(true);
          setMessage("You have been logged out successfuly");
          dispatch(setUserData(null));
          dispatch(setCurrentUser(null));
          navigate(`/`);
          scrollToTop();
        });
      } else if (page === "Login / Register") {
        navigate(`/auth`);
        // navigate(`/auth-artist`);
        scrollToTop();
      } else {
        navigate(`/${page.toLowerCase()}`);
        scrollToTop();
      }
    }

    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" sx={{ height: "60px", backgroundColor:'white' }}>
      <Container maxWidth="100%">
        <Toolbar disableGutter sx={{ p: 0 }}>
          
          <Box
            sx={{
              width: "fit-content",
              height: "fit-content",
              display: { xs: "none", md: "flex" },
            }}
          >
            <img
              src={logoWide}
              onClick={() => {
                navigate(`/`);
                scrollToTop();
              }}
              style={{
                height: "35px",
                width: "auto",
                marginRight: 10,
                cursor: "pointer",
              }}
            />
          </Box>

        {/* =============================MOBILE VIEW============================= */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon style={{color:defaultTheme.palette.primary.main}}/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          
        {/* =============================WEB VIEW============================= */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
              size='small'
              color='grey'
              variant='outlined'
                key={page}
                onClick={() => handleCloseNavMenu(page)}
                sx={{ my: 2, display: "block", px: 1 }}
        
                style={{color: defaultTheme.palette.black, fontSize:'10px', borderRadius:30, marginRight:5}}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <Button
              size='small'
              color='grey'
              variant='outlined'
                onClick={handleOpenUserMenu}
                style={{color: defaultTheme.palette.black, fontSize:'10px', borderRadius:30, marginRight:5}}
                sx={{ px: 1 }}
              >
                <Avatar
                  alt=""
                  src={userData?.imageUrl}
                  style={{ marginRight: 5, height:20, width:20 }}
                />
                {userData ? userData?.name?.split(" ")[0] : ""}
                {!anchorElUser ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
              </Button>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleCloseUserMenu(setting)}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* <Box sx={{ flexGrow: 0, marginLeft: 1 }}>
            <Tooltip title="Cart">
              <IconButton onClick={() => {}} style={{ background: "white" }}>
                <ShoppingCart />
              </IconButton>
            </Tooltip>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MenuBar;
