// src/components/Settings.js
import React from "react";
import { Box, Typography, Switch, FormControlLabel } from "@mui/material";

const Settings = () => {
  return (
    <Box sx={{ width: "100%", maxWidth: "400px", margin: "0 auto" }}>
      <Typography variant="h4" sx={{ marginBottom: "1rem" }}>
        Settings
      </Typography>
      <FormControlLabel
        control={<Switch />}
        label="Receive Notifications"
        sx={{ marginBottom: "1rem" }}
      />
      <FormControlLabel
        control={<Switch />}
        label="Enable Dark Mode"
        sx={{ marginBottom: "1rem" }}
      />
    </Box>
  );
};

export default Settings;
