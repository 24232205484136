// src/screens/SupportScreen.js
import React from "react";
import { Box } from "@mui/material";
import HelpCenter from "../components/HelpCenter";
import ContactSupport from "../components/ContactSupport";

const SupportScreen = () => {
  return (
    <Box sx={{ padding: "1rem" }}>
      <HelpCenter />
      <ContactSupport />
    </Box>
  );
};

export default SupportScreen;
