// src/screens/EventScreen.js
import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Box,
  Button,
  Grid,
  Modal,
  Typography,
  IconButton,
} from "@mui/material";
import EventOverview from "../components/EventOverview";
import Ticket from "../components/Ticket";
import PurchaseButton from "../components/PurchaseButton";
import CheckoutModal from "../components/CheckoutModal";
import { AppContext } from "../App";
import SignUp from "../components/SignUp";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { ContentCopy } from "@mui/icons-material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import EventComments from "../components/EventComments";
import { db } from "../config/firebaseConfig";
import { motion } from "framer-motion";
import {
  Expand,
  Minimize,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

const EventScreen = ({ previewEvent }) => {
  const {
    events,
    userData,
    navigate,
    getTicketFee,
    setInfo,
    setMessage,
    deviceType,
  } = useContext(AppContext);
  const [event, setEvent] = useState(null);
  const [eventHost, setEventHost] = useState(null);
  const [isArtistPage, setIsArtistPage] = useState(false);
  const [ticketCounts, setTicketCounts] = useState([]);
  const [total, setTotal] = useState(null);

  const [clientSecret, setClientSecret] = useState("");
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isExpanded, setIsExpanded] = useState(
    deviceType === "mobile" || false
  );

  const [comments, setComments] = useState(null);

  const [taxCalculated, setTaxCalculated] = useState(false)
  

  const tixRef = useRef(null);

  const path = window.location.pathname;
  const url = window.location.href.split("/success")[0];

  console.log(path);

  const fetchEventHost = async () => {
    const host = event?.promoter;
    const { uid } = host;
    let hostDoc = await db
      .collection("artists")
      // .collection("users")
      .doc(uid)
      .get()
      .then((doc) => doc.data());

    if (!hostDoc) {
      hostDoc = await db
        .collection("promoters")
        .doc(uid)
        .get()
        .then((doc) => doc.data());
    }

    setEventHost(hostDoc);
  };

  useEffect(() => {
    if (!event) {
      console.log("we are looking for the event!");

      const selectedEvent = previewEvent
        ? previewEvent
        : events.find((e) => path.split("/")[2].includes(e.id));
      if (selectedEvent) {
        setEvent(selectedEvent);
        if (
          path.split("/")[1] === "artists"
          //   path.split("/").length >= 4 &&
          //   !path.split("/")[path.split("/").length - 1].includes("success")
        ) {
          console.log(path.split("/")[path.split("/").length - 1]);
          setIsArtistPage(true);
          let artistUID = selectedEvent?.artists?.find(
            (x) =>
              x.profilePath.trim() ===
              path.split("/")[path.split("/").length - 1].trim()
          )?.uid;
          //   let artistUID = path.split("/")[path.split("/").length - 1];
          console.log(
            "artistUID ",
            artistUID,
            "ticket counts ",
            selectedEvent?.artists
              ?.find((artist) => artist.uid === artistUID)
              ?.assignedTickets.map(() => 0)
          );
          //   setTicketCounts(
          // selectedEvent?.artists
          //   ?.find((artist) => artist.uid === artistUID)
          //   ?.assignedTickets.map(() => 0)
          //   );
          setTicketCounts(selectedEvent.tickets.map(() => 0));
        } else {
          setTicketCounts(selectedEvent.tickets.map(() => 0));
        }
        if (path.split("/").length >= 4) {
          console.log("this is where we are");
          const success = path.split("/")[3].includes("success");
          if (success) {
            setShowSuccess(true);
          }
        }
      }
    } else {
      if (path.split("/").length >= 4) {
        console.log("this is where we are");
        const success = path
          .split("/")
          [path.split("/").length - 1].includes("success");
        if (success) {
          setShowSuccess(true);
        }
      } else {
        console.log("we are looking for the artist?");
      }
      if (comments === null) {
        db.collection("events")
          .doc(event.id)
          .collection("comments")
          .orderBy("createdAt", "desc")
          .get()
          .then((snapshot) => {
            const comments = snapshot.docs.map((doc) => doc.data());
            setComments(comments);
          });
      }
      if (!eventHost) {
        console.log("fetching the host");
        fetchEventHost();
      }
    }
    console.log("event tickets =>", event?.tickets);
    console.log("event host =>", eventHost);
  }, [event, eventHost]);

  useEffect(() => {
    if (ticketCounts.length > 0) {
      let total = 0;
      ticketCounts.forEach((count, idx) => {
        if (event.tickets[idx].price !== 0) {
          //!Add ticket tax here?
          total +=
            count *
            (event.tickets[idx].price + getTicketFee(event.tickets[idx].price));
        } else {
          total += count * event.tickets[idx].price;
        }
      });
      console.log("TOTAL", total);
      setTotal(total);
    }
  }, [ticketCounts]);

  useEffect(() => {
    console.log("Thie client secret is =>", clientSecret);
    if (clientSecret) {
      setLoading(false);
    }
  }, [clientSecret]);

  return (
    <Grid
      item
      container
      xs={12}
      md={10}
      lg={9}
      xl={8}
      sx={{
       
        paddingTop: 1,
        flexFlow: "column",
        background: "white",
      }}
    >
      {event && (
        <>
          <Box sx={{ marginBottom: "2rem" }}>
            <motion.div
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
              animate={{
                height:
                  deviceType === "mobile"
                    ? isExpanded
                      ? 400
                      : 200
                    : isExpanded
                    ? "70dvh"
                    : "45dvh",
              }}
              style={{
                md: "38dvh",
                padding: 10,
                width: "100%",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <motion.div
                style={{
                  position: "absolute",
                  zIndex: 1,
                  top: 10,
                  left: 10,
                  borderRadius: 12,
                  width: "calc(100% - 20px)",
                  height: "calc(100% - 20px)",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                whileHover={{
                  background: "#00000035",
                }}
              >
                {isExpanded ? (
                  <VisibilityOff style={{ color: "white" }} />
                ) : (
                  <Expand style={{ color: "white" }} />
                )}
              </motion.div>
              <img
                src={event.image || ""}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: 12,
                }}
              />
            </motion.div>
          </Box>
          <EventOverview event={event} />
          <Grid item container xs={12} direction={"row"}>
            <Grid item container xs={12} md={8}>
              {event && (
                <Ticket
                  tixRef={tixRef}
                  event={event}
                  eventTickets={
                    // !isArtistPage ?
                    event?.tickets
                    //   : event.artists?.find(
                    //       (artist) =>
                    //         artist.profilePath ===
                    //         path.split("/")[path.split("/").length - 1]
                    //       //   artist.uid ===
                    //       //   path.split("/")[path.split("/").length - 1]
                    //     )?.assignedTickets
                  }
                  setTaxCalculated={setTaxCalculated}
                  ticketCounts={ticketCounts}
                  setTicketCounts={setTicketCounts}
                />
              )}
            </Grid>
            <Grid
              item
              container
              xs={4}
              sx={{ display: { xs: "none", md: "flex" } }}
              style={{ padding: 10 }}
            >
              {event && (
                <PurchaseButton
                  loading={loading}
                  setLoading={setLoading}
                  userData={userData}
                  clientSecret={clientSecret}
                  setClientSecret={setClientSecret}
                  ticketCounts={ticketCounts}
                  event={event}
                  eventTickets={
                    // !isArtistPage ?
                    event?.tickets
                    //   : event.artists?.find(
                    //       (artist) =>
                    //         artist.profilePath ===
                    //         path.split("/")[path.split("/").length - 1]
                    //       //   artist.uid === path.split("/")[path.split("/").length - 1]
                    //     )?.assignedTickets
                  }
                  total={total}
                  setCheckoutOpen={setCheckoutOpen}
                  scrollToSection={() => {
                    tixRef.current.scrollIntoView({ behavior: "smooth" });
                  }}
                />
              )}
            </Grid>

            <Grid
              item
              container
              xs={12}
              sx={{ display: { xs: "flex", md: "none" } }}
              style={{
                padding: 10,
                position: "absolute",
                bottom: "5%",
                left: "50%",
                transform: "translate(-50%, 0%)",
                zIndex: 5,
              }}
            >
              <PurchaseButton
                loading={loading}
                setLoading={setLoading}
                scrollToSection={() => {
                  tixRef.current.scrollIntoView({ behavior: "smooth" });
                }}
                setCheckoutOpen={setCheckoutOpen}
                ticketCounts={ticketCounts}
                event={event}
                total={total}
                clientSecret={clientSecret}
                setClientSecret={setClientSecret}
                userData={userData}
              />
            </Grid>
          </Grid>

          {comments !== null && (
            <Grid item container xs={12} direction={"row"}>
              <EventComments
                comments={comments}
                setComments={setComments}
                event={event}
              />
            </Grid>
          )}

          {event && eventHost && (
            <CheckoutModal
              open={checkoutOpen}
              setOpen={setCheckoutOpen}
              event={event}
              eventHost={eventHost}
              taxCalculated={taxCalculated} setTaxCalculated={setTaxCalculated}
              eventTickets={
                // !isArtistPage ?
                event?.tickets
                //   : event.artists?.find(
                //       (artist) =>
                //         artist.profilePath ===
                //         path.split("/")[path.split("/").length - 1]
                //       //   artist.uid === path.split("/")[path.split("/").length - 1]
                //     )?.assignedTickets
              }
              artist={
                !isArtistPage
                  ? null
                  : event.artists?.find(
                      (artist) =>
                        artist.profilePath ===
                        path.split("/")[path.split("/").length - 1]
                      //   artist.uid === path.split("/")[path.split("/").length - 1]
                    )
              }
              ticketCounts={ticketCounts}
              setTicketCounts={setTicketCounts}
              total={total}
              clientSecret={clientSecret}
              setClientSecret={setClientSecret}
              showSuccess={showSuccess}
              setShowSuccess={setShowSuccess}
            />
          )}
        </>
      )}

      <Modal
        open={showSuccess}
        onClose={() => {
          setShowSuccess(false);
          navigate(-1);
        }}
      >
        <Grid
          item
          container
          xs={11}
          md={6}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "white",
            borderRadius: 12,
            padding: 20,
            outline: "none",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h4"
            style={{ width: "100%", textAlign: "center" }}
          >
            Success!!
          </Typography>
          <Typography style={{ width: "100%", textAlign: "center" }}>
            Thank you for your purchase!
          </Typography>
          <Typography style={{ width: "100%", textAlign: "center" }}>
            Your order should appear in your email shortly. Please check your
            email for any updates.
          </Typography>
          <Grid
            item
            container
            xs={12}
            style={{ justifyContent: "center", padding: "15px 0px" }}
          >
            <Typography
              variant="caption"
              style={{ width: "100%", textAlign: "center" }}
            >
              Share this event:
            </Typography>
            <FacebookShareButton
              url={url}
              quote={`I just bought my tickets to ${event?.title} 🎉! Join me, get tix here!`}
              hashtag={"#I just bought my tickets to ${event?.title} 🎉! Join me, get tix here!"}
              style={{ marginRight: 5 }}
            >
              <FacebookIcon size={40} round={true} />
            </FacebookShareButton>

            <TwitterShareButton
              url={url}
              title={`I just bought my tickets to ${event?.title} 🎉! Join me, get tix here!`}
              hashtags={["TicketShare", "Events", "Fun"]}
              style={{ marginRight: 5 }}
            >
              <XIcon size={40} round={true} />
            </TwitterShareButton>

            <LinkedinShareButton
              url={url}
              summary={`I just bought my tickets to ${event?.title} 🎉! Join me, get tix here!`}
              source={"TicketSHare"}
              style={{ marginRight: 5 }}
            >
              <LinkedinIcon size={40} round={true} />
            </LinkedinShareButton>

            <WhatsappShareButton
              url={url}
              title={`I just bought my tickets to ${event?.title} 🎉! Join me, get tix here!`}
              separator={" --> "}
              hashtag={"#TicketShare"}
              style={{ marginRight: 5 }}
            >
              <WhatsappIcon size={40} round={true} />
            </WhatsappShareButton>

            <CopyToClipboard
              text={`I just bought my tickets to ${event?.title} 🎉! If you're free you should join me --> ${url}`}
              onCopy={() => {
                setInfo(true);
                setMessage("Copied to clipboard");
              }}
            >
              <IconButton
                style={{ background: "lightgrey", width: 40, height: 40 }}
              >
                <ContentCopy />
              </IconButton>
            </CopyToClipboard>
          </Grid>
          {userData?.guest ? (
            <>
              <Typography
                style={{ width: "100%", textAlign: "center", marginTop: 20 }}
              >
                Create Account to access your tickets anytime.
              </Typography>
              <SignUp />
            </>
          ) : (
            <Button
              onClick={() => {
                setShowSuccess(false);
                navigate(-1);
              }}
            >
              Close
            </Button>
          )}
        </Grid>
      </Modal>
    </Grid>
  );
};

export default EventScreen;
