import { Button, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
import { db } from "../config/firebaseConfig";
import QRCode from "react-qr-code";
import { AppContext } from "../App";
import { motion, useMotionValue, useTransform } from "framer-motion";
import { KeyboardArrowRight } from "@mui/icons-material";

const CheckInTicket = () => {
  const { defaultTheme, setSuccess, setError, setMessage } =
    useContext(AppContext);
  const paths = window.location.pathname.split("/").filter((x) => x.length > 0);
  const eventId = paths[0];
  const userId = paths[1];
  const ticketId = paths[2];
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);

  const slideBarRef = useRef(null);

  const x = useMotionValue(0);
  const background = useTransform(
    x,
    [0, 100],
    [defaultTheme.palette.primary.light, defaultTheme.palette.primary.main]
  );
  const barBackground = useTransform(
    x,
    [0, 100],
    [defaultTheme.palette.grey.light, defaultTheme.palette.primary.main]
  );
  const textBackground = useTransform(
    x,
    [0, 100],
    [defaultTheme.palette.grey.main, defaultTheme.palette.white]
  );

  const checkIn = async () => {
    console.log("CHECK IN");
    try {
      await db
        .collection("users")
        .doc(userId)
        .collection("tickets")
        .doc(ticket.docId)
        .update({
          checkedIn: true,
        });


      //! Write a function here that updates the order ticket as well
      


      await db
        .collection("users")
        .doc(userId)
        .collection("tickets")
        .doc(ticket.docId)
        .get()
        .then((doc) => {
          setTicket({ ...doc.data(), docId: doc.id });
        });
      setSuccess(true);
      setMessage("Ticket Checked In Successfully");
      //close current window after 1.5 seconds
      setTimeout(() => {
        window.close();
      }, 1500);
    } catch (error) {
      console.log("ERR => ", error);
      setError(true);
      setMessage("Error Checking In Ticket");
    }
  };

  useEffect(() => {
    db.collection("users")
      .doc(userId)
      .collection("tickets")
      .where("ticketId", "==", ticketId)
      .get()
      .then((query) => {
        let tickets = query.docs.map((doc) => {
          return { ...doc.data(), docId: doc.id };
        });
        setTicket(tickets[0]);
      });
  }, []);

  useEffect(() => {
    // console.log("TICKET IS => ", ticket);
    console.log("SLIDE BAR", slideBarRef);
  }, [ticket, slideBarRef]);

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        height: "100dvh",
        marginTop: 70,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {ticket && (
        <Grid
          item
          container
          justifyContent={"center"}
          xs={11}
          md={6}
          lg={4}
          xl={3}
          sx={{
            padding: "20px",
            background: "white",
            borderRadius: { xs: "12px 12px 0px 0px", md: "12px" },
          }}
        >
          <Grid
            item
            container
            xs={12}
            justifyContent={"space-between"}
            alignItems={"center"}
            style={{
              padding: 10,
              borderBottom: "1px solid lightgrey",
              marginBottom: 20,
            }}
          >
            <Typography
              style={{
                width: "100%",
                textAlign: "center",
                fontWeight: "bold",
                background: textBackground,
              }}
            >
              {ticket?.checkedIn ? "Checked In" : "Check In"}
            </Typography>
          </Grid>

          <Grid
            item
            container
            xs={11}
            md={10}
            style={{
              borderRadius: 12,
              border: "1px solid grey",
              overflow: "hidden",
            }}
          >
            <Grid
              item
              container
              xs={12}
              justifyContent={"center"}
              style={{
                background: defaultTheme.palette.primary.main,
                padding: 12,
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  width: "100%",
                  textAlign: "center",
                  marginBottom: 10,
                }}
              >
                {ticket.ticketName ? ticket.ticketName : "General Admission"}
              </Typography>
              <Grid
                item
                container
                xs={6}
                md={4}
                style={{ padding: 15, background: "white" }}
              >
                <QRCode
                  value={ticket.qrCode}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                />
              </Grid>
              <Typography
                variant="caption"
                style={{ marginTop: 10, width: "100%", textAlign: "center" }}
              >
                {ticket.ticketId}
              </Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              justifyContent={"center"}
              style={{ background: "white" }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  width: "100%",
                  borderBottom: "1px solid lightgrey",
                  padding: 15,
                }}
              >
                {ticket.eventName}
              </Typography>
              <Grid
                item
                container
                xs={12}
                style={{ padding: 15, borderBottom: "1px solid lightgrey" }}
              >
                <Grid item container xs={6} direction="column">
                  <Typography variant="caption" style={{ color: "grey" }}>
                    Name
                  </Typography>
                  <Typography variant="body1" style={{ color: "black" }}>
                    {ticket.purchaserName
                      ? ticket.purchaserName.split(" ")[0]
                      : "N/A"}
                  </Typography>
                </Grid>
                <Grid item container xs={6} direction="column">
                  <Typography variant="caption" style={{ color: "grey" }}>
                    Last Name
                  </Typography>
                  <Typography variant="body1" style={{ color: "black" }}>
                    {ticket.purchaserName
                      ? ticket.purchaserName.split(" ")[1]
                      : "N/A"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                style={{ padding: 15, borderBottom: "1px solid lightgrey" }}
              >
                <Grid item container xs={12} direction="column">
                  <Typography variant="caption" style={{ color: "grey" }}>
                    Date & Time
                  </Typography>
                  <Typography variant="body1" style={{ color: "black" }}>
                    {new Date(
                      ticket.eventStartTime.seconds * 1000
                    ).toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {ticket.checkedIn ? (
            <Typography
              style={{ marginTop: 20, width: "100%", textAlign: "center" }}
            >
              <i>This Ticket has been checked in</i>
            </Typography>
          ) : (
            <>
              <Typography
                variant="caption"
                style={{
                  marginTop: 10,
                  width: "100%",
                  textAlign: "center",
                  color: textBackground,
                }}
              >
                <i>
                  This function is reserved for the event owner. <br />
                  DO NOT SLIDE if you are the ticket owner.
                </i>
              </Typography>
              <motion.div
                ref={slideBarRef}
                style={{
                  background: barBackground,
                  width: "100%",
                  height: 40,
                  padding: 2,
                  borderRadius: 4,
                  position: "relative",
                  overflow: "hidden",
                  //   marginTop: 20,
                }}
              >
                <motion.p
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: textBackground,
                    margin: 0,
                    padding: 0,
                  }}
                >
                  Slide to Check in
                </motion.p>
                <motion.div
                  drag="x"
                  dragConstraints={{
                    left: 0,
                    right: slideBarRef?.current?.clientWidth - 40,
                  }}
                  onDragEnd={(event, info) => {
                    if (
                      info.point.x >=
                      slideBarRef?.current?.clientWidth - 40
                    ) {
                      checkIn();
                    }
                  }}
                  style={{
                    x,
                    height: 36,
                    width: 36,
                    background,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 4,
                  }}
                >
                  <KeyboardArrowRight style={{ color: "white" }} />
                </motion.div>
              </motion.div>
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default CheckInTicket;
