import { Typography } from "@mui/material";

export const theme = {
  palette: {
    primary: {
      main: "#ee6b47",
      dark: "#b9402b",
      light: "#ff8e6f",
    },
    secondary: {
      main: "#32BC9B",
      dark: "#1c896f",
      light: "#62e2c3",
    },
    error: {
      main: "#f44336",
      dark: "#d32f2f",
      light: "#e57373",
    },
    warning: {
      main: "#ff9800",
      dark: "#f57c00",
      light: "#ffe082",
    },
    info: {
      main: "#2196f3",
      dark: "#1976d2",
      light: "#90caf9",
    },
    success: {
      main: "#4caf50",
      dark: "#2e7d32",
      light: "#c8e6c9",
    },
    black: "#000000",
    white: "#ffffff",
    grey: {
      main: "#808080",
      light: "#d3d3d3",
      dark: "#606060",
    },
  },
  typography: {
    fontFamily: [`"Montserrat", sans-serif`],
  },
};
