import { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

// third-party
import ReactApexChart from "react-apexcharts";

// chart options
const barChartOptions = {
  chart: {
    type: "bar",
    height: 365,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "45%",
      borderRadius: 4,
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    categories: [], // Updated to use props
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
  },
  grid: {
    show: false,
  },
};

// ==============================|| MONTHLY BAR CHART ||============================== //

export default function WeeklyBarChart({ seriesData, categories }) {
  const theme = useTheme();

  const { primary, secondary } = theme.palette.text;
  const info = theme.palette.info.light;

  const [series, setSeries] = useState([{ data: seriesData }]);
  const [options, setOptions] = useState({
    ...barChartOptions,
    xaxis: { ...barChartOptions.xaxis, categories },
  });

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [info],
      xaxis: {
        ...prevState.xaxis,
        categories,
        labels: {
          style: {
            colors: categories.map(() => secondary),
          },
        },
      },
    }));
  }, [primary, info, secondary, categories]);

  useEffect(() => {
    setSeries([{ data: seriesData }]);
  }, [seriesData]);

  return (
    <Box id="chart" sx={{ bgcolor: "transparent", height: "80%" }}>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={`100%`}
      />
    </Box>
  );
}
