import React, { useEffect, useState, useContext } from "react";
import {
  Add,
  Assignment,
  Delete,
  Edit,
  Info,
  MoreVert,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Tooltip,
  Typography,
  Modal,
  Chip,
  Autocomplete,
  TextField,
  Avatar,
  IconButton,
  MenuItem,
  Menu,
  FormControlLabel,
  Checkbox,
  Slider,
} from "@mui/material";
import { AppContext } from "../../App";
import { db } from "../../config/firebaseConfig";

const EventSettings = ({
  event,
  eventArtists,
  setEventArtists,
  paymentSettings,
  setPaymentSettings,
  saveSettings,
}) => {
  const { artists, defaultTheme, setSuccess, setError, setInfo, setMessage } =
    useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [selectedActs, setSelectedActs] = useState(eventArtists);
  const [inputValue, setInputValue] = useState("");
  const [addOptionOpen, setAddOptionOpen] = useState(false);
  const [newActName, setNewActName] = useState("");
  const [newActEmail, setNewActEmail] = useState("");
  const [anchorEls, setAnchorEls] = useState({});
  const [artistMenuOpen, setArtistMenuOpen] = useState({});
  //   const [paymentSettings, setPaymentSettings] = useState(
  //     event.paymentSettings ||
  //   );

  console.log("THIS IS THE PAYMENT SETTINGS =>", paymentSettings);

  const handlePaymentSettingsChange = (type, value) => {
    setPaymentSettings((prevSettings) => ({
      ...prevSettings,
      [type]: value,
    }));
  };

  const handleSliderChange = (event, newValue) => {
    setPaymentSettings((prevSettings) => ({
      ...prevSettings,
      artistRevenueSplitPercentage: newValue,
    }));
  };

  const handleThresholdChange = (type, index, field, value) => {
    setPaymentSettings((prevSettings) => ({
      ...prevSettings,
      [type]: {
        ...prevSettings[type],
        thresholds: prevSettings[type].thresholds.map((threshold, i) =>
          i === index ? { ...threshold, [field]: value } : threshold
        ),
      },
    }));
  };

  // Function to add a new threshold
  const addThreshold = (type) => {
    const lastThreshold =
      paymentSettings[type].thresholds[
        paymentSettings[type].thresholds.length - 1
      ];
    const newMinValue = lastThreshold ? parseInt(lastThreshold.max) + 1 : 0;

    setPaymentSettings((prevSettings) => ({
      ...prevSettings,
      [type]: {
        ...prevSettings[type],
        thresholds: [
          ...prevSettings[type].thresholds,
          {
            min: newMinValue,
            max: newMinValue,
            flatFee: 0,
            artistRevenue: 0,
            artistRevenueSplitEligible: false,
          },
        ],
      },
    }));
  };

  // Function to remove a threshold
  const removeThreshold = (type, index) => {
    setPaymentSettings((prevSettings) => ({
      ...prevSettings,
      [type]: {
        ...prevSettings[type],
        thresholds: prevSettings[type].thresholds.filter((_, i) => i !== index),
      },
    }));
  };

  const filterOptions = (options, { inputValue }) => {
    const filtered = options.filter((option) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase())
    );

    if (inputValue !== "" && !filtered.length) {
      filtered.push({
        name: `+ Add new Act`,
        imageUrl: "",
        isNewOption: true,
      });
    }

    return filtered;
  };

  const addNewOption = () => {
    setAddOptionOpen(true);
    setNewActName(inputValue);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email) && email.length < 255 && email.length > 0;
  };

  const confirmAddNewAct = () => {
    if (newActName && newActEmail) {
      //check if enewActEmail is vali
      const emailIsValid = validateEmail(newActEmail);
      if (emailIsValid) {
        setSelectedActs((prev) => [
          ...prev,
          {
            name: newActName,
            email: newActEmail,
            imageUrl: "",
            emailSent: false,
          },
        ]);
        setAddOptionOpen(false);
        setNewActName("");
        setNewActEmail("");
        setInputValue("");
        setSuccess(true);
        setMessage("New Acted Added.");
      } else {
        setError(true);
        setMessage("Please enter a valid email address");
      }
    } else {
      setError(true);
      setMessage("Please fill in all fields");
    }
  };

  const updateDBArtists = async (artists) => {
    await db
      .collection("events")
      .doc(event.id)
      .update(
        {
          ...event,
          artists: artists,
        },
        { merge: true }
      )
      .then(() => {
        setEventArtists(artists);
        setSuccess(true);
        setMessage("You've updated your event successfully!");
        // setEvents([...events]);
      })
      .catch((error) => {
        console.log("THERE IS AN ERR => ", error);
        setError(true);
        setMessage("Something went wrong");
      });
  };

  const cancelAddAct = () => {
    setAddOptionOpen(false);
    setNewActName("");
    setNewActEmail("");
    setSelectedActs(selectedActs.filter((act) => act.name !== "+ Add new Act"));
  };

  const cancelActs = () => {
    setEventArtists(event.artists);
    setAddOptionOpen(false);
  };

  const addActs = async () => {
    let artistsAdded = [...selectedActs].map((act) => {
      return {
        // ...act,
        uid: act.uid,
        name: act.name,
        artistFullName: act?.artistFullName ? act.artistFullName : act.fullName,
        email: act.adminEmail ? act.adminEmail : act.email,
        imageUrl: act.imageUrl,
        confirmed: false,
        assignedTickets: act?.assignedTickets || [],
        emailSent: act?.emailSent || false,
        profilePath: act.profilePath,
      };
    });
    await updateDBArtists(artistsAdded);
    setAddOptionOpen(false);
  };

  const handleClickArtistMenu = (event, idx) => {
    setAnchorEls({ ...anchorEls, [idx]: event.currentTarget });
    setArtistMenuOpen({ ...artistMenuOpen, [idx]: true });
  };

  const handleCloseArtistMenu = (idx) => {
    setAnchorEls({ ...anchorEls, [idx]: null });
    setArtistMenuOpen({ ...artistMenuOpen, [idx]: false });
  };

  const handleHideArtist = async (index) => {
    console.log(index);
    let artistsCopy = [...eventArtists];
    let artist = artistsCopy[index];
    artist.hidden = !artist.hidden;
    artistsCopy[index] = artist;

    await updateDBArtists(artistsCopy);
  };

  const handleRemoveAct = async (index) => {
    const newActs = eventArtists.filter((_, i) => i !== index);
    await updateDBArtists(newActs);
  };

  useEffect(() => {
    console.log("Updated Acts =>", selectedActs);
  }, [selectedActs]);

  return (
    <Grid
      item
      container
      xs={12}
      style={
        {
          // borderRadius: 12,
          // background: "white",
          // padding: 10,
          // marginTop: 20,
        }
      }
    >
      <Grid
        item
        container
        xs={12}
        style={{
          borderRadius: 12,
          background: "white",
          padding: 10,
          marginTop: 20,
        }}
      >
        <Grid container>
          {/* Payment Type Selection */}
          <Box
            sx={{
              width: "100%",
              borderBottom: "1px solid grey",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBottom: 1,
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
              }}
            >
              Payment Settings
            </Typography>

            <Tooltip title="Only available if performing artists are added to this show">
              <Info style={{ color: "grey", cursor: "pointer" }} />
            </Tooltip>
          </Box>
          <TextField
            // disabled={!event.artists || event?.artists?.length === 0}
            sx={{ margin: "30px 0px", width: { md: "100%", xs: "100%" } }}
            select
            size="small"
            label="Payment Type"
            value={paymentSettings.paymentType}
            onChange={(e) =>
              handlePaymentSettingsChange("paymentType", e.target.value)
            }
          >
            <MenuItem value="artistRevenueSplit">Artist Revenue Split</MenuItem>
            <MenuItem value="flatFee">Flat Fee</MenuItem>
            <MenuItem value="hybrid">Hybrid</MenuItem>
          </TextField>

          {/* Artist Revenue Split Slider */}
          {/* {event?.artists?.length > 0 && ( */}
          <>
            {paymentSettings.paymentType === "artistRevenueSplit" && (
              <Grid item xs={12} style={{ padding: 10 }}>
                <Typography
                  variant="caption"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  Revenue Split ({paymentSettings.artistRevenueSplitPercentage}
                  %)
                </Typography>
                <div
                  style={{
                    width: "100%",
                    background: `${defaultTheme.palette.primary.light}50`,
                    height: 6,
                    position: "relative",
                    borderRadius: 6,
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "0%",
                      transform: "translate(0%, -50%)",
                      width: "21%",
                      height: "100%",
                      background: defaultTheme.palette.primary.main,
                      borderRadius: 6,
                    }}
                  ></div>
                  <Slider
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-42%, -50%)",
                      width: "70%",
                    }}
                    value={paymentSettings.artistRevenueSplitPercentage}
                    onChange={handleSliderChange}
                    aria-labelledby="artist-revenue-split-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    //   marks
                    min={20}
                    max={90}
                  />
                </div>
              </Grid>
            )}

            {/* Dynamic Threshold Settings for Flat Fee and Hybrid */}
            {["flatFee", "hybrid"].includes(paymentSettings.paymentType) && (
              <>
                {paymentSettings[paymentSettings.paymentType].thresholds.map(
                  (threshold, index) => (
                    <Grid
                      key={index}
                      item
                      container
                      xs={12}
                      style={{ borderBottom: "1px solid lightgrey" }}
                    >
                      <Typography style={{ fontWeight: "bold", width: "100%" }}>
                        Guarantee {index + 1}
                      </Typography>
                      <Grid item xs={12} md={3} style={{ padding: 10 }}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Min Tickets"
                          value={threshold.min}
                          onChange={(e) =>
                            handleThresholdChange(
                              paymentSettings.paymentType,
                              index,
                              "min",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3} style={{ padding: 10 }}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Max Tickets"
                          value={threshold.max}
                          onChange={(e) =>
                            handleThresholdChange(
                              paymentSettings.paymentType,
                              index,
                              "max",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3} style={{ padding: 10 }}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Flat Fee"
                          value={threshold.flatFee}
                          onChange={(e) =>
                            handleThresholdChange(
                              paymentSettings.paymentType,
                              index,
                              "flatFee",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      {paymentSettings.paymentType === "hybrid" && (
                        <Grid
                          item
                          xs={12}
                          style={{
                            padding: 10,
                            display: threshold.artistRevenueSplitEligible
                              ? "block"
                              : "none",
                          }}
                        >
                          <Typography
                            gutterBottom
                            style={{ fontWeight: "bold" }}
                            variant="caption"
                          >
                            Revenue Split ({threshold.artistRevenue}%)
                          </Typography>
                          <div
                            style={{
                              width: "100%",
                              background: `${defaultTheme.palette.primary.light}50`,
                              height: 6,
                              position: "relative",
                              borderRadius: 6,
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "0%",
                                transform: "translate(0%, -50%)",
                                width: "11%",
                                height: "100%",
                                background: defaultTheme.palette.primary.main,
                                borderRadius: 6,
                              }}
                            ></div>
                            <Slider
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: "80%",
                              }}
                              value={threshold.artistRevenue}
                              onChange={(e) =>
                                handleThresholdChange(
                                  "hybrid",
                                  index,
                                  "artistRevenue",
                                  e.target.value
                                )
                              }
                              aria-labelledby="artist-revenue-split-slider"
                              valueLabelDisplay="auto"
                              step={1}
                              // marks
                              min={10}
                              max={90}
                            />
                          </div>
                        </Grid>
                      )}

                      {paymentSettings.paymentType === "hybrid" && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={threshold.artistRevenueSplitEligible}
                              onChange={(e) =>
                                handleThresholdChange(
                                  paymentSettings.paymentType,
                                  index,
                                  "artistRevenueSplitEligible",
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label="% Revenue Split"
                        />
                      )}
                      <IconButton
                        onClick={() =>
                          removeThreshold(paymentSettings.paymentType, index)
                        }
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  )
                )}
                {paymentSettings[paymentSettings.paymentType].thresholds
                  .length < 4 && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ height: 36, borderRadius: 40 }}
                    onClick={() => addThreshold(paymentSettings.paymentType)}
                  >
                    Add Threshold
                  </Button>
                )}
              </>
            )}
          </>
          {/*   )} */}

          <Grid
            item
            container
            xs={12}
            style={{ marginTop: 20, justifyContent: "flex-end" }}
          >
            <Button
              //   disabled={!event.artists || event?.artists?.length === 0}
              variant="contained"
              color="primary"
              style={{ height: 36 }}
              onClick={saveSettings}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={12}
        style={{
          borderRadius: 12,
          background: "white",
          padding: 10,
          marginTop: 20,
        }}
      >
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px solid grey",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBottom: 1,
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
            }}
          >
            Performing Artists / Acts
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              startIcon={<Add />}
              size="small"
              style={{ borderRadius: 40, marginRight: 5 }}
              onClick={() => setOpen(true)}
            >
              Add new act
            </Button>
            <Tooltip title="i.e a Musician, Comedian, Dancer, Host, etc)">
              <Info style={{ color: "grey", cursor: "pointer" }} />
            </Tooltip>
          </div>
        </Box>

        {eventArtists.length === 0 ? (
          <Typography
            style={{ width: "100%", textAlign: "center", padding: 20 }}
          >
            You don't have any acts assigned to this show.
          </Typography>
        ) : (
          <Grid item container xs={12} style={{ padding: `10px 0px` }}>
            {eventArtists.map((artist, idx) => (
              <Grid
                item
                container
                xs={12}
                key={idx}
                style={{
                  padding: 10,
                  height: "fit-content",
                  marginBottom: 10,
                  border: "1px solid grey",
                  borderRadius: 5,
                }}
              >
                <Avatar src={artist.imageUrl} />
                <Box
                  sx={{
                    display: "flex",
                    flexFlow: "row",
                    justifyContent: "space-between",
                    padding: 0.5,
                    width: "calc(100% - 42px)",
                  }}
                >
                  <Box sx={{ display: "flex", flexFlow: "column" }}>
                    <Typography style={{ fontWeight: "bold", width: "100%" }}>
                      {artist.name}
                      {!artist?.confirmed && (
                        <i
                          style={{
                            padding: "0px 5px",
                            background: `${defaultTheme.palette.secondary.light}55`,
                            borderRadius: 4,
                            color: defaultTheme.palette.secondary.dark,
                            marginLeft: 5,
                            fontWeight: "normal",
                          }}
                        >
                          pending
                        </i>
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ fontWeight: "bold", width: "100%" }}
                    >
                      Tickets Assigned:{" "}
                      {(!artist.assignedTickets ||
                        artist.assignedTickets.length === 0) &&
                        "0"}
                    </Typography>
                    {artist.assignedTickets?.length > 0 &&
                      artist.assignedTickets.map((assignedTicket, idx) => {
                        return (
                          <Typography
                            variant="caption"
                            key={idx}
                            style={{
                              width: "100%",
                              paddingLeft: 10,
                            }}
                          >
                            <strong>- {assignedTicket.name}</strong> | Quantity
                            left:{" "}
                            {assignedTicket.remaining +
                              "/" +
                              assignedTicket.quantity}
                          </Typography>
                        );
                      })}
                  </Box>

                  <IconButton
                    style={{ height: 40, width: 40 }}
                    aria-controls={
                      artistMenuOpen[idx] ? "demo-positioned-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={artistMenuOpen[idx] ? "true" : undefined}
                    onClick={(event) => handleClickArtistMenu(event, idx)}
                  >
                    <MoreVert />
                  </IconButton>

                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEls[idx]}
                    open={artistMenuOpen[idx] || false}
                    onClose={() => handleCloseArtistMenu(idx)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {/* <MenuItem
                    onClick={() => {
                      handleCloseArtistMenu(idx);
                      //   handleSelectUpdateTicket(ticket);
                      //   setArtistModalOpen(true);
                    }}
                  >
                    <Edit style={{ marginRight: 5, color: "grey" }} />
                    Edit
                  </MenuItem> */}
                    {/* <MenuItem
                                onClick={() => {
                                  console.log(idx);
                                //   handleHideArtist(idx);
                                  handleCloseArtistMenu(idx);
                                }}
                              >
                                {ticket.hidden ? (
                                  <VisibilityOff
                                    style={{ marginRight: 5, color: "grey" }}
                                  />
                                ) : (
                                  <Visibility
                                    style={{ marginRight: 5, color: "grey" }}
                                  />
                                )}
                                {ticket.hidden ? "Unhide" : "Hide"}
                              </MenuItem> */}
                    <MenuItem
                      onClick={() => {
                        handleRemoveAct(idx);
                        handleCloseArtistMenu(idx);
                      }}
                    >
                      <Delete style={{ marginRight: 5, color: "grey" }} />
                      Remove Act
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        //   setAssignTicketsModal(true);
                        //   setSelectedTicket(ticket);
                        //   handleCloseArtistMenu(idx);
                      }}
                    >
                      <Assignment style={{ marginRight: 5, color: "grey" }} />
                      Assign Tickets
                    </MenuItem>
                  </Menu>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Grid
          item
          container
          xs={11}
          md={8}
          lg={6}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            borderRadius: 12,
            background: "white",
            padding: 20,
          }}
        >
          <Typography
            variant="h5"
            style={{ fontWeight: "bold", width: "100%", textAlign: "center" }}
          >
            Select An Act
          </Typography>
          <Typography
            variant="body2"
            style={{
              width: "100%",
              textAlign: "center",
              marginBottom: 20,
            }}
          >
            Search for an act from the list below. <br />
            <span style={{ color: "grey" }}>
              Can't find the act you're looking for? Select '+ Add New Act' to
              send them an invite.
            </span>
          </Typography>

          <Autocomplete
            multiple
            limitTags={2}
            style={{ width: "100%" }}
            id="artists-standard"
            options={artists}
            getOptionLabel={(option) => option.name}
            filterOptions={filterOptions}
            value={selectedActs}
            onChange={(event, newValue) => {
              const isNewOption = newValue.some((option) => option.isNewOption);

              if (isNewOption) {
                addNewOption();
              } else {
                console.log("NEW VALUE => ", newValue);
                setSelectedActs(newValue);
              }
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderOption={(props, option) => (
              <li {...props}>
                <Avatar
                  alt={option.name}
                  src={option.imageUrl}
                  style={{ marginRight: 8 }}
                />
                {option.name} - {option.adminEmail}
              </li>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option.name}
                  {...getTagProps({ index })}
                  avatar={<Avatar alt={option.name} src={option.imageUrl} />}
                  style={{
                    backgroundColor: option.isNewOption
                      ? defaultTheme.palette.info.light
                      : undefined,
                  }}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Select Acts"
                placeholder="Search"
              />
            )}
          />

          <Grid
            item
            container
            xs={12}
            style={{ justifyContent: "space-evenly" }}
          >
            <Button variant="contianed" onClick={addActs}>
              Confirm
            </Button>
            <Button variant="contianed" onClick={cancelActs}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Modal>

      <Modal open={addOptionOpen} onClose={cancelAddAct}>
        <Grid
          item
          container
          xs={10}
          md={7}
          lg={5}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            borderRadius: 12,
            background: "white",
            padding: 20,
            outline: "none",
          }}
        >
          <Typography
            variant="h5"
            style={{ fontWeight: "bold", width: "100%", textAlign: "center" }}
          >
            Enter Act Details
          </Typography>
          <Typography
            variant="body2"
            style={{ width: "100%", textAlign: "center" }}
          >
            Once the event is published, your added Act will receive an email
            invite to join the platform and sell tickets for your show!
          </Typography>
          <TextField
            label="Full Name"
            variant="outlined"
            fullWidth
            value={newActName}
            onChange={(e) => setNewActName(e.target.value)}
            style={{ marginTop: 20 }}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={newActEmail}
            onChange={(e) => setNewActEmail(e.target.value)}
            style={{ marginTop: 20 }}
          />
          <Grid
            item
            container
            xs={12}
            style={{ justifyContent: "space-evenly", marginTop: 20 }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={confirmAddNewAct}
            >
              Add Act
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={cancelAddAct}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default EventSettings;
