// src/components/PurchaseButton.js
import React from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { AppContext } from "../App";
import axios from "axios";

const PurchaseButton = ({
  loading,
  ticketCounts,
  total,
  scrollToSection,
  setCheckoutOpen,
}) => {
  const handleTickets = async () => {
    if (ticketCounts.filter((x) => x > 0).length > 0) {
      setCheckoutOpen(true);
    } else {
      scrollToSection();
    }
  };
  return (
    <Box
      sx={{
        marginBottom: "2rem",
        padding: 2,
        borderRadius: "12px",
        border: "1px solid lightgrey",
        height: "fit-content",
        width: "100%",
        marginTop: 5,
        position: "sticky",
        top: 110,
        background: "white",
      }}
    >
      <Button onClick={handleTickets} fullWidth variant="contained">
        {loading ? (
          <CircularProgress style={{ width: 24, height: 24 }} />
        ) : ticketCounts.filter((x) => x > 0).length > 0 ? (
          total === 0 ? (
            "Reserve a Spot"
          ) : (
            `Check out: $${total.toFixed(2)}`
            // `Check out`
          )
        ) : (
          "Select Tickets"
        )}
      </Button>
    </Box>
  );
};

export default PurchaseButton;
