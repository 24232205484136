// src/components/MyTickets.js
import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Tabs,
  Tab,
  Modal,
  Button,
  IconButton,
} from "@mui/material";
import { AppContext } from "../App";
import QRCode from "react-qr-code";
import { Close } from "@mui/icons-material";

const MyTickets = () => {
  const { userData, deviceType, defaultTheme, navigate, scrollToTop } =
    useContext(AppContext);
  const [tickets, setTickets] = useState(userData?.tickets || []);
  const [viewingTicket, setViewingTicket] = useState(false);
  const [ticketInView, setTicketInView] = useState(null);

  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (userData?.tickets && userData.tickets.length !== tickets.length) {
      setTickets(userData?.tickets);
    }
  }, [userData]);

  const getTicketList = (tickets) => {
    console.log("tickets =>", tickets, deviceType);
    const sortedTickets = tickets.sort(
      (a, b) =>
        new Date(a.eventStartTime?.seconds * 1000).getTime() -
        new Date(b.eventStartTime?.seconds * 1000).getTime()
    );

    return sortedTickets.map((t) => {
      const dateOfEvent = new Date(t.eventStartTime?.seconds * 1000).getTime();
      const endOfEvent = new Date(t.eventEndTime?.seconds * 1000).getTime();
      //sevenDaysPrior
      const sevenDaysPrior = dateOfEvent - 604800000;
      //lessThan7DaysFromNow
      const lessThan7DaysFromNow = Date.now() >= sevenDaysPrior;
      //isHappeningNow
      const isHappeningNow =
        Date.now() >= dateOfEvent && Date.now() <= endOfEvent;
      //isPastEvent
      const isPastEvent = Date.now() > endOfEvent;

      return (
        <Grid
          item
          container
          xs={12}
          md={10}
          lg={8}
          sx={{
            marginBottom: "10px",
            borderRadius: deviceType === "mobile" ? 4 : 8,
            height: deviceType === "mobile" ? "100px" : "200px",
            position: "relative",
          }}
        >
          {lessThan7DaysFromNow && (
            <Typography
              variant="caption"
              style={{
                padding: 2.5,
                background: "white",
                borderRadius: 4,
                border: "1px solid lightgrey",
                position: "absolute",
                top: "3%",
                left: "-2%",
                zIndex: 2,
              }}
            >
              🎉 UP SOON!
            </Typography>
          )}
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              height: "100%",
              border: "1px solid lightgrey",
              borderRadius: deviceType === "mobile" ? 4 : 8,
              zIndex: 0,
            }}
          />
          <div
            style={{
              width: deviceType === "mobile" ? 15 : 25,
              height: deviceType === "mobile" ? 15 : 25,
              borderRadius: 25,
              background: "white",
              borderTop: "1px solid lightgrey",
              borderRight: "1px solid lightgrey",
              position: "absolute",
              top: "50%",
              left: deviceType === "mobile" ? -10 : -15,
              transform: "translate(0%, -50%)",
              zIndex: 2,
              rotate: "33deg",
            }}
          />
          <div
            style={{
              width: deviceType === "mobile" ? 15 : 25,
              height: deviceType === "mobile" ? 15 : 25,
              borderRadius: 25,
              background: "white",
              borderTop: "1px solid lightgrey",
              borderLeft: "1px solid lightgrey",
              position: "absolute",
              top: "50%",
              right: deviceType === "mobile" ? -10 : -15,
              transform: "translate(0%, -50%)",
              zIndex: 2,
              rotate: "-34deg",
            }}
          />
          <img
            src={t?.eventFlyer}
            style={{
              width: "35%",
              height: "100%",
              objectFit: "cover",
              borderRadius:
                deviceType === "mobile" ? "4px 0px 0px 4px" : "8px 0px 0px 8px",
            }}
          />
          <div style={{ width: "65%", zIndex: 3 }}>
            <Grid
              item
              xs={12}
              direction={"column"}
              justifyContent={"space-between"}
              style={{ padding: 8, height: "100%", display: "flex" }}
            >
              <Grid item container xs={12} style={{ height: "fit-content" }}>
                <Typography
                  variant="caption"
                  style={{ fontWeight: "bold", width: "100%" }}
                >
                  {new Date(t?.eventStartTime.seconds * 1000).toDateString()}
                </Typography>
                <Typography style={{ fontWeight: "bold", width: "100%" }}>
                  {deviceType === "mobile"
                    ? t?.eventName?.slice(0, 20) + "..."
                    : t?.eventName}
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                justifyContent={"space-between"}
                style={{ height: "fit-content", alignItems: "end" }}
              >
                <Typography variant="caption" style={{}}>
                  {t?.ticketName ? t?.ticketName : "General Admission"}
                </Typography>
                <Typography
                  onClick={() => {
                    setTicketInView(t);
                    setViewingTicket(true);
                  }}
                  variant="caption"
                  style={{
                    textDecoration: "underline",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  VIEW
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
      );
    });
  };

  return (
    <Grid
      item
      container
      xs={12}
      justifyContent={"center"}
      style={{ height: "fit-content" }}
    >
      <Typography
        variant="h4"
        style={{
          marginBottom: "1rem",
          width: "100%",
          textAlign: "center",
        }}
      >
        My Tickets
      </Typography>
      <Grid
        item
        container
        xs={12}
        md={8}
        lg={6}
        spacing={2}
        // style={{ height: "calc(100dvh - 25%)" }}
      >
        {/* Map over tickets here */}
        <Grid item container xs={12} justifyContent={"center"}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            style={{ width: "100%", marginBottom: 20 }}
          >
            <Tab label="Previous" />
            <Tab label="Upcoming" />
          </Tabs>
          {activeTab === 0 && (
            <>
              {tickets.filter(
                (ticket) =>
                  !!ticket.checkedIn ||
                  Date.now() >
                    new Date(ticket.eventEndTime.seconds * 1000).getTime()
              ).length === 0 ? (
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    padding: 5,
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: 20,
                    }}
                  >
                    You have not attended any previous events...
                  </Typography>
                  <Typography
                    style={{
                      width: "100%",
                      textAlign: "center",
                      margin: "10px 0px",
                    }}
                  >
                    Let's change that!
                  </Typography>
                  <Button
                    onClick={() => {
                      navigate("/events");
                      scrollToTop();
                    }}
                    variant="contained"
                  >
                    Let's go
                  </Button>
                </Grid>
              ) : (
                getTicketList(tickets.filter((ticket) => !!ticket.checkedIn))
              )}
            </>
          )}
          {activeTab === 1 && (
            <>
              {tickets.filter((ticket) => !ticket.checkedIn).length === 0 ? (
                <Grid
                  item
                  container
                  xs={12}
                  style={{ padding: 5, justifyContent: "center" }}
                >
                  <Typography
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: 20,
                    }}
                  >
                    You do not have any uncoming events...
                  </Typography>
                  <Typography
                    style={{
                      width: "100%",
                      textAlign: "center",
                      margin: "10px 0px",
                    }}
                  >
                    Let's change that!
                  </Typography>
                  <Button
                    onClick={() => {
                      navigate("/events");
                      scrollToTop();
                    }}
                    variant="contained"
                  >
                    Let's go
                  </Button>
                </Grid>
              ) : (
                getTicketList(tickets.filter((ticket) => !ticket.checkedIn))
              )}
            </>
          )}
        </Grid>
      </Grid>

      {ticketInView && (
        <Modal
          open={viewingTicket}
          onClose={() => {
            setViewingTicket(false);
            setTicketInView(null);
          }}
        >
          <Grid
            item
            container
            justifyContent={"center"}
            xs={11}
            md={6}
            lg={4}
            xl={3}
            sx={{
              position: "absolute",
              bottom: { md: "50%", xs: "0%" },
              left: "50%",
              transform: {
                xs: "translate(-50%, 0%)",
                md: "translate(-50%, 50%)",
              },
              padding: "20px",
              background: "white",
              borderRadius: { xs: "12px 12px 0px 0px", md: "12px" },
              outline: "none",
            }}
          >
            <Grid
              item
              container
              xs={12}
              justifyContent={"space-between"}
              alignItems={"center"}
              style={{
                padding: 10,
                borderBottom: "1px solid lightgrey",
                marginBottom: 20,
              }}
            >
              <Typography>Ticket</Typography>
              <IconButton>
                <Close
                  onClick={() => {
                    setViewingTicket(false);
                    setTicketInView(null);
                  }}
                />
              </IconButton>
            </Grid>

            <Grid
              item
              container
              xs={11}
              md={10}
              style={{
                borderRadius: 12,
                border: "1px solid grey",
                overflow: "hidden",
              }}
            >
              <Grid
                item
                container
                xs={12}
                justifyContent={"center"}
                style={{
                  background: defaultTheme.palette.primary.main,
                  padding: 12,
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    width: "100%",
                    textAlign: "center",
                    marginBottom: 10,
                  }}
                >
                  {ticketInView.ticketName
                    ? ticketInView.ticketName
                    : "General Admission"}
                </Typography>
                <Grid
                  item
                  container
                  xs={6}
                  md={4}
                  style={{ padding: 15, background: "white" }}
                >
                  <QRCode
                    value={ticketInView.qrCode}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  />
                </Grid>
                <Typography
                  variant="caption"
                  style={{ marginTop: 10, width: "100%", textAlign: "center" }}
                >
                  {ticketInView.ticketId}
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                justifyContent={"center"}
                style={{ background: "white" }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    width: "100%",
                    borderBottom: "1px solid lightgrey",
                    padding: 15,
                  }}
                >
                  {ticketInView.eventName}
                </Typography>
                <Grid
                  item
                  container
                  xs={12}
                  style={{ padding: 15, borderBottom: "1px solid lightgrey" }}
                >
                  <Grid item container xs={6} direction="column">
                    <Typography variant="caption" style={{ color: "grey" }}>
                      Name
                    </Typography>
                    <Typography variant="body1" style={{ color: "black" }}>
                      {ticketInView.purchaserName
                        ? ticketInView.purchaserName.split(" ")[0]
                        : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item container xs={6} direction="column">
                    <Typography variant="caption" style={{ color: "grey" }}>
                      Last Name
                    </Typography>
                    <Typography variant="body1" style={{ color: "black" }}>
                      {ticketInView.purchaserName
                        ? ticketInView.purchaserName.split(" ")[1]
                        : "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  style={{ padding: 15, borderBottom: "1px solid lightgrey" }}
                >
                  <Grid item container xs={12} direction="column">
                    <Typography variant="caption" style={{ color: "grey" }}>
                      Date & Time
                    </Typography>
                    <Typography variant="body1" style={{ color: "black" }}>
                      {new Date(
                        ticketInView.eventStartTime.seconds * 1000
                      ).toLocaleString()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Button fullWidth variant="contained" style={{ marginTop: 20 }}>
              DOWNLOAD
            </Button>
          </Grid>
        </Modal>
      )}
    </Grid>
  );
};

export default MyTickets;
