import React, {useContext} from 'react';
import PropTypes from "prop-types";

// material-ui
import { Chip, Grid, Stack, Typography, Box, Tooltip } from "@mui/material";

// project import
import MainCard from "./MainCard";
import { AppContext } from "../App";


// assets
import { TrendingUp, TrendingDown, TrendingFlat, CheckCircle, ConfirmationNumber, AttachMoney, CalendarToday
} from "@mui/icons-material";

const iconSX = {
  fontSize: "0.75rem",
  color: "inherit",
  marginLeft: 0,
  marginRight: 0,
};

export default function AnalyticCard({
  color = "#1677FF",
  title,
  count,
  percentage,
  profit,
  isLoss,
  extra,
}) {
  const {defaultTheme} = useContext(AppContext)
  const extraText = () => {
    if (extra > 0) {
      return `You made an extra ${extra} this week`;
    } else if (extra < 0) {
      return `You made ${Math.abs(extra)} less this week`;
    } else {
      return `Same amount made this week`;
    }
  };

  const getIcon = (title) => {
    switch (title) {
      case 'Total Tickets Sold':
        return <ConfirmationNumber style={{color:defaultTheme.palette.secondary.main}}/>
        break;
    
      case 'Revenue Generated':
        return <AttachMoney style={{color:defaultTheme.palette.secondary.main}}/>
        break;
    
      case 'Upcoming Events':
        return <CalendarToday style={{color:defaultTheme.palette.secondary.main}}/>
        break;
    
      case 'Check-ins Completed':
        return <CheckCircle style={{color:defaultTheme.palette.secondary.main}}/>
        break;
    
      default:
        break;
    }
  }

  return (
    <MainCard contentSX={{ p: 2, minHeight:'120px',}}>
      <Grid item container xs={12} sx={{height:'fit-content', alignItems:'center'}}>
      <Box sx={{width:'fit-content', }}>
        {getIcon(title)}
      </Box>
      <Box sx={{width:'86%', pl:1, display:'flex', flexFlow:'column' }}>
        <Typography variant='body2' color="text.secondary" sx={{fontWeight:'bold', textAlign:'left'}}>
            {title}
        </Typography>
        <Typography variant="h6" color="inherit" sx={{fontWeight:'bold', textAlign:'left'}}>
              {count} 
              {(title !== 'Upcoming Events' && title !== 'Check-ins Completed') && 
              <span style={{color: isLoss ? defaultTheme.palette.error.main : defaultTheme.palette.secondary.main}}>{`(${isLoss ? "-" : "+"}${Number(percentage).toFixed(1)}%)`}</span>
              }
        </Typography>
        {(title !== 'Upcoming Events' && title !== 'Check-ins Completed') && 
        <div style={{width:'100%', display:'flex', flexFlow:'row', alignItems:'center'}}>
          {isLoss ? <TrendingDown style={{color:defaultTheme.palette.error.main}}/> : Number(percentage) === 0 ? <TrendingFlat  style={{color:defaultTheme.palette.grey.main}}/> : <TrendingUp style={{color:defaultTheme.palette.secondary.main}}/>}
        <Typography variant="caption" color="text.secondary" style={{textAlign:'left'}}>
          this week
        </Typography>
        </div>
        }
      </Box>

      </Grid>
      
    </MainCard>
  );
}

AnalyticCard.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.string,
  percentage: PropTypes.number,
  isLoss: PropTypes.bool,
  extra: PropTypes.string,
};
