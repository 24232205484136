import React, { useContext, useState } from "react";
import {
  Typography,
  Box,
  Checkbox,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import { AppContext } from "../App";

const PerformanceAgreement = ({
  event,
  paymentSettings,
  onAccept,
  isPending,
}) => {
  const { userData } = useContext(AppContext);
  const [signedName, setSignedName] = useState("");
  const [checked, setChecked] = useState(false);
  console.log("paymentSettings from terms =>", paymentSettings);
  const getPaymentDetails = () => {
    switch (paymentSettings.paymentType) {
      case "artistRevenueSplit":
        return (
          <Typography>
            <strong>Artist Revenue Split:</strong>{" "}
            {paymentSettings.artistRevenueSplitPercentage}% of your net ticket
            sales.
          </Typography>
        );
      case "flatFee":
        return paymentSettings.flatFee.thresholds.map((threshold, index) => (
          <Typography key={index}>
            <strong>Threshold {index + 1}:</strong> Min Tickets: {threshold.min}
            , Max Tickets: {threshold.max}, Flat Fee: ${threshold.flatFee}
          </Typography>
        ));
      case "hybrid":
        return paymentSettings.hybrid.thresholds.map((threshold, index) => (
          <Typography key={index}>
            <strong>Threshold {index + 1}:</strong> Min Tickets: {threshold.min}
            , Max Tickets: {threshold.max}, Flat Fee: ${threshold.flatFee},
            Artist Revenue: {threshold.artistRevenue}% of your net ticket sales.
          </Typography>
        ));
      default:
        return null;
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Performance Agreement
      </Typography>
      <Typography paragraph>
        This Performance Agreement ("Agreement") is entered into by and between
        the <strong>Promoter</strong> ("Event Owner") and the{" "}
        <strong>Artist</strong> as outlined below.
      </Typography>
      <Typography paragraph>
        <strong>Purpose of Agreement:</strong> The Promoter and the Artist agree
        to collaborate for the upcoming event, as specified below. This
        Agreement outlines the responsibilities and expectations of both
        parties.
      </Typography>
      <Typography paragraph>
        <strong>Artist's Commitment:</strong> The Artist agrees to perform at
        the event on the agreed date and time. The Artist will arrive at the
        venue punctually and be prepared to perform as scheduled. The Artist
        shall provide a professional performance and adhere to all reasonable
        guidelines and requirements set by the Promoter.
      </Typography>
      <Typography paragraph>
        <strong>Promoter's Commitment:</strong> The Promoter agrees to
        compensate the Artist under the terms specified in this Agreement.
        Payment details are outlined in the <strong>Payment Terms</strong>{" "}
        section below. The Promoter will ensure that the venue and necessary
        equipment are prepared and available for the Artist's performance.
      </Typography>

      <Typography variant="h6">Event Details</Typography>
      <Typography paragraph>
        <strong>Event Name:</strong> {event.title}
        <br />
        <strong>Event Date:</strong>{" "}
        {new Date(event.startDate.seconds * 1000).toLocaleDateString()}
        <br />
        <strong>Event Venue:</strong> {event.location}
        <br />
        <strong>Show Time:</strong>{" "}
        {new Date(event.startDate.seconds * 1000).toLocaleTimeString()}
      </Typography>
      <Typography variant="h6">Payment Terms</Typography>
      {getPaymentDetails()}
      <Typography paragraph>
        <strong>Payout Schedule:</strong> TicketShare will handle all payouts
        related to this event. The payout will be initiated and processed 1
        business day after the event has concluded.
      </Typography>
      <Typography paragraph>
        <strong>Precedence of Agreements:</strong> This Agreement shall not
        supersede any other existing agreements between the Promoter and the
        Artist. Any pre-existing agreements between the parties remain in effect
        unless explicitly modified by this Agreement.
      </Typography>
      <Typography paragraph>
        <strong>Cancellations:</strong> In the event of a cancellation by the
        Promoter, the Artist will be compensated according to the cancellation
        policy agreed upon by both parties. Should the Artist need to cancel
        their performance, notice must be given as early as possible. The Artist
        will not be entitled to any compensation if the performance is canceled
        by the Artist unless otherwise agreed upon.
      </Typography>
      <Typography paragraph>
        <strong>Dispute Resolution:</strong> Any disputes arising out of this
        Agreement will be resolved through amicable negotiation between the
        Promoter and the Artist. If a resolution cannot be reached, both parties
        agree to seek mediation before pursuing legal action.
      </Typography>

      {/* Acceptance Section */}
      {isPending ? (
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ marginTop: 20 }}
        >
          <Grid item xs={12}>
            <Typography variant="h6">
              Please confirm your acceptance of these terms:
            </Typography>
          </Grid>
          <Grid item>
            <Checkbox
              required
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
            <Typography variant="caption">
              I agree to the terms and conditions
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              value={signedName}
              onChange={(e) => setSignedName(e.target.value)}
              label="Type your full name to confirm"
              variant="outlined"
              fullWidth
              helperText={
                userData.artistFullName
                  ? userData.artistFullName
                  : userData.fullName
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={
                (signedName.trim() !== userData.fullName &&
                  signedName.trim() !== userData.artistFullName) ||
                !checked
              }
              variant="contained"
              color="primary"
              onClick={() => onAccept(event)}
              fullWidth
            >
              Confirm Acceptance
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ marginTop: 20 }}
        >
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "bold" }}>
              You accepted these terms:{" "}
              {event?.artists?.find((a) => a?.uid === userData?.uid)
                ?.dateConfirmed &&
                new Date(
                  event?.artists?.find((a) => a?.uid === userData?.uid)
                    ?.dateConfirmed?.seconds * 1000
                ).toLocaleString()}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default PerformanceAgreement;
