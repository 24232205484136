import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  MenuItem,
  Modal,
  Paper,
  Radio,
  TextField,
  Tooltip,
  Typography,
  Switch,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  Box,
  FormControl,
  Menu,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect, useContext, useRef } from "react";
import { motion } from "framer-motion";
import {
  Add,
  Assignment,
  CheckBox,
  Close,
  Delete,
  Edit,
  Info,
  InfoOutlined,
  LocalOffer,
  LocationOnOutlined,
  MoreVert,
  Remove,
  Search,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import PlacesAutoComplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import Geocode from "react-geocode";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AppContext } from "../App";
import { TimePicker } from "@mui/x-date-pickers";
import Toast from "./Toast";
import firebase, { db, functions, storage } from "../config/firebaseConfig";
import { eventTags } from "../utils/contants";
import EventSettings from "./events/EventSettings";
import dayjs from "dayjs";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "inherit",
    },
  },
};

const weekDays = [
  { short: "Su", long: "Sunday" },
  { short: "Mo", long: "Monday" },
  { short: "Tu", long: "Tuesday" },
  { short: "We", long: "Wednesday" },
  { short: "Th", long: "Thurday" },
  { short: "Fr", long: "Friday" },
  { short: "Sa", long: "Saturday" },
];

const initializeSettings = (event) => {
  return {
    paymentType: event?.paymentSettings?.paymentType || "artistRevenueSplit",
    artistRevenueSplitPercentage:
      event?.paymentSettings?.artistRevenueSplitPercentage || 20,
    artistRevenueSplit: event?.paymentSettings?.artistRevenueSplit || {
      artistRevenue: 0,
      artistRevenueLabel: "",
    },
    flatFee: event?.paymentSettings?.flatFee || {
      thresholds: [
        { min: 0, max: 10, flatFee: 0, artistRevenueSplitEligible: false },
      ],
    },
    hybrid: event?.paymentSettings?.hybrid || {
      thresholds: [{ min: 0, max: 10, flatFee: 0, artistRevenue: 0 }],
    },
  };
};

const ViewEditEvent = ({
  open,
  setClose,
  event,
  setEvents,
  events,
  isArtist,
}) => {
  const {
    defaultTheme,
    setError,
    setMessage,
    setSuccess,
    userData,
    getTicketFee,
    artists,
  } = useContext(AppContext);
  let isAdmin = userData?.roles?.includes("admin");
  const [isEventOwner, setIsEventOwner] = useState(true);
  const [testMode, setTestMode] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionTeaser, setDescriptionTeaser] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [recurring, setRecurring] = useState(false);
  const [location, setLocation] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [selectedTags, setSelectedTags] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedLocationType, setSelectedLocationType] =
    useState("physical location");
  const fileInputRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [image, setImage] = useState("");

  const [activeTab, setActiveTab] = useState(0);

  //! Tickets
  const [ticketModalOpen, setTicketModalOpen] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [ticketName, setTicketName] = useState("");
  const [ticketPrice, setTicketPrice] = useState("");
  const [ticketQuantity, setTicketQuantity] = useState("");
  const [ticketDescription, setTicketDescription] = useState("");
  const [ticketLimit, setTicketLimit] = useState("");
  const [specifyDates, setSpecifyDates] = useState(false);
  const [availableStarting, setAvaiableStarting] = useState("");
  const [availableUntil, setAvailableUntil] = useState("");
  const [discounted, setDiscounted] = useState(false);
  const [discountedValue, setDiscountedValue] = useState(0);
  const [absorbFees, setAbsorbFees] = useState(false);
  const [discountType, setDiscountType] = useState("");
  const [feeInfoOpen, setFeeInfoOpen] = useState(false);
  const [anchorEls, setAnchorEls] = useState({});
  const [ticketMenuOpen, setTicketMenuOpen] = useState({});

  const [assignTicketsModal, setAssignTicketsModal] = useState(false);
  const [amtAssigned, setAmtAssigned] = useState(0);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null);
  //   const ticketMenuOpen = Boolean(anchorEl);

  const [importOpen, setImportOpen] = useState(false);
  const [addPromoterModalOpen, setAddPromoterModalOpen] = useState(false);
  const [importUrl, setImportUrl] = useState("");
  const [loadingScraper, setLoadingScraper] = useState(false);
  const [importedEvent, setImportedEvent] = useState(null);
  const [userIsPromoter, setUserIsPromoter] = useState(null);
  const [promoterEmail, setPromoterEmail] = useState("");
  const [promoter, setPromoter] = useState(null);

  //! Artists
  const [eventArtists, setEventArtists] = useState([]);

  //! Vouchers State
  const [vouchersSearchLoading, setVouchersSearchLoading] = useState(false);
  const [vouchersOpen, setVouchersOpen] = useState(false);
  const [voucherHolderEmail, setVoucherHolderEmail] = useState("");
  const [voucherHolder, setVoucherHolder] = useState(null);

  //!Settings
  const [paymentSettings, setPaymentSettings] = useState({
    paymentType: "artistRevenueSplit", // Default value
    artistRevenueSplitPercentage: 20, // Default slider value
    artistRevenueSplit: {
      artistRevenue: 0,
      artistRevenueLabel: "",
    },
    flatFee: {
      thresholds: [
        { min: 0, max: 10, flatFee: 0, artistRevenueSplitEligible: false },
      ],
    },
    hybrid: {
      thresholds: [{ min: 0, max: 10, flatFee: 0, artistRevenue: 0 }],
    },
  });

  const handleTabChange = (event, newValue) => {
    console.log("new value =>", newValue);
    setActiveTab(newValue);
  };

  const handleRecurring = () => {
    setRecurring(!recurring);
  };

  //   !========= TICKET FUNTIONS ===========================

  const handleClickTicketMenu = (event, idx) => {
    setAnchorEls({ ...anchorEls, [idx]: event.currentTarget });
    setTicketMenuOpen({ ...ticketMenuOpen, [idx]: true });
  };

  const handleCloseTicketMenu = (idx) => {
    setAnchorEls({ ...anchorEls, [idx]: null });
    setTicketMenuOpen({ ...ticketMenuOpen, [idx]: false });
  };

  const clearTicket = () => {
    // Clear ticket input fields
    setTicketName("");
    setTicketPrice("");
    setTicketQuantity("");
    setTicketDescription("");
    setTicketLimit("");
    setAbsorbFees(false);
    setDiscounted(false);
    setDiscountedValue(0);
    setDiscountType("");
    setSpecifyDates(false);
    setAvaiableStarting("");
    setAvailableUntil("");
    setSelectedTicket(null);
    setTicketModalOpen(false);
  };

  const updateEvent = async (event) => {
    await db
      .collection("events")
      .doc(event.id)
      .update(
        {
          event,
        },
        { merge: true }
      )
      .then(() => {
        setEventArtists(event.artists);
        setTickets(event.tickets);
        setSelectedTicket(null);
        setSelectedArtist(null);
        setAssignTicketsModal(false);
        setAmtAssigned(0);
        setSuccess(true);
        setMessage("Tickets assigned successfully.");
        let eventIdx = events.find((e) => e.id === event.id);
        setEvents(
          events.map((e, i) =>
            i === events.indexOf(eventIdx) ? { ...eventIdx, ...event } : e
          )
        );

        // setEvents([...events]);
      })
      .catch((error) => {
        console.log("THERE IS AN ERR => ", error);
        setError(true);
        setMessage("Something went wrong");
      });
  };

  const updateDBTickets = async (tix) => {
    await db
      .collection("events")
      .doc(event.id)
      .update(
        {
          ...event,
          tickets: tix,
        },
        { merge: true }
      )
      .then(() => {
        setTickets(tix);
        setSuccess(true);
        setMessage("Ticket updated successfully!");
        setEvents([...events]);
      })
      .catch((error) => {
        console.log("THERE IS AN ERR => ", error);
        setError(true);
        setMessage("Something went wrong");
      });
  };

  const handleAddOrUpdateTicket = async (selected) => {
    if (!selected) {
      let newTicket = {
        discountValue: discounted ? discountedValue : 0,
        price: Number(ticketPrice),
        sold: 0,
        description: ticketDescription,
        discountType: discounted ? discountType : "",
        name: ticketName,
        remaining: ticketQuantity,
        quantity: ticketQuantity,
        limit: ticketLimit,
        discounted: discounted,
        absorbFees: absorbFees,
        specifyDates: specifyDates,
        hidden: false,
      };

      if (specifyDates) {
        newTicket.availableStarting = availableStarting;
        newTicket.availableUntil = availableUntil;
      }
      console.log(newTicket);

      await updateDBTickets([...tickets, newTicket]);
      // setSelectedTicket(null)
      clearTicket();
    } else {
      const index = tickets.findIndex((t) => t.name === selected.name);
      let tix = [...tickets];
      tix[index].discountValue = discounted ? discountedValue : 0;
      tix[index].price = ticketPrice;
      tix[index].description = ticketDescription;
      tix[index].discountType = discounted ? discountType : "";
      tix[index].name = ticketName;
      tix[index].remaining = ticketQuantity;
      tix[index].quantity = ticketQuantity;
      tix[index].limit = ticketLimit;
      tix[index].discounted = discounted;
      tix[index].absorbFees = absorbFees;
      tix[index].specifyDates = specifyDates;
      tix[index].hidden = false;
      if (specifyDates) {
        tix[index].availableStarting = availableStarting;
        tix[index].availableUntil = availableUntil;
      }
      console.log(tix[index]);
      await updateDBTickets(tix);
      clearTicket();
    }
  };

  const handleHideTicket = async (index) => {
    console.log(index);
    let ticketsCopy = [...tickets];
    let ticket = ticketsCopy[index];
    ticket.hidden = !ticket.hidden;
    ticketsCopy[index] = ticket;

    await updateDBTickets(ticketsCopy);
  };

  const handleDeleteTicket = async (index) => {
    const newTickets = tickets.filter((_, i) => i !== index);
    await updateDBTickets(newTickets);
  };

  const assignTicketsToArtist = async () => {
    try {
      // Update the selected artist's ticket assignment
      const ticketsAssigned = {
        ...selectedTicket,
        remaining: amtAssigned,
        quantity: amtAssigned,
      };

      const updatedArtists = eventArtists.map((artist) => {
        if (artist.uid === selectedArtist.uid) {
          return {
            ...artist,
            assignedTickets: artist?.assignedTickets
              ? [...artist.assignedTickets, ticketsAssigned]
              : [ticketsAssigned],
          };
        }
        return artist;
      });

      // Update the tickets remaining and assigned in the selected ticket
      const updatedTickets = tickets.map((ticket) => {
        if (ticket.name === selectedTicket.name) {
          return {
            ...ticket,
            remaining: ticket.remaining - amtAssigned,
            assigned: (ticket.assigned || 0) + amtAssigned,
          };
        }
        return ticket;
      });

      // Assuming you have a function to update the event data in the database
      await updateEvent({
        ...event,
        artists: updatedArtists,
        tickets: updatedTickets,
      });
    } catch (error) {
      setError(true);
      setMessage("Error assigning tickets.");
    }
  };

  const sendVouchers = async () => {
    try {
      let total = 0;
      let index = tickets.findIndex((x) => x.name === selectedTicket.name);
      let ticketCounts = Array.from({ length: tickets.length }).map((x) => 0);
      //   console.log("TIckets Count", ticketCounts, "Index =>", index);
      ticketCounts[index] = amtAssigned;
      total += (
        selectedTicket.price * amtAssigned +
        getTicketFee(selectedTicket.price) * amtAssigned
      ).toFixed(2);

      //   console.log("TIckets Count with amtassigned", ticketCounts);

      let seller = event.promoter;

      const sendVouchers = firebase.functions().httpsCallable("sendVouchers");
      sendVouchers({
        test: true,
        user: voucherHolder,
        seller,
        event: event,
        ticketCounts,
        total,
      }).then((res) => {
        console.log("RESULT => ", res);
      });
    } catch (error) {
      setError(true);
      setMessage("Error sending vouchers.");
    }
  };

  const handleSelectUpdateTicket = (ticket) => {
    setSelectedTicket(ticket);
    setTicketName(ticket.name);
    setTicketPrice(ticket.price);
    setTicketQuantity(ticket.remaining);
    setTicketDescription(ticket.description);
    setAbsorbFees(ticket.absorbFees);
    setDiscountType(ticket.discountType);
    setDiscountedValue(ticket.discountValue);
    setDiscounted(ticket.discounted);
    setTicketLimit(ticket.limit);
    setSpecifyDates(ticket.specifyDates);
    if (ticket.availableStarting) {
      setAvaiableStarting(new Date(ticket.availableStarting.seconds * 1000));
    }
    if (ticket.availableEnding) {
      setAvailableUntil(new Date(ticket.availableEnding.seconds * 1000));
    }
  };

  const handleUserSearch = async () => {
    setVouchersSearchLoading(true);
    const foundUsers = await db
      .collection("users")
      .where("email", "==", voucherHolderEmail.toLowerCase().trim())
      .get();

    if (foundUsers.docs.length > 0) {
      setVoucherHolder(foundUsers.docs[0].data());
      setSuccess(true);
      setMessage("User found!");
      setVouchersSearchLoading(false);
    } else {
      setVoucherHolderEmail("");
      setError(true);
      setMessage("User not found!");
      setVouchersSearchLoading(false);
    }
  };

  const handleChangeLocationType = (event) => {
    if (event.target.value === "tbd") {
      setLocation("Location is TBD");
      setCoordinates({ lat: null, lng: null });
    } else {
      setLocation("");
      setCoordinates({ lat: null, lng: null });
    }
    setSelectedLocationType(event.target.value);
  };

  const handleChange = async (value) => {
    setLocation(value);
  };

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);

    setLocation(value);
    setCoordinates(latLng);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const saveSettings = async () => {
    const updatedEvent = {
      ...event,
      paymentSettings: paymentSettings, // Store the updated payment settings
    };

    try {
      await db.collection("events").doc(event.id).update(updatedEvent);
      setSuccess(true);
      setMessage("Settings saved successfully!");
    } catch (error) {
      setError(true);
      setMessage("Failed to save settings.");
    }
  };

  const saveOrPuplishEvent = async (isDraft) => {
    if (event) {
      console.log(event);
      //   console.log("Image File", imageFile);
      let dbImage;
      if (imageFile) {
        let imagePath = storage.ref(
          `artists/eventImages/${userData.uid}/${imageFile.name}`
        );
        let uploadTask = imagePath.put(imageFile);
        await uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGE,
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log("Upload is " + progress + "% done");
          }
        );
        await uploadTask
          .then((snapshot) => {
            console.log("Uploaded a file! Image URL will be here.");
            return imagePath.getDownloadURL();
          })
          .then((downloadURL) => {
            dbImage = downloadURL;
          });
      }
      // Update event
      let selectedEvent = events.find((e) => e.id === event.id);
      selectedEvent.title = title;
      selectedEvent.description = description;
      selectedEvent.teaser = descriptionTeaser;
      selectedEvent.startDate =
        date !== "" && time !== ""
          ? new Date(date + " " + time)
          : new Date(selectedEvent.startDate);
      selectedEvent.endDate =
        endDate !== "" && endTime !== ""
          ? new Date(endDate + " " + endTime)
          : new Date(selectedEvent.endDate);
      selectedEvent.location = location;
      selectedEvent.coordinates = coordinates;
      selectedEvent.image = dbImage ? dbImage : image;
      selectedEvent.tickets = tickets;
      selectedEvent.draft = false;
      selectedEvent.tags = selectedTags;
      selectedEvent.artists = eventArtists;

      await db
        .collection("events")
        .doc(event.id)
        .update(
          {
            ...event,
            title: selectedEvent.title,
            description: selectedEvent.description,
            teaser: selectedEvent.teaser,
            startDate: selectedEvent.startDate,
            endDate: selectedEvent.endDate,
            location: selectedEvent.location,
            coordinates: selectedEvent.coordinates,
            image: selectedEvent.image,
            tickets: selectedEvent.tickets,
            draft: selectedEvent.draft,
            tags: selectedEvent.tags,
            artists: selectedEvent.artists,
          },
          { merge: true }
        )
        .then(() => {
          console.log("Event updated successfully", isDraft);
          setSuccess(true);
          setMessage(
            isDraft ? "Event saved successfully" : "Event updated successfully"
          );
          const eventIdx = events.findIndex((e) => e.id === event.id);
          events[eventIdx] = selectedEvent;
          if (!isDraft) {
            console.log("is not a draft");
            //send email to artists who have been added to an event and thier email has not been sent out
            const artistsToNotify = selectedEvent.artists.filter(
              (artist) => !artist.emailSent || artist.emailSent === false
            );

            console.log("Sending email to event artits");
            try {
              if (artistsToNotify.length > 0) {
                artistsToNotify.forEach((artist) => {
                  const artistEmail = artist?.email;
                  const artistName = artist?.name;
                  const artistId = artist?.uid || false;
                  const artistTickets =
                    artist?.assignedTickets
                      ?.map(
                        (ticket) =>
                          ticket?.quantity + " " + ticket?.name + " tickets"
                      )
                      ?.join(", ") || "none";

                  //send email from callable
                  if (artist.emailSent)
                    console.log("Sending confirmation email..");

                  const notifyArtists = firebase
                    .functions()
                    .httpsCallable("sendEmailToAddedArtist");
                  notifyArtists({
                    eventId: selectedEvent.id,
                    eventTitle: selectedEvent.title,
                    eventStart:
                      new Date(
                        selectedEvent?.startDate?.seconds * 1000
                      ).toLocaleString() || "",
                    artistEmail,
                    artistName,
                    artistId,
                    artistTickets,
                    assignedBy: {
                      name: userData?.name,
                      email: userData?.email,
                      uid: userData?.uid,
                    },
                  })
                    .then((result) => {
                      console.log("Email sent to artist", artistName);
                      //update artist email sent status
                      const artistIdx = selectedEvent.artists.findIndex(
                        (a) => a.uid === artistId
                      );
                      selectedEvent.artists[artistIdx].emailSent = true;
                      setEvents([...events]);
                    })
                    .catch((error) => {
                      console.error("Error sending email to artist", error);
                    });
                });

                events[eventIdx] = selectedEvent;
                db.collection("events").doc(selectedEvent.id).update({
                  artists: selectedEvent.artists,
                });
                setEvents([...events]);
              }
            } catch (error) {
              console.error("Error sending email to artist", error);
            }
          } else {
            setEvents([...events]);
          }
          setClose();
        })
        .catch((error) => {
          console.log("THERE IS AN ERR => ", error);
          setError(true);
          setMessage("Something went wrong");
        });
    } else {
      // Add event
      console.log("Image File", imageFile);
      let dbImage;
      let imagePath;
      if (imageFile) {
        imagePath = storage.ref(
          `artists/eventImages/${userData.uid}/${imageFile.name}`
        );
        let uploadTask = imagePath.put(imageFile);
        await uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            console.log("Upload is " + progress + "% done");
          }
        );
        await uploadTask
          .then((snapshot) => {
            console.log("Uploaded a file! Image URL will be here.");
            return imagePath.getDownloadURL();
          })
          .then((downloadURL) => {
            dbImage = downloadURL;
          });
      }

      let newEvent = {
        eventOwnerId: userData.uid,
        artists: [],
        artistId: userData.uid,
        coordinates,
        description: description,
        endDate:
          endDate !== "" && endTime !== ""
            ? new Date(endDate + " " + endTime)
            : new Date(),
        id:
          Math.floor(Math.random() * 1000000000).toString() +
          "-" +
          userData.uid,
        image: dbImage ? dbImage : imagePath,
        location: location,
        startDate:
          date !== "" && time !== "" ? new Date(date + " " + time) : new Date(),
        teaser: descriptionTeaser,
        tickets: tickets,
        title: title,
        draft: isDraft ? true : false,
        isHidden: false,
        promoter: {
          name: promoter ? promoter.name : userData.name,
          email: promoter ? promoter.email : userData.email,
          phone: promoter ? promoter.phone : userData.phone,
          uid: promoter ? promoter.uid : userData.uid,
        },
        test: testMode,
        tags: selectedTags,
        artists: eventArtists,
      };

      console.log(newEvent, time, date);

      await db
        .collection("events")
        .doc(newEvent.id)
        .set(newEvent)
        .then(function () {
          setSuccess(true);
          setMessage(
            isDraft ? "Event saved successfully" : "Event created successfully"
          );
        })
        .catch(function (error) {
          console.log("Error adding document: ", error);
          setError(true);
          setMessage("Error adding event, please try again.");
        });

      setEvents([...events, newEvent]);
      setClose();
    }
  };

  const handleImageSelect = async (e) => {
    console.log("this is the e", e);
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      //set file to state for upload
      setImageFile(file);
      const reader = await new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log("result", reader.result);
        setImage(reader.result);
        setImageSrc(reader.result);
      };
    }
  };

  const handleConfirmPromoter = async () => {
    let updatedEvent = {
      ...event,
      promoter: {
        name: userData.name,
        email: userData.email,
        phone: userData.phone,
        uid: userData.uid,
      },
    };

    try {
      await db
        .collection("events")
        .doc(event.id)
        .update({
          promoter: {
            name: userData.name,
            email: userData.email,
            phone: userData.phone,
            uid: userData.uid,
          },
        })
        .then(() => {
          //update events
          setEvents(events.map((e) => (e.id === event.id ? updatedEvent : e)));
          setSuccess(true);
          setMessage("Promoter updated successfully");
          setClose();
        });
    } catch (error) {
      console.log(error);
    }

    setEvents();
  };

  const handleClose = () => {
    // Reset form values
    setTitle("");
    setDescription("");
    setDescriptionTeaser("");
    setDate("");
    setTime("");
    setEndDate("");
    setEndTime("");
    setPromoter(null);
    setLocation("");
    setCoordinates({ lat: null, lng: null });
    setImage("");
    setImageFile(null);
    setImageSrc("");
    setTickets([]);
    setClose();
    setImportUrl("");
    setPromoterEmail("");
    setActiveTab(0);
    setSpecifyDates(false);
    setAvaiableStarting("");
    setAvailableUntil("");
    setTestMode(false);
    setIsEventOwner(true);
    setSelectedTags([]);
    setEventArtists([]);
    setActiveTab(0);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email) && email.length < 255 && email.length > 0;
  };

  const handleImportEvent = async () => {
    //call cloud function to scrape eventbrite or ticketshare
    console.log("button clicked");
    setLoadingScraper(true);
    try {
      if (importUrl.includes("eventbrite")) {
        const scrapeEventbrite = functions.httpsCallable(
          "scrapeEventbriteEvent"
        );
        const res = await scrapeEventbrite({
          url: importUrl.split("?")[0],
        });

        if (res.data) {
          let importedEvent = res.data;
          console.log(res);
          //   setSuccess(true);
          //   setMessage("Event imported successfully");
          //   setLoadingScraper(false);
          setImportedEvent(importedEvent);
          //   setTitle(importedEvent.title);
          //   setDescription(importedEvent.description);
          //   setDescriptionTeaser(importedEvent.teaser);
          //   setDate(`${new Date(importedEvent.startDate).toLocaleDateString()}`);
          //   setTime(new Date(importedEvent.startDate).toLocaleTimeString());
          //   setEndDate(`${new Date(importedEvent.endDate).toLocaleDateString()}`);
          //   setEndTime(new Date(importedEvent.endDate).toLocaleTimeString());
          //   setLocation(importedEvent.location?.split("Show map")[0]);
          //   setCoordinates(importedEvent.coordinates)
          //   setImage(importedEvent.image);
          setAddPromoterModalOpen(true);
          //   setImportOpen(false);
        } else {
          setLoadingScraper(false);
          setError(true);
          setMessage("Error importing event, please try again.");
          setLoadingScraper(false);
        }
      }
    } catch (error) {
      console.error("Error => ", error);
    }
  };

  const handleAddPromoter = async () => {
    let foundPromoter;

    if (userIsPromoter) {
      foundPromoter = {
        name: userData.name,
        email: userData.email,
        phone: userData.phone,
        uid: userData.uid,
      };
    } else {
      foundPromoter = await db
        .collection("promoters")
        .where("email", "==", promoterEmail)
        .get()
        .then((query) => {
          if (query.empty) {
            return {
              name: "",
              email: promoterEmail,
              phone: "",
              uid: "",
            };
          } else {
            return query.docs.map((doc) => doc.data())[0];
          }
        });
    }

    setPromoter(foundPromoter);

    if (importedEvent) {
      setLoadingScraper(false);
      setImportedEvent(importedEvent);
      setTitle(importedEvent.title);
      setDescription(importedEvent.description);
      setDescriptionTeaser(importedEvent.teaser);
      setDate(`${new Date(importedEvent.startDate).toLocaleDateString()}`);
      setTime(new Date(importedEvent.startDate).toLocaleTimeString());
      setEndDate(`${new Date(importedEvent.endDate).toLocaleDateString()}`);
      setEndTime(new Date(importedEvent.endDate).toLocaleTimeString());
      setLocation(importedEvent.location?.split("Show map")[0]);
      // setCoordinates(importedEvent.coordinates)
      setImage(importedEvent.image);
      setImportOpen(false);
    }
    setSuccess(true);
    setMessage("Promoter added and Event imported successfully");
    setAddPromoterModalOpen(false);
  };

  const handleChangeTags = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTags(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    if (event) {
      console.log(new Date(event.startDate));
      console.log(new Date(event?.startDate));
      //TODO: This will need to be addressed ASAP!!!
      if (
        event?.eventOwnerId === userData.uid ||
        (event?.promoter?.uid === userData.uid &&
          event?.artistId === userData.uid)
      ) {
        setIsEventOwner(true);
      } else {
        setIsEventOwner(false);
      }

      setTitle(event.title);
      setDescription(event.description);
      setDescriptionTeaser(event.teaser);
      if (!event?.startDate?.seconds) {
        setDate(new Date(event.startDate).toLocaleDateString());
        setTime(`${new Date(event.startDate).toLocaleTimeString()}`);
        setEndDate(`${new Date(event.endDate).toLocaleDateString()}`);
        setEndTime(`${new Date(event.endDate).toLocaleTimeString()}`);
      } else {
        setDate(new Date(event.startDate.seconds * 1000).toLocaleDateString());
        setTime(
          `${new Date(event.startDate.seconds * 1000).toLocaleTimeString()}`
        );
        setEndDate(
          `${new Date(event.endDate.seconds * 1000).toLocaleDateString()}`
        );
        setEndTime(
          `${new Date(event.endDate.seconds * 1000).toLocaleTimeString()}`
        );
      }

      setLocation(event.location);
      setImage(event.image);
      if (event.tags) {
        setSelectedTags(event.tags);
      }
      if (event.artists) {
        setEventArtists(event.artists);
      }
      setTickets(event.tickets);
      if (event.promoter) {
        setPromoter(event.promoter);
      } else {
        setPromoter({
          name: userData.name,
          email: userData.email,
          phone: userData.phone,
          uid: userData.uid,
        });
      }

      setPaymentSettings(initializeSettings(event));
    } else {
      // setIsEventOwner(true)
    }
  }, [event]);

  useEffect(() => {
    console.log("tickets =>", tickets);
  }, [tickets]);

  return (
    <Modal open={open} onClose={handleClose}>
      <>
        <Toast />
        <Grid
          item
          container
          xs={11}
          md={10}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "lightgrey",
            borderRadius: "12px",
            padding: { xs: "10px", md: "20px" },
            height: "fit-content",
          }}
        >
          <Grid
            item
            container
            xs={12}
            justifyContent={"space-between"}
            alignItems={"center"}
            style={{ marginBottom: 10 }}
          >
            <Typography
              variant="h4"
              style={{ fontWeight: "bold" }}
              sx={{
                textAlign: { xs: "center", md: "left" },
                width: { xs: "100%", md: "49%" },
              }}
            >
              {event
                ? !isArtist
                  ? "View Event"
                  : "Edit event"
                : "Create event"}
              {!event && (
                <Tooltip title="This event will not be live. Only you will see this.">
                  <FormControlLabel
                    style={{ marginLeft: 5 }}
                    label={<Typography variant="caption">Test Mode</Typography>}
                    control={
                      <Switch
                        checked={testMode}
                        onChange={() => setTestMode(!testMode)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />
                </Tooltip>
              )}
            </Typography>

            {isEventOwner || isArtist ? (
              <Grid
                item
                container
                xs={12}
                md={6}
                sx={{ justifyContent: { xs: "space-evenly", md: "flex-end" } }}
              >
                <Tooltip title="Clear changes">
                  <Button variant="contained" onClick={handleClose}>
                    Cancel
                  </Button>
                </Tooltip>
                <Tooltip title="Save as Draft and close">
                  <Button
                    variant="contained"
                    onClick={() => saveOrPuplishEvent(true)}
                    style={{ margin: "0px 5px" }}
                  >
                    {event ? "Save" : "Create Draft"}
                  </Button>
                </Tooltip>
                {event && (
                  <Tooltip title="All changes will be live">
                    <Button
                      disabled={
                        !title ||
                        !date ||
                        !time ||
                        !endDate ||
                        !endTime ||
                        (date &&
                          time &&
                          endDate &&
                          endTime &&
                          new Date(`${date} ${time}`) >=
                            new Date(`${endDate} ${endTime}`)) ||
                        !location ||
                        tickets.length === 0
                      }
                      variant="contained"
                      onClick={() => saveOrPuplishEvent(false)}
                    >
                      Publish
                    </Button>
                  </Tooltip>
                )}
              </Grid>
            ) : (
              <IconButton>
                <Close onClick={handleClose} />
              </IconButton>
            )}
          </Grid>

          <Grid
            item
            container
            xs={12}
            sx={{
              padding: "10px",
              maxHeight: { xs: "70dvh", md: "80dvh" },
              overflowY: "scroll",
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                borderRadius: 12,
                background: "white",
                padding: 10,
                margin: "20px 0px",
              }}
            >
              {/* <Typography
                variant="h6"
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  borderBottom: "1px solid grey",
                }}
              >
                Tabs
              </Typography> */}

              <Grid item container xs={12} style={{ padding: 10 }}>
                <Tabs value={activeTab} onChange={handleTabChange} centered>
                  <Tab label="General" />
                  <Tab label="Tickets & Seating" disabled={!event} />
                  <Tab label="Settings" disabled={!event} />
                </Tabs>
              </Grid>
            </Grid>

            {activeTab === 0 && (
              <>
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    borderRadius: 12,
                    background: "white",
                    padding: 10,
                    height: "fit-content",
                  }}
                >
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      justifyContent: "space-between",
                      borderBottom: "1px solid grey",
                      paddingBottom: 5,
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      General Info
                    </Typography>

                    <Button
                      onClick={() => setImportOpen(true)}
                      variant="contained"
                      disabled={userData?.worksWithPromoters !== "yes"}
                    >
                      Import Event
                    </Button>
                  </Grid>
                  <Grid item container xs={12} style={{ padding: 10 }}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={8}
                      style={{ padding: 10, position: "relative" }}
                    >
                      <Typography variant="caption" style={{ width: "100%" }}>
                        Event Name
                      </Typography>
                      <TextField
                        disabled={!isArtist && !isEventOwner}
                        fullWidth
                        placeholder="Enter the name of your event..."
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        style={{ marginBottom: 10 }}
                      />
                      <Typography variant="caption" style={{ width: "100%" }}>
                        Short teaser (optional)
                      </Typography>
                      <TextField
                        disabled={!isArtist && !isEventOwner}
                        fullWidth
                        multiline
                        rows={2}
                        placeholder="Add a few words under the name of the event, to inspire guest, e.g., An unforgettable show!"
                        value={descriptionTeaser}
                        onChange={(e) => setDescriptionTeaser(e.target.value)}
                        style={{ marginBottom: 10 }}
                      />

                      <Grid
                        item
                        container
                        xs={12}
                        sx={{ display: { xs: "none", md: "block" } }}
                        style={{
                          position: "absolute",
                          bottom: 10,
                          left: 10,
                          alignItems: "center",
                        }}
                      >
                        {promoter ? (
                          <>
                            <Typography
                              variant="caption"
                              style={{ marginRight: 5 }}
                            >
                              {promoter.name !== "" ? (
                                `Promoter: ${promoter.name} (${promoter.email})`
                              ) : (
                                <>
                                  <b>Promoter</b>: {promoter.email}{" "}
                                  <i>(pending)</i>
                                  <>
                                    {promoter.email === userData.email && (
                                      <Button
                                        style={{ marginLeft: 5 }}
                                        onClick={handleConfirmPromoter}
                                      >
                                        Confirm
                                      </Button>
                                    )}
                                  </>
                                </>
                              )}
                            </Typography>
                            {promoter.name !== "" ? (
                              <IconButton
                                disabled={!isArtist}
                                onClick={() => setAddPromoterModalOpen(true)}
                              >
                                <Edit />
                              </IconButton>
                            ) : (
                              <>
                                {isArtist && (
                                  <Tooltip title="Once you have added a promoter, they can not be removed.">
                                    <Info />
                                  </Tooltip>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Typography
                              variant="caption"
                              style={{ marginRight: 5 }}
                            >
                              <b>Promoter</b>: ME
                            </Typography>
                            <Tooltip title="Once you have added a promoter, they can not be removed.">
                              <IconButton
                                disabled={!isArtist && !isEventOwner}
                                onClick={() => setAddPromoterModalOpen(true)}
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      sx={{ height: { xs: "20dvh", lg: "350px" } }}
                      style={{ padding: 10 }}
                    >
                      <Typography variant="caption" style={{ width: "100%" }}>
                        Event Image
                      </Typography>
                      <motion.div
                        whileHover={{
                          opacity: 0.75,
                        }}
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          height: "100%",
                          height: "100%",
                          padding: 10,
                          border: "2px dashed lightgrey",
                          position: "relative",
                        }}
                      >
                        <motion.div
                          whileHover={{ opacity: 1 }}
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            opacity: 0.5,
                          }}
                          onDrop={
                            (isArtist || isEventOwner) && handleImageSelect
                          }
                        >
                          {(isArtist || isEventOwner) && (
                            <Button
                              variant="contained"
                              onClick={() => {
                                (isArtist || isEventOwner) &&
                                  fileInputRef.current.click();
                              }}
                            >
                              {image ? "Change" : "Add"}
                            </Button>
                          )}
                        </motion.div>
                        {image && (
                          <img
                            src={image}
                            alt=""
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </motion.div>
                      <input
                        ref={fileInputRef}
                        onChange={handleImageSelect}
                        type="file"
                        //accept png, jpg, jpeg, gif only
                        accept="image/png, image/jpeg, image/jpg, image/gif"
                        style={{ display: "none" }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    sx={{ display: { xs: "block", md: "none" } }}
                    style={{
                      marginTop: 10,
                    }}
                  >
                    {promoter ? (
                      <>
                        <Typography
                          variant="caption"
                          style={{ marginRight: 5 }}
                        >
                          {promoter.name !== "" ? (
                            `Promoter: ${promoter.name} (${promoter.email})`
                          ) : (
                            <>
                              <b>Promoter</b>: {promoter.email} <i>(pending)</i>
                              <>
                                {promoter.email === userData.email && (
                                  <Button
                                    style={{ marginLeft: 5 }}
                                    onClick={handleConfirmPromoter}
                                  >
                                    Confirm
                                  </Button>
                                )}
                              </>
                            </>
                          )}
                        </Typography>
                        {promoter.name !== "" ? (
                          <IconButton
                            disabled={!isArtist && !isEventOwner}
                            onClick={() => setAddPromoterModalOpen(true)}
                          >
                            <Edit />
                          </IconButton>
                        ) : (
                          <>
                            {isArtist && (
                              <Tooltip title="Once you have added a promoter, they can not be removed.">
                                <Info />
                              </Tooltip>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="caption"
                          style={{ marginRight: 5 }}
                        >
                          <b>Promoter</b>: ME
                        </Typography>
                        <Tooltip title="Once you have added a promoter, they can not be removed.">
                          <IconButton
                            disabled={!isArtist}
                            onClick={() => setAddPromoterModalOpen(true)}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    borderRadius: 12,
                    background: "white",
                    padding: 10,
                    marginTop: 20,
                  }}
                >
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      justifyContent: "space-between",
                      borderBottom: "1px solid grey",
                      paddingBottom: 5,
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Date and time
                    </Typography>
                    <Tooltip title="This event is a part of a series of events.">
                      <FormControlLabel
                        style={{ marginLeft: 5 }}
                        label={
                          <Typography variant="caption">Reccuring</Typography>
                        }
                        labelPlacement="start"
                        control={
                          <Switch
                            small
                            checked={recurring}
                            onChange={handleRecurring}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                      />
                    </Tooltip>
                  </Grid>
                  {recurring && (
                        <Grid item container xs={12} style={{ marginTop: 20 }}>
                          <Typography
                            variant="caption"
                            style={{ width: "100%", marginBottom: 10 }}
                          >
                            What day of the week does this event recur?
                          </Typography>
                          {weekDays.map((day, idx) => {
                            return (
                              <Button
                                key={idx}
                                variant="outlined"
                                style={{
                                  borderRadius: 30,
                                  fontSize: 10,
                                  marginRight: 5,
                                }}
                              >
                                {day.short}
                              </Button>
                            );
                          })}
                        </Grid>
                      )}

                      {recurring && (
                        <Grid item container xs={12} style={{ marginTop: 20 }}>
                          <Typography
                            variant="caption"
                            style={{ width: "100%", marginBottom: 10 }}
                          >
                            How many times will this event occur?
                          </Typography>
                          {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((num, idx) => {
                            return (
                              <Button
                                key={idx}
                                variant="outlined"
                                style={{
                                  borderRadius: 30,
                                  fontSize: 10,
                                  marginRight: 5,
                                }}
                              >
                                {num}
                              </Button>
                            );
                          })}
                        </Grid>
                      )}
                  <Grid item container xs={12} style={{ padding: 10 }}>
                    <Grid item xs={12} style={{ padding: 10 }}>
                      <Typography variant="caption" style={{ width: "100%" }}>
                        Start date
                      </Typography>
                      <Grid
                        item
                        container
                        xs={12}
                        md={8}
                        justifyContent={"space-between"}
                      >
                        {date && time ? (
                          <>
                            <Typography>{date + " " + time}</Typography>
                            <IconButton
                              disabled={!isArtist && !isEventOwner}
                              onClick={() => {
                                setDate("");
                                setTime("");
                              }}
                            >
                              <Edit />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              style={{ width: "60%" }}
                            >
                              <DatePicker
                                label="Start Date"
                                onChange={(e) => {
                                  console.log(`${e.$d}`.split(" 00:00:00 ")[0]);
                                  setDate(`${e.$d}`.split(" 00:00:00 ")[0]);
                                }}
                              />
                            </LocalizationProvider>

                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              style={{ width: "60%" }}
                            >
                              <TimePicker
                                label="Start Time"
                                ampm={true} // Ensures 12-hour format with AM/PM
                                value={time ? dayjs(`${date} ${time}`) : null} // Use dayjs to set a valid value
                                onAccept={(e) => {
                                  if (e) {
                                    const hours = e.$H < 10 ? `0${e.$H}` : e.$H;
                                    const minutes =
                                      e.$m < 10 ? `0${e.$m}` : e.$m;
                                    setTime(`${hours}:${minutes}:00`);
                                  }
                                }}
                              />
                            </LocalizationProvider>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={8} style={{ padding: 10 }}>
                      <Typography variant="caption" style={{ width: "100%" }}>
                        End date
                      </Typography>
                      <Grid
                        item
                        container
                        xs={12}
                        justifyContent={"space-between"}
                      >
                        {endDate && endTime ? (
                          <>
                            <Typography>{endDate + " " + endTime}</Typography>
                            <IconButton
                              disabled={!isArtist && !isEventOwner}
                              onClick={() => {
                                setEndDate("");
                                setEndTime("");
                              }}
                            >
                              <Edit />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              style={{ width: "60%" }}
                            >
                              <DatePicker
                                label="End Date"
                                onChange={(e) => {
                                  if (
                                    new Date(`${e.$d}`).getTime() <
                                    new Date(date).getTime()
                                  ) {
                                    setError(true);
                                    setMessage(
                                      "End date cannot be before start date"
                                    );
                                  } else {
                                    setEndDate(
                                      `${e.$d}`.split(" 00:00:00 ")[0]
                                    );
                                  }
                                }}
                              />
                            </LocalizationProvider>

                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              style={{ width: "60%" }}
                            >
                              <TimePicker
                                label="End Time"
                                ampm={true}
                                value={
                                  endTime
                                    ? dayjs(`${endDate} ${endTime}`)
                                    : null
                                }
                                onAccept={(e) => {
                                  if (e) {
                                    const hours = e.$H < 10 ? `0${e.$H}` : e.$H;
                                    const minutes =
                                      e.$m < 10 ? `0${e.$m}` : e.$m;
                                    if (
                                      new Date(`${date} ${time}`) >=
                                      new Date(
                                        `${endDate} ${hours}:${minutes}:00`
                                      )
                                    ) {
                                      setError(true);
                                      setMessage(
                                        "End time cannot be before start time"
                                      );
                                    } else {
                                      setEndTime(`${hours}:${minutes}:00`);
                                    }
                                  }
                                }}
                              />
                            </LocalizationProvider>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    borderRadius: 12,
                    background: "white",
                    padding: 10,
                    marginTop: 20,
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      width: "100%",
                      fontWeight: "bold",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    Location
                  </Typography>
                  {isArtist || isEventOwner ? (
                    <Grid item container xs={12} style={{ padding: 10 }}>
                      <Grid item xs={12} style={{ padding: 10 }}>
                        <Typography variant="caption" style={{ width: "100%" }}>
                          Select one
                        </Typography>
                        <Grid
                          item
                          container
                          xs={12}
                          md={8}
                          justifyContent={"space-between"}
                        >
                          {/*  */}
                          <Grid
                            item
                            container
                            xs={12}
                            md={4}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            style={{
                              padding: 10,
                            }}
                          >
                            <Grid
                              item
                              container
                              xs={12}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              style={{
                                border:
                                  selectedLocationType === "physical location"
                                    ? "2px solid red"
                                    : "1px solid lightgrey",
                                borderRadius: 8,
                                height: "fit-content",
                                padding: 10,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Radio
                                  checked={
                                    selectedLocationType === "physical location"
                                  }
                                  onChange={handleChangeLocationType}
                                  value="physical location"
                                  name="radio-buttons"
                                  inputProps={{
                                    "aria-label": "Physical location",
                                  }}
                                  style={{
                                    padding: 0,
                                    color:
                                      selectedLocationType ===
                                      "physical location"
                                        ? "red"
                                        : "",
                                  }}
                                  //   color="red"
                                />
                                <Typography style={{ marginLeft: 5 }}>
                                  Physical location
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            md={4}
                            style={{
                              padding: 10,
                            }}
                          >
                            <Grid
                              item
                              container
                              xs={12}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              style={{
                                border:
                                  selectedLocationType === "online"
                                    ? "2px solid red"
                                    : "1px solid lightgrey",
                                borderRadius: 8,
                                height: "fit-content",
                                padding: 10,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Radio
                                  checked={selectedLocationType === "online"}
                                  onChange={handleChangeLocationType}
                                  value="online"
                                  name="radio-buttons"
                                  inputProps={{ "aria-label": "Online" }}
                                  style={{
                                    padding: 0,
                                    color:
                                      selectedLocationType === "online"
                                        ? "red"
                                        : "",
                                  }}
                                />
                                <Typography style={{ marginLeft: 5 }}>
                                  Online
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            md={4}
                            style={{
                              padding: 10,
                            }}
                          >
                            <Grid
                              item
                              container
                              xs={12}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                              style={{
                                border:
                                  selectedLocationType === "tbd"
                                    ? "2px solid red"
                                    : "1px solid lightgrey",
                                borderRadius: 8,
                                height: "fit-content",
                                padding: 10,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Radio
                                  checked={selectedLocationType === "tbd"}
                                  onChange={handleChangeLocationType}
                                  value="tbd"
                                  name="radio-buttons"
                                  inputProps={{ "aria-label": "TBD" }}
                                  style={{
                                    padding: 0,
                                    color:
                                      selectedLocationType === "tbd"
                                        ? "red"
                                        : "",
                                  }}
                                />
                                <Typography style={{ marginLeft: 5 }}>
                                  To be decided (TBD)
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                          {/*  */}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={8} style={{ padding: 10 }}>
                        <Typography variant="caption" style={{ width: "100%" }}>
                          {selectedLocationType === "physical location"
                            ? "Event location"
                            : selectedLocationType === "online"
                            ? "Event URL"
                            : "Display a message"}
                        </Typography>
                        <Grid
                          item
                          container
                          xs={12}
                          justifyContent={"space-between"}
                        >
                          {selectedLocationType === "physical location" ? (
                            <PlacesAutoComplete
                              value={location}
                              onChange={handleChange}
                              onSelect={handleSelect}
                              style={{ width: "inherit" }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div style={{ width: "inherit" }}>
                                  <Paper
                                    elevation={0}
                                    component="form"
                                    style={{
                                      marginLeft: 5,
                                      flex: 1,
                                      display: "flex",
                                      height: 50,
                                      alignItems: "center",
                                      width: "100%",
                                      border: "1px solid lightgrey",
                                      borderRadius: 5,
                                    }}
                                  >
                                    <InputBase
                                      aria-controls="simple-menu"
                                      onClick={handleClick}
                                      {...getInputProps({
                                        placeholder: "Enter Your Address...",
                                      })}
                                      style={{
                                        marginLeft: 5,
                                        flex: 1,
                                        display: "flex",
                                        height: 50,
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    />

                                    {/* <LocationOnOutlined
								style={{
									color: defaultTheme.palette.primary.main,
									fontSize: "40px",
									cursor: "pointer",
								}}
								/> */}
                                  </Paper>

                                  <div
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    style={{
                                      background: "white",
                                    }}
                                  >
                                    {loading && <MenuItem>Loading...</MenuItem>}
                                    {suggestions.map((suggestion) => {
                                      const style = suggestion.active
                                        ? {
                                            backgroundColor:
                                              defaultTheme.palette.primary
                                                .light,
                                            cursor: "pointer",
                                          }
                                        : { backgroundColor: "white" };

                                      return (
                                        <MenuItem
                                          style={{ background: "white" }}
                                          onClick={handleClose}
                                          key={suggestion.description}
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              style,
                                            }
                                          )}
                                        >
                                          {suggestion.description}
                                        </MenuItem>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutoComplete>
                          ) : (
                            <TextField
                              disabled={!isArtist && !isEventOwner}
                              placeholder={
                                selectedLocationType === "online"
                                  ? "Enter your event URL"
                                  : "Location is TBD..."
                              }
                              value={location}
                              onChange={(e) => setLocation(e.target.value)}
                              style={{ marginBottom: 10, width: "100%" }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item container xs={12} style={{ padding: 10 }}>
                      <Typography>
                        <b>Location</b> {location}
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    borderRadius: 12,
                    background: "white",
                    padding: 10,
                    marginTop: 20,
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      width: "100%",
                      fontWeight: "bold",
                      borderBottom: "1px solid grey",
                    }}
                  >
                    About the event (optional)
                  </Typography>
                  <Grid item container xs={12} style={{ padding: 10 }}>
                    <Grid item xs={12} style={{ padding: 10 }}>
                      <Typography variant="caption" style={{ width: "100%" }}>
                        What is this event about?
                      </Typography>
                      <TextField
                        disabled={!isArtist && !isEventOwner}
                        fullWidth
                        multiline
                        rows={4}
                        placeholder="Use this space to tell guests more about this event, e.g., event schedule, speakers, important info, & more."
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        style={{ marginBottom: 10 }}
                      />

                      <Typography
                        variant="caption"
                        style={{ width: "100%", marginTop: 20 }}
                      >
                        Add Tags? Help event goers find your event with tags.
                      </Typography>

                      <FormControl sx={{ mt: 1, width: "100%" }}>
                        <InputLabel id="tags-chip-label">Tags</InputLabel>
                        <Select
                          style={{ width: "100%" }}
                          labelId="tags-chip-label"
                          id="tags-chip"
                          multiple
                          value={selectedTags}
                          onChange={handleChangeTags}
                          input={
                            <OutlinedInput
                              id="tags-multiple-chip"
                              label="Tags"
                            />
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  style={{ zIndex: 10 }}
                                  key={value}
                                  label={value}
                                  onDelete={() => {
                                    setSelectedTags(
                                      selectedTags.filter(
                                        (tag) => tag !== value
                                      )
                                    );
                                  }}
                                />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {eventTags.map((tag) => (
                            <MenuItem key={tag} value={tag}>
                              {tag}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {activeTab === 1 && event && (
              <>
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    borderRadius: 12,
                    background: "white",
                    padding: 10,
                    marginTop: 20,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      // width: "100%",
                      fontWeight: "bold",
                      // borderBottom: "1px solid grey",
                    }}
                  >
                    Seat Mapping
                  </Typography>
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    size="small"
                    style={{ borderRadius: 40 }}
                    disabled
                  >
                    Add Seat Map
                  </Button>
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    borderRadius: 12,
                    background: "white",
                    padding: 10,
                    marginTop: 20,
                    flexDirection: "row",
                  }}
                >
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      background: "white",
                      justifyContent: "space-between",
                      borderBottom: "1px solid grey",
                      paddingBottom: 10,
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        // width: "100%",
                        fontWeight: "bold",
                      }}
                    >
                      Tickets
                    </Typography>
                    <Button
                      variant="contained"
                      startIcon={<Add />}
                      size="small"
                      style={{ borderRadius: 40 }}
                      onClick={() => {
                        setTicketModalOpen(true);
                      }}
                    >
                      Add Ticket
                    </Button>
                  </Grid>

                  <Grid item container xs={12} style={{ padding: 10 }}>
                    {tickets.map((ticket, idx) => {
                      return (
                        <Grid
                          item
                          container
                          xs={12}
                          key={idx}
                          style={{
                            padding: 10,
                            height: "fit-content",
                            marginBottom: 10,
                            border: ticket.hidden
                              ? "1px solid lightgrey"
                              : "1px solid grey",
                            borderRadius: 5,
                          }}
                        >
                          <Grid item container xs={8}>
                            {ticket.hidden && (
                              <VisibilityOff
                                style={{ marginRight: 5, color: "grey" }}
                              />
                            )}
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: "bold",
                                opacity: ticket.hidden ? 0.5 : 1,
                              }}
                            >
                              {ticket.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{ width: "100%" }}
                            >
                              Price: $
                              {Number(ticket.price) === 0
                                ? "FREE"
                                : absorbFees
                                ? Number(ticket.price).toFixed(2) +
                                  " (+ fees/tax)"
                                : Number(ticket.price).toFixed(2) + " (+ tax)"}
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{ width: "100%" }}
                            >
                              Description: {ticket.description}
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{ width: "100%" }}
                            >
                              Quantity: {ticket.remaining}/{ticket.quantity}{" "}
                              (Limit: {ticket.limit})
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={4}
                            justifyContent={"flex-end"}
                          >
                            <IconButton
                              style={{ height: 40, width: 40 }}
                              aria-controls={
                                ticketMenuOpen[idx]
                                  ? "demo-positioned-menu"
                                  : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={
                                ticketMenuOpen[idx] ? "true" : undefined
                              }
                              onClick={(event) =>
                                handleClickTicketMenu(event, idx)
                              }
                            >
                              <MoreVert />
                            </IconButton>

                            <Menu
                              id="demo-positioned-menu"
                              aria-labelledby="demo-positioned-button"
                              anchorEl={anchorEls[idx]}
                              open={ticketMenuOpen[idx] || false}
                              onClose={() => handleCloseTicketMenu(idx)}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleCloseTicketMenu(idx);
                                  handleSelectUpdateTicket(ticket);
                                  setTicketModalOpen(true);
                                }}
                              >
                                <Edit
                                  style={{ marginRight: 5, color: "grey" }}
                                />
                                Edit
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  console.log(idx);
                                  handleHideTicket(idx);
                                  handleCloseTicketMenu(idx);
                                }}
                              >
                                {ticket.hidden ? (
                                  <VisibilityOff
                                    style={{ marginRight: 5, color: "grey" }}
                                  />
                                ) : (
                                  <Visibility
                                    style={{ marginRight: 5, color: "grey" }}
                                  />
                                )}
                                {ticket.hidden ? "Unhide" : "Hide"}
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleDeleteTicket(idx);
                                  handleCloseTicketMenu(idx);
                                }}
                              >
                                <Delete
                                  style={{ marginRight: 5, color: "grey" }}
                                />
                                Remove
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setAssignTicketsModal(true);
                                  setSelectedTicket(ticket);
                                  handleCloseTicketMenu(idx);
                                }}
                              >
                                <Assignment
                                  style={{ marginRight: 5, color: "grey" }}
                                />
                                Assign Tickets
                              </MenuItem>
                              {isAdmin && (
                                <MenuItem
                                  onClick={() => {
                                    setVouchersOpen(true);
                                    setSelectedTicket(ticket);
                                    handleCloseTicketMenu(idx);
                                  }}
                                >
                                  <LocalOffer
                                    style={{ marginRight: 5, color: "grey" }}
                                  />
                                  Vouchers
                                </MenuItem>
                              )}
                            </Menu>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </>
            )}

            {activeTab === 2 && event && (
              <EventSettings
                event={event}
                setEventArtists={setEventArtists}
                eventArtists={eventArtists}
                saveSettings={saveSettings}
                paymentSettings={paymentSettings}
                setPaymentSettings={setPaymentSettings}
              />
            )}
          </Grid>

          {/* Ticket Creation Modal */}
          <Modal open={ticketModalOpen} onClose={clearTicket}>
            {(isArtist || isEventOwner) && (
              <Grid
                item
                container
                xs={12}
                md={6}
                style={{
                  padding: 20,
                  borderRadius: 12,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  background: "white",
                }}
              >
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  {selectedTicket ? "Update Ticket" : "Add New Ticket"}
                </Typography>
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    maxHeight: "80dvh",
                    overflowY: "scroll",
                    padding: 5,
                    overflowX: "hidden",
                  }}
                >
                  <TextField
                    disabled={selectedTicket !== null}
                    required
                    fullWidth
                    label="Ticket Name"
                    placeholder="Ticket Name"
                    value={ticketName}
                    onChange={(e) => setTicketName(e.target.value)}
                    style={{ margin: "10px 0px" }}
                  />

                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    rowsMax={3}
                    label="Ticket Description"
                    placeholder="Ticket Description"
                    value={ticketDescription}
                    onChange={(e) => setTicketDescription(e.target.value)}
                    style={{ marginBottom: 10 }}
                  />

                  <Grid item container xs={12} justifyContent={"space-between"}>
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography variant="caption">Availability</Typography>
                      <Tooltip title="Specify when tickets will be available. Great for 'Early bird' tix!">
                        <FormControlLabel
                          style={{ marginLeft: 5 }}
                          label={
                            <Typography variant="caption">
                              Specify Date(s)
                            </Typography>
                          }
                          labelPlacement="start"
                          control={
                            <Switch
                              small
                              checked={specifyDates}
                              onChange={() => {
                                if (!specifyDates) {
                                  setAvaiableStarting(new Date());
                                } else {
                                  setAvaiableStarting("");
                                }
                                setSpecifyDates(!specifyDates);
                                setAvailableUntil("");
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                        />
                      </Tooltip>
                    </Grid>

                    {specifyDates && (
                      <Grid
                        item
                        container
                        xs={12}
                        style={{ margin: "10px 0px" }}
                      >
                        <Grid item container xs={6} style={{}}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Starting Day"
                              onChange={(e) => {
                                //   console.log(`${e.$d}`);
                                if (
                                  new Date(`${e.$d}`).getTime() >
                                  new Date(date).getTime()
                                ) {
                                  setError(true);
                                  setMessage(
                                    "The Availabilty start date must be before the event starts!"
                                  );
                                  setAvaiableStarting("");
                                } else {
                                  setAvaiableStarting(new Date(`${e.$d}`));
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item container xs={6} justifyContent={"flex-end"}>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            style={{}}
                          >
                            <DatePicker
                              label="Last Day"
                              onChange={(e) => {
                                //   console.log(`${e.$d}`.split(" 00:00:00 "));
                                const splitDate = `${e.$d}`.split(" 00:00:00 ");
                                const newDate =
                                  splitDate[0] + " 23:59:00 " + splitDate[1];
                                if (
                                  //   new Date(newDate).getTime() >
                                  new Date(`${e.$d}`) >
                                  new Date(endDate).getTime()
                                ) {
                                  setError(true);
                                  setMessage(
                                    "The Availabilty end date must be before the event ends!"
                                  );
                                  setAvailableUntil("");
                                } else {
                                  if (
                                    availableStarting !== "" &&
                                    new Date(newDate).getTime() <
                                      new Date(availableStarting).getTime()
                                  ) {
                                    setError(true);
                                    setMessage(
                                      "The Availabilty end date must be after the Availabilty start date!"
                                    );
                                    setAvailableUntil("");
                                  } else {
                                    setAvailableUntil(new Date(newDate));
                                  }
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    )}

                    <TextField
                      required
                      //   fullWidth
                      label="Limit per person"
                      placeholder="Limit per person"
                      value={ticketLimit}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (!isNaN(value) && Number(value) >= 0) {
                          setTicketLimit(Math.round(value));
                        }
                      }}
                      style={{ marginBottom: 10, width: "49%" }}
                    />
                    <TextField
                      required
                      //   fullWidth
                      label="Available Quantity"
                      placeholder="Available Quantity"
                      value={ticketQuantity}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (!isNaN(value) && Number(value) >= 0) {
                          setTicketQuantity(Number(value));
                        }
                      }}
                      style={{ marginBottom: 10, width: "49%" }}
                    />
                  </Grid>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{"$"}</InputAdornment>
                      ),
                    }}
                    required
                    label="Ticket Price"
                    placeholder="Ticket Price"
                    value={ticketPrice}
                    onChange={(e) => {
                      const value = e.target.value;

                      // Regular expression to check if the input is a valid number or a partial decimal (e.g., '12.', '12.3')
                      const regex = /^[0-9]*\.?[0-9]*$/;

                      if (regex.test(value)) {
                        setTicketPrice(value);
                      }
                    }}
                    style={{
                      marginBottom: 10,
                      width: "calc(100% - 100px)",
                    }}
                  />
                  <Button
                    variant="outlined"
                    style={{ width: 90, height: 55, marginLeft: 10 }}
                    onClick={() => {
                      setTicketPrice(0.0);
                      setAbsorbFees(false);
                    }}
                  >
                    FREE
                  </Button>

                  <Grid
                    item
                    xs={12}
                    md={discounted ? 6 : 12}
                    lg={discounted ? 4 : 12}
                    style={{ padding: 10 }}
                  >
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      Discounted
                    </Typography>
                    <Radio
                      checked={discounted === true}
                      onChange={() => setDiscounted(true)}
                      value={true}
                      name="discounted"
                    />
                    <Typography variant="body2" style={{ display: "inline" }}>
                      Yes
                    </Typography>
                    <Radio
                      checked={discounted === false}
                      onChange={() => {
                        setDiscounted(false);
                        setDiscountType("");
                        setDiscountedValue(0);
                      }}
                      value={false}
                      name="discounted"
                    />
                    <Typography variant="body2" style={{ display: "inline" }}>
                      No
                    </Typography>
                  </Grid>

                  {discounted && (
                    <>
                      <Grid item xs={12} md={6} lg={4} style={{ padding: 10 }}>
                        <Typography
                          required
                          variant="body2"
                          style={{ fontWeight: "bold" }}
                        >
                          Discount Type
                        </Typography>
                        <Radio
                          checked={discountType === "fixed"}
                          onChange={() => setDiscountType("fixed")}
                          value="fixed"
                          name="discountType"
                        />
                        <Typography
                          variant="body2"
                          style={{ display: "inline" }}
                        >
                          Fixed
                        </Typography>
                        <Radio
                          checked={discountType === "percentage"}
                          onChange={() => setDiscountType("percentage")}
                          value="percentage"
                          name="discountType"
                        />
                        <Typography
                          variant="body2"
                          style={{ display: "inline" }}
                        >
                          Percentage
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={6} lg={4} style={{ padding: 10 }}>
                        <Typography
                          variant="body2"
                          style={{ fontWeight: "bold" }}
                        >
                          Discount Value
                        </Typography>

                        <TextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {discountType === "percentage" ? "%" : "$"}
                              </InputAdornment>
                            ),
                          }}
                          required
                          fullWidth
                          label="Discount Value"
                          placeholder="Enter discount value"
                          value={discountedValue}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (!isNaN(value) && Number(value) >= 0) {
                              setDiscountedValue(value);
                            }
                          }}
                          style={{ marginBottom: 10 }}
                        />
                      </Grid>
                    </>
                  )}
                  {ticketPrice !== "" && ticketPrice > 0 && (
                    <Grid
                      item
                      container
                      xs={12}
                      justifyContent={"space-between"}
                    >
                      <Typography variant="caption" style={{ color: "grey" }}>
                        <b>Buyer total:</b> $
                        {absorbFees
                          ? Number(ticketPrice).toFixed(2)
                          : (
                              Number(ticketPrice) + getTicketFee(ticketPrice)
                            ).toFixed(2)}{" "}
                        {/* + applicable taxes */}
                      </Typography>
                      <Typography
                        onClick={() => setFeeInfoOpen(true)}
                        variant="caption"
                        color="secondary"
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        How fees work
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ padding: 10, marginBottom: 20 }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={ticketPrice === 0 || ticketPrice === ""}
                            checked={absorbFees}
                            onChange={() => setAbsorbFees(!absorbFees)}
                          />
                        }
                        label="Absorb fees: Ticketing fees are deducted from your ticket revenue."
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item container xs={12} style={{ padding: 10 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddOrUpdateTicket(selectedTicket)}
                      disabled={
                        ticketName === "" ||
                        ticketPrice === "" ||
                        (!!discounted && discountType === "") ||
                        (!!discounted && discountedValue === "") ||
                        ticketLimit === "" ||
                        ticketQuantity === "" ||
                        (specifyDates &&
                          (availableStarting === "" || availableUntil === ""))
                      }
                    >
                      {selectedTicket ? "Update Ticket" : "Add Ticket"}
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setTicketName("");
                        setTicketDescription("");
                        setTicketPrice("");
                        setDiscounted(false);
                        setDiscountType("");
                        setDiscountedValue(0);
                        setTicketLimit("");
                        setTicketQuantity("");
                        setAbsorbFees(false);
                        setSelectedTicket(null);
                        setTicketModalOpen(false);
                      }}
                      disabled={
                        ticketName === "" &&
                        ticketPrice === "" &&
                        !!discounted &&
                        discountType === "" &&
                        !!discounted &&
                        discountedValue === "" &&
                        ticketLimit === "" &&
                        ticketQuantity === ""
                      }
                      style={{ marginLeft: 5 }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Modal>

          {/* Assign Tickets to Artist Modal */}
          <Modal
            open={assignTicketsModal}
            onClose={() => {
              setAssignTicketsModal(false);
            }}
          >
            <Grid
              item
              container
              xs={12}
              md={6}
              style={{
                padding: 20,
                borderRadius: 12,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "white",
              }}
            >
              <Typography
                variant="body1"
                style={{ fontWeight: "bold", width: "100%" }}
              >
                Assign Tickets
              </Typography>
              <Typography variant="body2" style={{ width: "100%" }}>
                Select someone to assign tickets to.
              </Typography>
              <Grid item container xs={12} md={12} style={{ marginTop: 20 }}>
                {eventArtists.map((artist) => {
                  return (
                    <Grid
                      item
                      container
                      xs={12}
                      md={12}
                      key={artist.uid}
                      style={{
                        marginTop: 10,
                        border:
                          selectedArtist && selectedArtist?.uid === artist?.uid
                            ? `2px solid ${defaultTheme.palette.primary.main}`
                            : "1px solid lightgrey",
                        padding: 10,
                        borderRadius: 8,
                        cursor: "pointer",
                      }}
                      onClick={() => setSelectedArtist(artist)}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          width: "100%",
                          borderBottom: "1px solid lightgrey",
                        }}
                      >
                        {artist.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: "bold", width: "100%" }}
                      >
                        Tickets Assigned:{" "}
                        {(!artist.assignedTickets ||
                          artist.assignedTickets.length === 0) &&
                          "0"}
                      </Typography>
                      {artist.assignedTickets?.length > 0 &&
                        artist.assignedTickets.map((assignedTicket, idx) => {
                          return (
                            <Typography
                              variant="caption"
                              key={idx}
                              style={{
                                width: "100%",
                                paddingLeft: 10,
                              }}
                            >
                              <strong>- {assignedTicket.name}</strong> |
                              Quantity left:{" "}
                              {assignedTicket.remaining +
                                "/" +
                                assignedTicket.quantity}
                            </Typography>
                          );
                        })}
                    </Grid>
                  );
                })}
                {selectedArtist && (
                  <Grid item container xs={12} style={{ marginTop: 20 }}>
                    <Grid
                      item
                      container
                      xs={12}
                      md={12}
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        Assign <strong>{selectedTicket?.name}</strong> tickets
                        to <strong>{selectedArtist.name}</strong>
                      </Typography>
                      <Grid
                        item
                        container
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: 10,
                        }}
                      >
                        <IconButton
                          style={{
                            background: defaultTheme.palette.primary.main,
                            color: "white",
                            borderRadius: 4,
                            marginRight: 5,
                          }}
                          onClick={() =>
                            setAmtAssigned((prev) => Math.max(prev - 1, 0))
                          }
                          disabled={amtAssigned <= 0}
                        >
                          <Remove />
                        </IconButton>
                        <TextField
                          inputProps={{
                            type: "number",
                            min: 0,
                            max: selectedTicket?.remaining,
                            className: "no-spinner", // Add this line
                          }}
                          size="small"
                          value={amtAssigned}
                          onChange={(e) => {
                            const value = Math.min(
                              Math.max(Number(e.target.value), 0),
                              selectedTicket?.remaining
                            );
                            setAmtAssigned(value);
                          }}
                          style={{ width: 60, textAlign: "center" }}
                        />
                        <Typography style={{ marginLeft: 5 }}>
                          / {selectedTicket?.remaining}
                        </Typography>
                        <IconButton
                          style={{
                            background: defaultTheme.palette.primary.main,
                            color: "white",
                            borderRadius: 4,
                            marginLeft: 5,
                          }}
                          onClick={() =>
                            setAmtAssigned((prev) =>
                              Math.min(prev + 1, selectedTicket?.remaining)
                            )
                          }
                          disabled={amtAssigned >= selectedTicket?.remaining}
                        >
                          <Add />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  container
                  xs={12}
                  style={{ marginTop: 20, justifyContent: "flex-end" }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setAssignTicketsModal(false);
                      setAmtAssigned(0);
                      setSelectedArtist(null);
                    }}
                    style={{ marginRight: 10 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={assignTicketsToArtist}
                    disabled={!selectedArtist || amtAssigned === 0}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Modal>

          {/* Vouchers: Send FREE Tickets Modal */}
          <Modal open={vouchersOpen} onClose={() => setVouchersOpen(false)}>
            <Grid
              item
              container
              xs={11}
              md={8}
              lg={6}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                borderRadius: 12,
                background: "white",
                padding: 20,
              }}
            >
              <Typography
                variant="body1"
                style={{ fontWeight: "bold", width: "100%" }}
              >
                Vouchers
              </Typography>
              <Typography variant="body2" style={{ width: "100%" }}>
                Enter the email of the person you want to send tickets to.{" "}
                <br />
                <br />
                Note: Tickets can only be sent to individuals who have purchased
                a ticket but did not receive them. These vouchers cannot be
                returned after sending.
              </Typography>
              {/* EMAIL INFO HERE... */}
              {/* EMAIL TEXTFIELD, BUTTONS TO ADD... */}

              <Grid
                item
                container
                xs={12}
                style={{
                  marginTop: 10,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  size="small"
                  style={{ width: "calc(100% - 150px)" }}
                  value={voucherHolderEmail}
                  onChange={(e) => setVoucherHolderEmail(e.target.value)}
                />
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleUserSearch}
                  disabled={
                    voucherHolder ||
                    !voucherHolderEmail ||
                    !voucherHolderEmail.includes("@") ||
                    !voucherHolderEmail.includes(".")
                  }
                >
                  {vouchersSearchLoading ? (
                    <CircularProgress style={{ height: 20, width: 20 }} />
                  ) : (
                    <>
                      <Search style={{ marginRight: 5 }} />
                      Search
                    </>
                  )}
                </Button>
              </Grid>
              {voucherHolder && (
                <Grid item container xs={12} style={{ marginTop: 20 }}>
                  <Grid
                    item
                    container
                    xs={12}
                    md={12}
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      Send <strong>{selectedTicket?.name}</strong> tickets to{" "}
                      <strong>{voucherHolder.name}</strong>
                    </Typography>
                    <Grid
                      item
                      container
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 10,
                      }}
                    >
                      <IconButton
                        style={{
                          background: defaultTheme.palette.primary.main,
                          color: "white",
                          borderRadius: 4,
                          marginRight: 5,
                        }}
                        onClick={() =>
                          setAmtAssigned((prev) => Math.max(prev - 1, 0))
                        }
                        disabled={amtAssigned <= 0}
                      >
                        <Remove />
                      </IconButton>
                      <TextField
                        inputProps={{
                          type: "number",
                          min: 0,
                          max: selectedTicket?.remaining,
                          className: "no-spinner", // Add this line
                        }}
                        size="small"
                        value={amtAssigned}
                        onChange={(e) => {
                          const value = Math.min(
                            Math.max(Number(e.target.value), 0),
                            selectedTicket?.remaining
                          );
                          setAmtAssigned(value);
                        }}
                        style={{ width: 60, textAlign: "center" }}
                      />
                      <Typography style={{ marginLeft: 5 }}>
                        / {selectedTicket?.remaining}
                      </Typography>
                      <IconButton
                        style={{
                          background: defaultTheme.palette.primary.main,
                          color: "white",
                          borderRadius: 4,
                          marginLeft: 5,
                        }}
                        onClick={() =>
                          setAmtAssigned((prev) =>
                            Math.min(prev + 1, selectedTicket?.remaining)
                          )
                        }
                        disabled={amtAssigned >= selectedTicket?.remaining}
                      >
                        <Add />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid
                item
                container
                xs={12}
                style={{ marginTop: 20, justifyContent: "flex-end" }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setAssignTicketsModal(false);
                    setAmtAssigned(0);
                    setSelectedArtist(null);
                  }}
                  style={{ marginRight: 10 }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={sendVouchers}
                  disabled={!voucherHolder || amtAssigned === 0}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Modal>

          {/* Event Import Modal */}
          <Modal
            open={importOpen}
            onClose={() => {
              setImportOpen(false);
              setImportUrl("");
            }}
          >
            <Grid
              item
              container
              xs={10}
              md={5}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: 20,
                background: "white",
                borderRadius: 12,
                justifyContent: "center",
              }}
            >
              <Typography style={{ fontWeight: "bold", marginBottom: 20 }}>
                Import from Ticketmaster or Eventbrite
              </Typography>

              <TextField
                disabled={loadingScraper}
                required
                fullWidth
                placeholder="Enter a URL to import from"
                value={importUrl}
                onChange={(e) => {
                  setImportUrl(e.target.value);
                }}
                style={{ marginBottom: 10 }}
                error={
                  importUrl !== "" &&
                  ((importUrl.includes(".com") &&
                    !importUrl.includes("ticketmaster") &&
                    importUrl.includes(".com") &&
                    !importUrl.includes("eventbrite")) ||
                    !importUrl.includes(".com"))
                    ? true
                    : false
                }
                helperText={
                  importUrl !== "" &&
                  ((importUrl.includes(".com") &&
                    !importUrl.includes("ticketmaster") &&
                    importUrl.includes(".com") &&
                    !importUrl.includes("eventbrite")) ||
                    !importUrl.includes(".com"))
                    ? "Please ensure you are using a ticket master or eventbrite URL."
                    : ""
                }
              />

              <Button
                disabled={
                  loadingScraper ||
                  importUrl === "" ||
                  (importUrl !== "" &&
                    ((importUrl.includes(".com") &&
                      !importUrl.includes("ticketmaster") &&
                      importUrl.includes(".com") &&
                      !importUrl.includes("eventbrite")) ||
                      !importUrl.includes(".com")))
                }
                variant="contained"
                color="primary"
                onClick={handleImportEvent}
              >
                {loadingScraper ? "Loading..." : "Import"}
              </Button>
            </Grid>
          </Modal>

          {/* Add Promoter Modal */}
          <Modal open={addPromoterModalOpen}>
            <Grid
              item
              container
              xs={10}
              md={5}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: 20,
                background: "white",
                borderRadius: 12,
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  marginBottom: 20,
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Is this your event?
              </Typography>
              <Button
                variant={userIsPromoter ? "contained" : "outlined"}
                onClick={() => {
                  setUserIsPromoter(true);
                  setPromoterEmail("");
                }}
              >
                Yes
              </Button>
              <Button
                variant={userIsPromoter === false ? "contained" : "outlined"}
                style={{ marginLeft: 5 }}
                onClick={() => setUserIsPromoter(false)}
              >
                No
              </Button>

              {userIsPromoter === false && (
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Promoter Email
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{
                      marginBottom: 20,
                      width: "100%",
                    }}
                  >
                    <b>Note:</b> Once you have added a promoter, they can not be
                    removed.
                  </Typography>
                  <TextField
                    required
                    fullWidth
                    placeholder="Enter a Promoter Email"
                    value={promoterEmail}
                    onChange={(e) => {
                      setPromoterEmail(e.target.value);
                    }}
                    style={{ marginBottom: 10 }}
                    error={
                      promoterEmail !== "" && !validateEmail(promoterEmail)
                    }
                    helperText={
                      promoterEmail !== "" && !validateEmail(promoterEmail)
                        ? "Please enter a valid email."
                        : ""
                    }
                  />
                  <Button
                    disabled={
                      promoterEmail === "" || !validateEmail(promoterEmail)
                    }
                    variant="contained"
                    color="primary"
                    onClick={handleAddPromoter}
                    style={{ width: "100%" }}
                  >
                    Add Promoter
                  </Button>
                </Grid>
              )}

              <Grid
                item
                container
                xs={12}
                style={{ marginTop: 20, justifyContent: "space-between" }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    setImportedEvent(null);
                    setAddPromoterModalOpen(false);
                    setPromoterEmail("");
                    setUserIsPromoter(false);
                  }}
                >
                  Cancel
                </Button>
                {userIsPromoter === true && (
                  <Button
                    variant="contained"
                    style={{}}
                    onClick={handleAddPromoter}
                  >
                    Continue
                  </Button>
                )}
              </Grid>
            </Grid>
          </Modal>

          {/* FEEs Information Modal */}
          <Modal open={feeInfoOpen} onClose={() => setFeeInfoOpen(false)}>
            <Grid
              item
              container
              xs={10}
              md={5}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "white",
                borderRadius: 12,
                outline: "none",
                padding: 20,
              }}
            >
              <Typography
                variant="h5"
                style={{
                  fontWeight: "bold",
                  marginBottom: 20,
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Fee Information
              </Typography>

              <Grid item container xs={12}>
                <TableContainer
                  style={{ border: "1px solid lightgrey", borderRadius: 12 }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Price Range</TableCell>
                        <TableCell>Fee</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>$0</TableCell>
                        <TableCell>$0.00</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>$0.01 - $9.99</TableCell>
                        <TableCell>$0.99</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>$10.00 - $49.99</TableCell>
                        <TableCell>$1.49</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>$50.00 - $84.99</TableCell>
                        <TableCell>$2.49</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>$85 and up</TableCell>
                        <TableCell>$3.99</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Modal>
        </Grid>
      </>
    </Modal>
  );
};

export default ViewEditEvent;
