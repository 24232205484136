// src/screens/MyTicketsScreen.js
import React, { useContext, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import MyTickets from "../components/MyTickets";
import { AppContext } from "../App";
import { db } from "../config/firebaseConfig";
import AuthScreen from "./AuthScreen";

const MyTicketsScreen = () => {
  const { userData, dispatch, setUserData } = useContext(AppContext);

  useEffect(() => {
    const fetchTickets = async () => {
      console.log("fetching tickets...");
      let userDataCopy = { ...userData };
      await db
        .collection("users")
        .doc(userData.uid)
        .collection("tickets")
        .get()
        .then((querySnapshot) => {
          dispatch(
            setUserData({
              ...userDataCopy,
              tickets: querySnapshot.docs.map((doc) => {
                return doc.data();
              }),
            })
          );
        });
    };
    if (userData && !userData.tickets) {
      fetchTickets();
    }
  }, [userData]);

  useEffect(() => {
    console.log("this is userData => ", userData);
  }, [userData]);

  return (
    <Grid
      item
      container
      xs={12}
      justifyContent={"center"}
      style={
        userData && {
          marginTop: 70,
          height: "100dvh",
          overflowY: "scroll",
          background: "white",
        }
      }
      sx={{ padding: "1rem" }}
    >
      {userData ? <MyTickets /> : <AuthScreen isNotAuthPage={true} />}
    </Grid>
  );
};

export default MyTicketsScreen;
