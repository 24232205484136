import {
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Paper,
  InputBase,
  CircularProgress,
} from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../App";
import firebase, { auth, db } from "../config/firebaseConfig";
import PlacesAutoComplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { EmailAuthProvider } from "firebase/auth";
import { getAuth, linkWithCredential } from "firebase/auth";
import { musicGenres } from "../utils/contants";
import axios from "axios";

const OnboardingModal = ({
  userType,
  invitee,
  onboardingModalOpen,
  setOnboardingModalOpen,
  isOnboarded,
}) => {
  const {
    defaultTheme,
    userData,
    setSuccess,
    setError,
    setMessage,
    navigate,
    dispatch,
    setUserData,
    scrollToTop,
    setCurrentUser,
  } = useContext(AppContext);

  const [UserType, setUserType] = useState(userType);
  const [isArtist, setIsArtist] = useState(userData?.isArtist || "yes");
  const [email, setEmail] = useState(userData?.email || "");
  const [fullName, setFullName] = useState(userData?.name || "");
  const [displayName, setDisplayName] = useState(userData?.displayName || "");
  const [address, setAddress] = useState(userData?.address || "");
  const [worksWithPromoters, setWorksWithPromoters] = useState(
    userData?.worksWithPromoters || "no"
  );
  const [phoneNumber, setPhoneNumber] = useState(userData?.phoneNumber || "");
  const [preferredCommunication, setPreferredCommunication] = useState(
    userData?.preferredCommunication || ""
  );
  const [socialMediaLinks, setSocialMediaLinks] = useState(
    userData?.socialMediaLinks || {
      instagram: "",
      twitter: "",
      facebook: "",
    }
  );
  const [genre, setGenre] = useState(userData?.genre || "");
  const [websiteURL, setWebsiteURL] = useState(userData?.websiteURL || "");
  const [bio, setBio] = useState(userData?.bio || "");
  const [profilePath, setProfilePath] = useState(userData?.profilePath || "");
  const [step, setStep] = useState(1); // Step management
  const [isLoading, setIsLoading] = useState(false);

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);
    const addressComponents = result[0].address_components;

    const zipCode = addressComponents.find((component) =>
      component.types.includes("postal_code")
    )?.long_name;

    setAddress(value + " " + zipCode);
  };

  const handleContinue = async () => {
    //e.preventDefault()
    // console.log(userData.stripeId)
    try {
      const response = await axios.post(
        "https://us-central1-ticketshare-16a46.cloudfunctions.net/app/onboarding",
        {
          id: userData.stripeId,
          url: window.location.origin,
        }
      );
      if (response.data.success) {
        window.location.assign(response.data.accountLinks.url);
      } else {
        console.log("Failure Link retrieval");
      }
    } catch (error) {
      console.log("Stripe Onboarding Error:", error);
    }
  };

  const handleSubmit = async () => {
    if (step === 1) {
      // Handle collecting non-sensitive data (excluding payout details)
      let onboardingData = {
        bio,
        email,
        fullName,
        address,
        worksWithPromoters,
        preferredCommunication,
        socialMediaLinks,
        websiteUrl: websiteURL,
        infoSubmitted: true,
        profilePath: profilePath.replace(" ", "-").toLowerCase().trim(),
      };

      if (userData.roles.includes("artist")) {
        onboardingData.isArtist = isArtist;
        onboardingData.worksWithPromoters = worksWithPromoters;
        onboardingData.genre = genre;
      }

      if (userData.roles.includes("promoter")) {
        onboardingData.name = fullName;
        onboardingData.displayName = displayName;
      }

      // Saving data to Firestore
      await db
        .collection(UserType)
        .doc(userData.uid)
        .set(onboardingData, { merge: true })
        .then(() => {
          setStep(2); // Move to the next step for Stripe Connect
          setSuccess(true);
          setMessage(
            "Account info saved successfully. Next step: Stripe onboarding."
          );
        })
        .catch((error) => {
          setError(true);
          setMessage("Error saving onboarding data, please try again.");
        });
    } else if (step === 2) {
      handleContinue();
    } else {
      navigate("/dashboard");
    }
  };

  const generateStripeId = async () => {
    try {
      //   let userType = userData?.roles.includes("artist")
      //     ? "artists"
      //     : "promoters";
      const response = await axios.post(
        "https://us-central1-ticketshare-16a46.cloudfunctions.net/app/create-stripeid",
        {
          uid: userData.uid,
          name: userData.name,
          email: userData.email,
          profilePath: userData.profilePath,
          userType: UserType,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("response.data.stripeId: ", response);
      if (response) {
        dispatch(
          setUserData({ ...userData, stripeId: response.data.stripeId })
        );
        console.log("userData after creation: ", {
          ...userData,
          stripeId: response.data.stripeId,
        });
      } else {
        console.log("Failure Link retrieval");
      }
    } catch (error) {
      console.log("Stripe Onboarding Error:", error);
      //   db.collection(UserType)
      //     .doc(userData.uid)
      //     .get()
      //     .then((doc) => {
      //       if (doc.exists) {
      //         dispatch(setUserData(doc.data()));
      //       } else {
      //         console.log("No such document!");
      //       }
      //     });
    }
  };

  const handleCompleteOnboarding = async () => {
    // let userType = userData?.roles.includes("artist") ? "artists" : "promoters";
    if (userData.stripeId) {
      await db
        .collection(UserType)
        .doc(userData.uid)
        .update({
          infoSubmitted: true,
          onboarded: true,
          lastUpdated: new Date(),
        })
        .catch((error) => {
          setError(true);
          setMessage("Error saving onboarding data, please try again.");
        });

      //fetch data and setUserData
      await db
        .collection(UserType)
        .doc(userData.uid)
        .get()
        .then((doc) => {
          if (doc.data().onboarded) {
            dispatch(setUserData(doc.data()));
          }
        })
        .catch((error) => {
          setError(true);
          setMessage("Error fetching onboarding data, please try again.");
        });
    }
  };

  useEffect(() => {
    if (userData) {
      if (!UserType) {
        if (userData.guest) {
          navigate("/");
          scrollToTop();
        } else {
          if (userData.roles.includes("artist")) {
            setUserType("artists");
          } else if (userData.roles.includes("promoter")) {
            setUserType("promoters");
          }
        }
      } else {
        if (!userData.stripeId) {
          console.log("generating stripe id");
          //   setIsLoading(true);
          generateStripeId();
        } else {
          //   setIsLoading(false);
          if (isOnboarded) {
            handleCompleteOnboarding();
            setStep(3);
            setOnboardingModalOpen(true);
          } else if (!userData?.onboarded) {
            setStep(2);
            setOnboardingModalOpen(true);
          } else {
            // setOnboardingModalOpen(false);
          }
        }
      }
    }
    console.log("is onboarded", isOnboarded);
  }, [userData, UserType, isLoading]);

  return (
    <Modal open={onboardingModalOpen}>
      <Grid
        item
        container
        xs={11}
        md={8}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "white",
          padding: 20,
          borderRadius: 12,
          justifyContent: "center",
          outline: "none",
        }}
      >
        {step === 1 && (
          <Grid
            item
            container
            xs={12}
            style={{
              maxHeight: "80dvh",
              overflowY: "scroll",
              justifyContent: "center",
            }}
          >
            {/* Step 1: Collect necessary data */}
            <Typography
              variant="h5"
              gutterBottom
              style={{
                width: "100%",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Complete Onboarding
            </Typography>
            <Grid
              item
              container
              xs={11}
              md={10}
              style={{
                display: "flex",
                flexFlow: "column",
                // alignItems: "center",
              }}
            >
              {(!UserType || UserType === "artists") && (
                <FormControl component="fieldset" style={{ marginBottom: 20 }}>
                  <FormLabel component="legend">Are you the artist?</FormLabel>
                  <RadioGroup
                    row
                    value={isArtist}
                    onChange={(e) => setIsArtist(e.target.value)}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              )}

              {(isArtist === "no" || UserType) && (
                <TextField
                  fullWidth
                  disabled={UserType}
                  label={UserType ? "Email" : "Artist Email"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    margin: UserType ? "10px 0px 20px 0px" : "0px 0px 20px 0px",
                  }}
                />
              )}

              {(isArtist === "no" || UserType) && (
                <TextField
                  fullWidth
                  label={
                    UserType ? "Full Name (legal)" : "Artist Full Name (legal)"
                  }
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  style={{ marginBottom: 20 }}
                />
              )}

              {UserType === "promoters" && (
                <TextField
                  fullWidth
                  label={"Display Name"}
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                  style={{ marginBottom: 20 }}
                />
              )}

              <Typography variant="caption" style={{ width: "100%" }}>
                Address
              </Typography>
              <PlacesAutoComplete
                value={address}
                onChange={(value) => setAddress(value)}
                onSelect={handleSelect}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                  <div style={{ width: "inherit" }}>
                    <TextField
                      {...getInputProps({
                        placeholder: "Enter Your Address...",
                      })}
                      fullWidth
                      style={{ marginBottom: 20 }}
                    />
                    <div>
                      {suggestions.map((suggestion) => (
                        <MenuItem
                          {...getSuggestionItemProps(suggestion)}
                          key={suggestion.description}
                        >
                          {suggestion.description}
                        </MenuItem>
                      ))}
                    </div>
                  </div>
                )}
              </PlacesAutoComplete>

              {UserType !== "promoters" && (
                <FormControl component="fieldset" style={{ marginBottom: 20 }}>
                  <FormLabel component="legend">
                    <Typography variant="caption">
                      Would you like to be discovered/booked by promoters via
                      TicketShare?
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    row
                    value={worksWithPromoters}
                    onChange={(e) => setWorksWithPromoters(e.target.value)}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              )}
              <TextField
                select
                fullWidth
                label="Preferred Communication"
                value={preferredCommunication}
                onChange={(e) => setPreferredCommunication(e.target.value)}
                style={{ marginBottom: 20 }}
              >
                {["Mail", "E-mail", "Phone"].map((method) => (
                  <MenuItem value={method}>{method}</MenuItem>
                ))}
              </TextField>

              <Typography variant="caption" style={{ width: "100%" }}>
                Social Media Links (optional)
              </Typography>

              <TextField
                fullWidth
                label="Instagram"
                value={socialMediaLinks.instagram}
                onChange={(e) =>
                  setSocialMediaLinks({
                    ...socialMediaLinks,
                    instagram: e.target.value,
                  })
                }
                style={{ marginBottom: 20 }}
              />

              <TextField
                fullWidth
                label="Twitter"
                value={socialMediaLinks.twitter}
                onChange={(e) =>
                  setSocialMediaLinks({
                    ...socialMediaLinks,
                    twitter: e.target.value,
                  })
                }
                style={{ marginBottom: 20 }}
              />

              <TextField
                fullWidth
                label="Facebook"
                value={socialMediaLinks.facebook}
                onChange={(e) =>
                  setSocialMediaLinks({
                    ...socialMediaLinks,
                    facebook: e.target.value,
                  })
                }
                style={{ marginBottom: 20 }}
              />
              {!UserType && (
                <>
                  <Typography variant="caption" style={{ width: "100%" }}>
                    What's the Artist's Primary Genre?
                  </Typography>

                  <Select
                    fullWidth
                    label="Genre"
                    value={genre}
                    onChange={(e) => setGenre(e.target.value)}
                    style={{ marginBottom: 20 }}
                  >
                    {musicGenres.map((genre) => (
                      <MenuItem value={genre}>{genre}</MenuItem>
                    ))}
                  </Select>

                  <Typography variant="caption" style={{ width: "100%" }}>
                    About the Artist
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={3}
                    label="Something interesting..."
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    style={{ marginBottom: 20 }}
                  />

                  <Typography variant="caption" style={{ width: "100%" }}>
                    Create your Public Profile Url
                  </Typography>
                  <Grid item container direction={"row"}>
                    {/* <Grid
                      item
                      container
                      xs={7}
                      md={4}
                      style={{
                        alignItems: "center",
                        justifyContent: "flex-end",
                        height: 50,
                        //   paddingRight: 10,
                      }}
                    >
                      <Typography>https://ticketshare.app/artists/</Typography>
                    </Grid> */}
                    <Grid item container xs={12}>
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <Typography
                              variant="caption"
                              style={{ marginRight: 5, color: "grey" }}
                            >
                              https://ticketshare.app/artists/
                            </Typography>
                          ),
                        }}
                        fullWidth
                        placeholder={`${userData?.name
                          ?.split(" ")
                          ?.join("")
                          ?.toLowerCase()
                          .trim()}`}
                        value={profilePath}
                        onChange={(e) => setProfilePath(e.target.value)}
                        style={{ marginBottom: 20 }}
                      />
                    </Grid>
                  </Grid>

                  <Typography variant="caption" style={{ width: "100%" }}>
                    Already have a website / domain name?
                  </Typography>
                  <TextField
                    fullWidth
                    label="Website URL"
                    value={websiteURL}
                    onChange={(e) => setWebsiteURL(e.target.value)}
                    style={{ marginBottom: 20 }}
                  />
                </>
              )}

              <Button
                disabled={isLoading}
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
              >
                {isLoading && (
                  <CircularProgress size={20} style={{ marginRight: 10 }} />
                )}
                {!isLoading ? "Next" : "Loading..."}
              </Button>
            </Grid>
          </Grid>
        )}

        {step === 2 && (
          <>
            {/* Step 2: Stripe Connect Onboarding */}
            <Grid
              item
              container
              xs={11}
              md={10}
              style={{
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Stripe Connect Onboarding
              </Typography>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Complete Onboarding
              </Typography>
              <Typography
                style={{
                  color: "gray",
                  padding: 10,
                  border: "1px solid #c0c0c0",
                  borderRadius: "4px",
                  width: "100%",
                }}
              >
                We are introducing Stripe Connect to handle your payments
                securely. With Stripe Connect, you will have access to:
                <ul>
                  <li>Daily payout options</li>
                  <li>An updated dashboard for monitoring your earnings</li>
                  <li>
                    Keeping your sensitive information safe, as all financial
                    data will be securely stored by Stripe
                  </li>
                </ul>
                Please click <strong>“Continue Onboarding”</strong> below to set
                up your Stripe account and enable these new features.
                {isLoading && (
                  <>
                    <br />
                    <br />
                    <i style={{ width: "100%", textAlign: "center" }}>
                      If you have not been redirected after 15 secondes, please
                      refresh this page and try again.
                    </i>
                  </>
                )}
              </Typography>
              <Button
                disabled={isLoading}
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: 20 }}
                onClick={() => {
                  handleSubmit();
                  setIsLoading(true);
                }}
              >
                {isLoading && (
                  <CircularProgress size={20} style={{ marginRight: 10 }} />
                )}
                {!isLoading ? "Continue Onboarding" : "Loading..."}
              </Button>
            </Grid>
          </>
        )}

        {step === 3 && (
          <>
            {/* Step 2: Stripe Connect Onboarding */}
            <Grid
              item
              container
              xs={11}
              md={10}
              style={{
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Onboarding Complete!
              </Typography>

              <Typography
                style={{
                  color: "gray",
                  padding: 10,
                  border: "1px solid #c0c0c0",
                  borderRadius: "4px",
                }}
              >
                Congratulations! You have completed the onboarding process and
                can now publish your first event!
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: 20 }}
                onClick={handleSubmit}
              >
                Close
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Modal>
  );
};

export default OnboardingModal;
