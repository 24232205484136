import React, { useContext, useEffect, useState } from "react";
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    Button,
    TextField,
    IconButton,
    Chip,
    Modal,
  } from "@mui/material";
  import {
    TimerOutlined,
    Search,
    Clear,
    ArrowForward,
    ArrowBack,
    FilterList,
    LocationOnRounded,
  } from "@mui/icons-material";
  import { eventTags, locationsUS } from "../utils/contants"; // Assuming you have a list of possible tags


export const EventSearchBar = ({searchTerm, setSearchTerm, handleClear, handleSearch, handleLocationChange, selectedLocation, }) => {
  return (
    <Grid
        item
        container
        xs={12}
        sx={{
          padding: 2,
          //   marginBottom: "20px",
          justifyContent: { xs: "flex-end", md: "center" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "calc(100% - 200px)" },
            display: "flex",
            marginRight: { xs: 0, md: "10px" },
          }}
        >
          <TextField
            label="Search Events"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: { xs: "70%", md: "60%" } }}
            InputProps={{
              style: { borderRadius: "50px 0px 0px 50px" },
              endAdornment: searchTerm.length > 0 && (
                <IconButton onClick={handleClear} color="primary">
                  <Clear />
                </IconButton>
              ),
            }}
          />
          <TextField
            select
            // label="Location"
            value={selectedLocation}
            onChange={handleLocationChange}
            variant="outlined"
            size="small"
            sx={{ width: { xs: "30%", md: "40%" } }}
            SelectProps={{
              native: true,
            }}
            InputProps={{
              style: { borderRadius: "0px 50px 50px 0px" },
              startAdornment: (
                <LocationOnRounded style={{ color: "lightgrey" }} />
              ),
            }}
          >
            <option value="">All Locations</option>
            {locationsUS.map((location) => (
              <option key={location.name} value={location.value}>
                {location.name}
              </option>
            ))}
          </TextField>
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Search />}
          onClick={handleSearch}
          sx={{
            borderRadius: "50px",
            height: "36px",
            marginTop: { xs: 1, md: 0 },
          }}
        >
          Search
        </Button>
      </Grid>
  )
}
