import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Modal,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import AnalyticCard from "./AnalyticCard";
import OrdersTable from "./OrdersTable";
import MainCard from "./MainCard";
import ManageEvents from "./ManageEvents";
import { AppContext } from "../App";
import { db } from "../config/firebaseConfig";
import OnboardingModal from "./OnboardingModal";
import WeeklyBarChart from "./WeeklyBarChart";
import {
  Filter,
  FilterList,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  MoreVert,
} from "@mui/icons-material";
import Payouts from "./Payouts";
import ManageCustomers from "./ManageCustomers";
import DashboardStripe from "./DashboardStripe";

const Dashboard = ({ tabValue, isOnboarded }) => {
  const {
    userData,
    adminUIDs,
    events,
    upcomingEvents,
    artists,
    setArtists,
    promoters,
    setPromoters,
    defaultTheme,
    getTicketFee,
    isAdmin,
  } = useContext(AppContext);
  const [overviewPage, setOverviewPage] = useState(0);
  const [orders, setOrders] = useState(null);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState(null);
  const [filterBy, setFilterBy] = useState(""); // avail options ["Artist", "Event", "Promoter"]
  const [filteredArtist, setFilteredArtist] = useState(null);
  const [filteredEvent, setFilteredEvent] = useState(null);
  const [filteredPromoter, setFilteredPromoter] = useState(null);
  const [totalTicketsSold, setTotalTicketsSold] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [weeklyRevenue, setWeeklyRevenue] = useState(0);
  const [previousWeeklyRevenue, setPreviousWeeklyRevenue] = useState(0);
  const [weeklyTicketsSold, setWeeklyTicketsSold] = useState(0);
  const [previousWeeklyTicketsSold, setPreviousWeeklyTicketsSold] = useState(0);
  const [weeklyOrders, setWeeklyOrders] = useState(0);
  const [previousWeeklyOrders, setPreviousWeeklyOrders] = useState(0);
  const [chartData, setChartData] = useState({
    seriesData: [],
    categories: [],
  });
  const [loading, setLoading] = useState(true);

  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);
  const [weekIndex, setWeekIndex] = useState(0); // 0 means current week, -1 means last week, etc.

  //! Regarding Payouts
  const [myEvents, setMyEvents] = useState(null)
  const [prevPayout, setPrevPayout] = useState(0);
  const [futurePayout, setFuturePayout] = useState(0);
  const [prevEvents, setPrevEvents] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);

  //! Regarding Customers
  const [customers, setCusomters] = useState([]);

  const filterOptions = adminUIDs.includes(userData?.uid)
    ? ["Artist", "Promoter", "Event"]
    : ["Event"];



    const fetchMyEvents = async () => {
      try {
        // let myEventsSet = new Set();
    
        // // Fetch events where user is an artist
        // const artistQuerySnapshot = await db.collection('events')
        //   .where('artistId', '==', userData.uid)
        //   .get();
    
        // if (!artistQuerySnapshot.empty) {
        //   artistQuerySnapshot.forEach(doc => {
        //     myEventsSet.add(JSON.stringify({ id: doc.id, ...doc.data() }));
        //   });
        // } else {
        //   console.log('No events found where user is an artist.');
        // }
    
        // // Fetch events where user is an owner
        // const ownerQuerySnapshot = await db.collection('events')
        //   .where('eventOwnerId', '==', userData.uid)
        //   .get();
    
        // if (!ownerQuerySnapshot.empty) {
        //   ownerQuerySnapshot.forEach(doc => {
        //     myEventsSet.add(JSON.stringify({ id: doc.id, ...doc.data() }));
        //   });
        // } else {
        //   console.log('No events found where user is an owner.');
        // }
    
        // // Convert back to array, ensuring uniqueness
        // const uniqueEvents = Array.from(myEventsSet).map(event => JSON.parse(event));
    
        // setMyEvents(uniqueEvents);
        let uniqueEvents = events.filter(event => event.artistId === userData.uid || event.artists?.find((artist) => artist.uid === userData.uid) || event.eventOwnerId === userData.uid)
        let uniqueFutureEvents = upcomingEvents.filter(event => event.artistId === userData.uid || event.artists?.find((artist) => artist.uid === userData.uid) || event.eventOwnerId === userData.uid)
        setMyEvents(uniqueEvents)
        setFutureEvents(uniqueFutureEvents)
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

  const handleChange = (e, type) => {
    const { name, value } = e.target;
    switch (type) {
      case "filter":
        setFilterBy(value);
        break;
      case "artist":
        setFilteredArtist(value);
        setFilteredPromoter(null);
        setFilteredEvent(null);
        break;
      case "promoter":
        setFilteredPromoter(value);
        setFilteredArtist(null);
        setFilteredEvent(null);
        break;
      case "event":
        setFilteredEvent(value);
        break;
      default:
        break;
    }
  };

  const handleFilter = () => {
    let filtered = orders.slice();
    if (filteredEvent) {
      filtered = filtered.filter((order) => order.eventId === filteredEvent);
    }

    if (filterBy === "Artist") {
      setFilteredOrders(
        filtered.filter((order) => order.tickets[0].artistId === filteredArtist)
      );
    } else if (filterBy === "Event") {
      setFilteredOrders(filtered);
    } else if (filterBy === "Promoter") {
      setFilteredOrders(
        // TODO: Update the tickets or event to have promoter id info...
        orders.filter((order) => order.tickets[0].artistId === filteredPromoter)
      );
    } else {
      setFilteredOrders(orders);
    }
  };

  const handleClearFilter = () => {
    setFilteredArtist(null);
    setFilteredEvent(null);
    setFilteredPromoter(null);
    setFilteredOrders(orders);
    setFilterBy("");
  };

  const fetchOrders = async () => {
    if (adminUIDs.includes(userData.uid)) {
      let dbOrders = [];

      // Get snapshots for promoters and artists
      const promoterSnapshots = await db.collection("promoters")
      // .collection("users")
      // .where("roles", "array-contains", "promoter")
      .get();
      const artistSnapshots = await db.collection("artists")
      // .collection("users")
      // .where("roles", "array-contains", "artist")
      .get();

      // Define a function to get orders from a specific collection
      const getOrdersFromCollection = async (collection, doc) => {
        const ordersSnapshot = await db
          .collection(collection)
          .doc(doc.id)
          .collection("orders")
          .orderBy("purchaseDate", "desc")
          .get();
        return ordersSnapshot.docs.map((orderDoc) => orderDoc.data());
      };

      // Collect orders for artists
      const artistOrderPromises = artistSnapshots.docs.map((artistDoc) =>
        getOrdersFromCollection("artists", artistDoc)
      );

      // Collect orders for promoters
      const promoterOrderPromises = promoterSnapshots.docs.map((promoterDoc) =>
        getOrdersFromCollection("promoters", promoterDoc)
      );

      // Resolve all order promises
      const resolvedArtistOrders = await Promise.all(artistOrderPromises);
      const resolvedPromoterOrders = await Promise.all(promoterOrderPromises);

      // Merge orders from both artists and promoters
      dbOrders = [
        ...resolvedArtistOrders.flat(),
        ...resolvedPromoterOrders.flat(),
      ];

      // Set state for orders, promoters, and artists
      setOrders(dbOrders);
      setFilteredOrders(dbOrders);
      setPromoters(promoterSnapshots.docs.map((doc) => doc.data()));
      setArtists(artistSnapshots.docs.map((doc) => doc.data()));
      setLoading(false);
    } else {
      // Fetch orders for a specific artist
      if (userData.roles.includes("artist")) {
        const ordersSnapshot = await db
          .collection("artists")
          .doc(userData.uid)
          .collection("orders")
          .orderBy("purchaseDate", "desc")
          .get();
        const data = ordersSnapshot.docs.map((doc) => doc.data());
        setOrders(data);
        setFilteredOrders(data);
        setLoading(false);
      } else if (userData.roles.includes("promoter")) {
        const ordersSnapshot = await db
          .collection("promoters")
          .doc(userData.uid)
          .collection("orders")
          .orderBy("purchaseDate", "desc")
          .get();
        const data = ordersSnapshot.docs.map((doc) => doc.data());
        setOrders(data);
        setFilteredOrders(data);
        setLoading(false);
      }
    }
  };

  const calculateOrderData = (orders) => {
    const totalTicketsSold = orders.reduce(
      (acc, order) => acc + order.tickets.length,
      0
    );
    const totalOrders = orders.length;
    const totalRevenue = orders.reduce((acc, order) => {
      return acc + order.orderTotal;
      //   return acc + order.orderTotal;
    }, 0);
    const totalProfit = orders.reduce((acc, order) => {
      //   let amtOfTix = order.tickets.length;
      let ticketFees = order.tickets.reduce((ticketsAcc, ticket) => {
        return ticketsAcc + ticket.ticketFee;
      }, 0);
      //   console.log("TICKET FEES =>", ticketFees);
      let stripeFee = order.orderTotal * 0.029 + 0.3;
      let totalFees = ticketFees + stripeFee;
      let earnings = order.orderTotal - totalFees;
      return acc + earnings;
      //   return acc + order.orderTotal;
    }, 0);

    const totalStripeFees = orders.reduce((acc, order) => {
      let stripeFee = order.orderTotal * 0.029 + 0.3;
      return acc + stripeFee;
    }, 0);

    const totalPlatformFees = orders.reduce((acc, order) => {
      let ticketFees = order.tickets.reduce((ticketsAcc, ticket) => {
        return ticketsAcc + ticket.ticketFee;
      }, 0);
      return acc + ticketFees;
    }, 0);

    const uniqueUserIds = new Set(orders.map((order) => order.purchaserId));
    const totalUsers = uniqueUserIds.size;

    return {
      totalTicketsSold,
      totalOrders,
      totalRevenue,
      totalUsers,
      totalProfit,
      totalStripeFees,
      totalPlatformFees,
    };
  };

  const calculateEventRenue = () => {
    //deterime which events have ended...
    // look for the next event that is about to end...
    //check the day of the week on the next event to end...
    const completedEvents = myEvents?.filter((event) => event.ended);
    const upcomingEventsToPayout = myEvents?.filter((event) => !event.ended);
    const nextEventToPayout = myEvents?.find(
      (event) =>
        !event.ended &&
        new Date().getTime() < new Date(event.endDate.seconds * 1000).getTime()
    );
    let dayOfWeek = 0;
    let artistId;
    let eventId;
    let eventOrders = [];
    let orderData;

    if (nextEventToPayout) {
      dayOfWeek = new Date(nextEventToPayout.startDate.seconds).getDay();
      artistId = nextEventToPayout.artistId;
      eventId = nextEventToPayout.id;
    }

    if (eventId) {
      eventOrders = orders.filter((order) => order.eventId === eventId);
      //   console.log("event orders =>", eventOrders);
      orderData = calculateOrderData(eventOrders);
    }

    //TODO: payout day cannot be Sat or Sun.. if so, needs to be Mon

    const paidOutRevenue = completedEvents.reduce((acc, event) => {
      if (event.revenue.profit) {
        return acc + event.revenue.profit;
      } else {
        return acc;
      }
    }, 0);

    // console.log(
    //   `"paid out amt => $${paidOutRevenue.toFixed(2)}... nex event => `,
    //   orderData
    // );

    //TODO: Get previous pay out total and next payout total
    setPrevPayout(paidOutRevenue);
    if (orderData) {
      setFuturePayout(orderData.totalProfit);
    }
    setPrevEvents(completedEvents);

    // if (upcomingEventsToPayout.length > 0) {
    //   let updatedEvents = upcomingEventsToPayout.map((event) => {
    //     let updatedEventOrders = orders.filter(
    //       (order) => order.eventId === event.id
    //     );
    //     // console.log(
    //     //   "event ID => ",
    //     //   event.id,
    //     //   "updatedEventOrders length =>",
    //     //   updatedEventOrders.length
    //     // );
    //     let updatedOrderData = calculateOrderData(updatedEventOrders);
    //     if (updatedOrderData) {
    //       event["revenue"] = {
    //         amtOfOrders: updatedEventOrders.length,
    //         platformFees: updatedOrderData.totalPlatformFees,
    //         profit: updatedOrderData.totalProfit,
    //         stripeFees: updatedOrderData.totalStripeFees,
    //         total: updatedOrderData.totalRevenue,
    //       };
    //       return event;
    //     } else {
    //       event["revenue"] = {
    //         amtOfOrders: 0,
    //         platformFees: 0,
    //         profit: 0,
    //         stripeFees: 0,
    //         total: 0,
    //       };
    //       return event;
    //     }
    //   });
    //   setFutureEvents(updatedEvents);
    // } else {
    //   setFutureEvents(upcomingEventsToPayout);
    // }

    // return { paidOutRevenue };
  };

  const getAllCustomers = () => {
    let allCustomers = [];
    orders.forEach((order) => {
      if (
        !allCustomers.find(
          (customer) =>
            customer.uid === order.purchaserId ||
            customer.email === order.purchaserEmail
        )
      ) {
        let newCusotmer = {
          uid: order.purchaserId,
          name: order.purchaserName,
          email: order.purchaserEmail,
          phone: order.purchaserPhone,
          orders: [order],
          totalSpent: order.orderTotal,
        };
        allCustomers.push(newCusotmer);
      } else {
        let customer = allCustomers.find(
          (customer) =>
            customer.uid === order.purchaserId ||
            customer.email === order.purchaserEmail
        );
        customer.orders.push(order);
        customer.totalSpent += order.orderTotal;
      }
    });
    // console.log("CUSTOMERS => ", allCustomers);
    setCusomters(allCustomers);
  };

  const processChartData = (orders, weekIndex) => {
    const now = new Date();
    const startOfWeek = new Date(
      now.setDate(now.getDate() - now.getDay() + 7 * weekIndex)
    );
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    const previousWeekStart = new Date(startOfWeek);
    previousWeekStart.setDate(previousWeekStart.getDate() - 7);
    const previousWeekEnd = new Date(endOfWeek);
    previousWeekEnd.setDate(previousWeekEnd.getDate() - 7);

    const categories = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    const seriesData = [0, 0, 0, 0, 0, 0, 0];
    let weeklyTotalRevenue = 0;
    let previousWeeklyTotalRevenue = 0;
    let weeklyOrders = 0;
    let previousWeeklyOrders = 0;
    let weeklyTicketsSold = 0;
    let previousWeeklyTicketsSold = 0;

    orders.forEach((order) => {
      const purchaseDate = new Date(order.purchaseDate.toDate());
      if (purchaseDate >= startOfWeek && purchaseDate <= endOfWeek) {
        const day = purchaseDate.getDay(); // 0 (Sunday) to 6 (Saturday)
        seriesData[day] += order.orderTotal;
        weeklyTotalRevenue += order.orderTotal;
        weeklyOrders += 1;
        weeklyTicketsSold += order.tickets.length;
      } else if (
        purchaseDate >= previousWeekStart &&
        purchaseDate <= previousWeekEnd
      ) {
        previousWeeklyTotalRevenue += order.orderTotal;
        previousWeeklyOrders += 1;
        previousWeeklyTicketsSold += order.tickets.length;
      }
    });

    setWeeklyRevenue(weeklyTotalRevenue);
    setPreviousWeeklyRevenue(previousWeeklyTotalRevenue);
    setWeeklyOrders(weeklyOrders);
    setPreviousWeeklyOrders(previousWeeklyOrders);
    setWeeklyTicketsSold(weeklyTicketsSold);
    setPreviousWeeklyTicketsSold(previousWeeklyTicketsSold);

    return { seriesData, categories };
  };

  useEffect(() => {
    if(userData && events.length > 0 && !myEvents){
      fetchMyEvents()
    }
    console.log('Here are all MY events => ', myEvents)
  }, [userData, events, myEvents]);
  

  useEffect(() => {
    if (userData && (!userData.onboarded || !userData.stripeId)) {
      console.log("opening modal!");
      setOnboardingModalOpen(true);
    }

    if(userData && events.length > 0 && !myEvents){
      fetchMyEvents()
    }

    if (myEvents && userData && !orders) {
      fetchOrders();
    }
    // if (orders) {
    if (filteredOrders) {
      const {
        totalTicketsSold,
        totalOrders,
        totalRevenue,
        totalUsers,
        totalProfit,
      } = calculateOrderData(filteredOrders.filter((x) => !x.refunded));
      setTotalTicketsSold(totalTicketsSold);
      setTotalOrders(totalOrders);
      setTotalRevenue(totalRevenue);
      setTotalProfit(totalProfit);
      setTotalUsers(totalUsers);
      calculateEventRenue();
      getAllCustomers();

      const chartData = processChartData(filteredOrders, weekIndex);
      setChartData(chartData);
    }
  }, [orders, filteredOrders, userData, weekIndex, events, myEvents]);

  

  const calculatePercentageChange = (current, previous) => {
    if (previous === 0 && current === 0) return 0;
    if(previous === 0 && current !== 0) return 100;
    if(current - previous === 0) return 0;
    return ((current - previous) / previous) * 100;
  };

  const weeklyPercentageChange = calculatePercentageChange(
    weeklyRevenue,
    previousWeeklyRevenue
  );
  const weeklyExtraRevenue = weeklyRevenue - previousWeeklyRevenue;
  const weeklyPercentageChangeOrders = calculatePercentageChange(
    weeklyOrders,
    previousWeeklyOrders
  );
  const weeklyExtraOrders = weeklyOrders - previousWeeklyOrders;
  const weeklyPercentageChangeTicketsSold = calculatePercentageChange(
    weeklyTicketsSold,
    previousWeeklyTicketsSold
  );
  const weeklyExtraTicketsSold = weeklyTicketsSold - previousWeeklyTicketsSold;

  const handlePreviousWeek = () => {
    setWeekIndex((prev) => prev - 1);
  };

  const handleNextWeek = () => {
    setWeekIndex((prev) => (prev === 0 ? 0 : prev + 1)); // Prevent going beyond current week
  };

  const tabs = ["Overview", "Events", "Customers", "Settings"];

  return (
    <Box
      sx={{
        width: { xs: "100%" },
        paddingLeft: { xs: "0px", md: "60px" },
        paddingTop: "10px",
        background: "#e6e6e6",
      }}
    >
      <Grid item container xs={12} style={{ padding: 10, }}>
        <Grid item container xs={12} style={{ padding: 10, background:'white', borderRadius:10 }}>
          <Grid
            item
            container
            xs={12}
            style={{ display: "flex", marginBottom: 10, alignItems: "center" }}
          >
            <Typography variant={"h4"} sx={{ mr: 1 }}>
              Dashboard
            </Typography>
            <Typography variant={"h5"} sx={{}}>
              {` / ${tabs[tabValue]}`}
            </Typography>
          </Grid>

          {tabValue === 0 && (
            <>
              <Grid item container xs={12}>
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={8} md={6}>
                    <Typography variant="h5" sx={{ pl: 2, textAlign: "left" }}>
                      {overviewPage === 0 && "Sales Analytics"}
                      {overviewPage === 1 && "Payouts"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    md={6}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <IconButton
                      sx={{ display: { xs: "block", md: "none" } }}
                      onClick={() => {}}
                    >
                      <MoreVert />
                    </IconButton>

                    <Grid
                      item
                      container
                      xs={12}
                      sx={{
                        display: { xs: "none", md: "flex" },
                        marginBottom: "10px",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant={overviewPage === 0 ? "contained" : "outlined"}
                        style={{ marginRight: 5 }}
                        onClick={() => setOverviewPage(0)}
                      >
                        Sales
                      </Button>
                      <Button
                        style={{ marginRight: 5 }}
                        variant={overviewPage === 1 ? "contained" : "outlined"}
                        onClick={() => {
                          setOverviewPage(1);
                        }}
                      >
                        Payouts
                      </Button>
                      <IconButton onClick={() => setFilterModalOpen(true)}>
                        <FilterList />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Map over dashboard items here */}

                <></>
                {/* ============================== MOBILE VIEW ========================================== */}
                {/* ===================================================================================== */}

                {overviewPage === 0 && (
                  <>
                    <Grid
                      item
                      container
                      xs={12}
                      md={5}
                      style={{}}
                      sx={{ display: { xs: "block", md: "none" } }}
                    >
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Typography variant="h5" style={{}}>
                            Income Overview
                          </Typography>
                        </Grid>
                        <Grid item />
                      </Grid>
                      <MainCard
                        sx={{ width: "100%", height: "55dvh" }}
                        content={false}
                      >
                        <Box sx={{ pb: 0 }}>
                          <Stack spacing={2} style={{}}>
                            <Typography
                              variant="h6"
                              color="text.secondary"
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                width: "100%",
                              }}
                            >
                              {weekIndex === 0
                                ? "This Week"
                                : weekIndex === -1
                                ? "Last Week"
                                : weekIndex * -1 + " Weeks Prior"}{" "}
                              Statistics{" "}
                              {weekIndex !== 0 && (
                                <span
                                  onClick={() => setWeekIndex(0)}
                                  style={{
                                    fontSize: 12,
                                    padding: 5,
                                    background: "lightgrey",
                                    borderRadius: 5,
                                    marginLeft: 5,
                                    cursor: "pointer",
                                  }}
                                >
                                  TODAY
                                </span>
                              )}
                            </Typography>
                            <Grid
                              item
                              container
                              xs={12}
                              justifyContent={"space-around"}
                            >
                              <IconButton onClick={handlePreviousWeek}>
                                <KeyboardArrowLeft />
                              </IconButton>
                              <Typography
                                variant="h4"
                                style={{ margin: "0px 10px" }}
                              >
                                ${weeklyRevenue.toFixed(2)}
                              </Typography>
                              <IconButton
                                disabled={weekIndex === 1}
                                onClick={handleNextWeek}
                              >
                                <KeyboardArrowRight />
                              </IconButton>
                            </Grid>
                          </Stack>
                        </Box>
                        <WeeklyBarChart
                          seriesData={chartData.seriesData}
                          categories={chartData.categories}
                        />
                      </MainCard>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={12}
                      sx={{ display: { xs: "block", md: "none" } }}
                    >
                      <Accordion style={{ width: "100%" }}>
                        <AccordionSummary
                          expandIcon={<KeyboardArrowDown />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="h5">Weekly Insights</Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid item container xs={12}>
                            <Grid item xs={12} md={3} style={{ padding: 2 }}>
                              <AnalyticCard
                                title="Total Users"
                                count={totalUsers.toString()}
                                percentage={0}
                                extra={0}
                              />
                            </Grid>

                            <Grid item xs={12} md={3} style={{ padding: 2 }}>
                              <AnalyticCard
                                title="Total Tickets Sold"
                                count={totalTicketsSold.toString()}
                                percentage={weeklyPercentageChangeTicketsSold.toFixed(
                                  2
                                )}
                                isLoss={weeklyPercentageChangeTicketsSold
                                  .toFixed(2)
                                  .includes("-")}
                                extra={weeklyExtraTicketsSold.toString()}
                              />
                            </Grid>

                            <Grid item xs={12} md={3} style={{ padding: 2 }}>
                              <AnalyticCard
                                title="Total Orders"
                                count={totalOrders.toString()}
                                percentage={weeklyPercentageChangeOrders.toFixed(
                                  2
                                )}
                                isLoss={weeklyPercentageChangeOrders
                                  .toFixed(2)
                                  .includes("-")}
                                extra={weeklyExtraOrders.toString()}
                              />
                            </Grid>

                            <Grid item xs={12} md={3} style={{ padding: 2 }}>
                              <AnalyticCard
                                title="Total Revenue"
                                count={`$${totalRevenue.toFixed(2)}`}
                                profit={totalProfit.toFixed(2)}
                                percentage={weeklyPercentageChange.toFixed(2)}
                                isLoss={weeklyPercentageChange
                                  .toFixed(2)
                                  .includes("-")}
                                extra={weeklyExtraRevenue.toFixed(2)}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    {orders && (
                      <Grid
                        item
                        container
                        xs={12}
                        sx={{ display: { xs: "block", md: "none" } }}
                      >
                        <Grid
                          item
                          container
                          xs={12}
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography variant="h5" style={{}}>
                              Recent Orders
                            </Typography>
                          </Grid>
                          <Grid item />
                        </Grid>
                        <Grid item container xs={12}>
                          <MainCard
                            sx={{ width: "100%", height: "75dvh" }}
                            content={false}
                          >
                            <OrdersTable orders={filteredOrders} />
                          </MainCard>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}

                {/* ================================= WEB VIEW ========================================== */}
                {/* ===================================================================================== */}

                
                {overviewPage === 0 && (
                  <>
                    <Grid
                      item
                      container
                      xs={12}
                      sx={{ display: { xs: "none", md: "flex" }, padding: "5px" }}
                    >
                      {/* <Grid item xs={12} md={3} style={{ padding: 5 }}>
                        <AnalyticCard
                          title="Total Users"
                          count={totalUsers.toString()}
                          percentage={0}
                          extra={0}
                        />
                      </Grid> */}

                      <Grid item xs={12} md={3} style={{ padding: 5 }}>
                        <AnalyticCard
                          title="Total Tickets Sold"
                          count={totalTicketsSold.toString()}
                          percentage={weeklyPercentageChangeTicketsSold.toFixed(
                            2
                          )}
                          isLoss={weeklyPercentageChangeTicketsSold
                            .toFixed(2)
                            .includes("-")}
                          extra={weeklyExtraTicketsSold.toString()}
                        />
                      </Grid>

                      <Grid item xs={12} md={3} style={{ padding: 5 }}>
                        <AnalyticCard
                          title="Revenue Generated"
                          count={`${totalRevenue.toFixed(2)}`}
                          profit={totalProfit.toFixed(2)}
                          percentage={weeklyPercentageChange.toFixed(2)}
                          isLoss={weeklyPercentageChange.toFixed(2).includes("-")}
                          extra={weeklyExtraRevenue.toFixed(2)}
                        />
                      </Grid>

                      <Grid item xs={12} md={3} style={{ padding: 5 }}>
                        <AnalyticCard
                          title="Upcoming Events"
                          count={futureEvents?.length || 0}
                          percentage={weeklyPercentageChangeOrders.toFixed(2)}
                          isLoss={weeklyPercentageChangeOrders
                            .toFixed(2)
                            .includes("-")}
                          extra={weeklyExtraOrders.toString()}
                        />
                      </Grid>

                      <Grid item xs={12} md={3} style={{ padding: 5 }}>
                        <AnalyticCard
                          title="Check-ins Completed"
                          count={totalOrders.toString()}
                          percentage={weeklyPercentageChangeOrders.toFixed(2)}
                          isLoss={weeklyPercentageChangeOrders
                            .toFixed(2)
                            .includes("-")}
                          extra={weeklyExtraOrders.toString()}
                        />
                      </Grid>

                      
                    </Grid>
                  </>
                )}
              </Grid>

              {/* row 3 */}
              {overviewPage === 0 && (
                //   <DashboardStripe />
                <>
                  {orders && (
                    <>
                      <Grid
                        item
                        container
                        xs={12}
                        md={5}
                        style={{ padding: 10 }}
                        sx={{ display: { xs: "none", md: "block" } }}
                      >
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography variant="h5" style={{}}>
                              Income Overview
                            </Typography>
                          </Grid>
                          <Grid item />
                        </Grid>
                        <MainCard
                          sx={{ width: "100%", height: "55dvh" }}
                          content={false}
                        >
                          <Box sx={{ pb: 0 }}>
                            <Stack spacing={2} style={{}}>
                              <Typography
                                variant="h6"
                                color="text.secondary"
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                  width: "100%",
                                }}
                              >
                                {weekIndex === 0
                                  ? "This Week"
                                  : weekIndex === -1
                                  ? "Last Week"
                                  : weekIndex * -1 + " Weeks Prior"}{" "}
                                Statistics{" "}
                                {weekIndex !== 0 && (
                                  <span
                                    onClick={() => setWeekIndex(0)}
                                    style={{
                                      fontSize: 12,
                                      padding: 5,
                                      background: "lightgrey",
                                      borderRadius: 5,
                                      marginLeft: 5,
                                      cursor: "pointer",
                                    }}
                                  >
                                    TODAY
                                  </span>
                                )}
                              </Typography>
                              <Grid
                                item
                                container
                                xs={12}
                                justifyContent={"space-around"}
                              >
                                <IconButton onClick={handlePreviousWeek}>
                                  <KeyboardArrowLeft />
                                </IconButton>
                                <Typography
                                  variant="h4"
                                  style={{ margin: "0px 10px" }}
                                >
                                  ${weeklyRevenue.toFixed(2)}
                                </Typography>
                                <IconButton
                                  disabled={weekIndex === 1}
                                  onClick={handleNextWeek}
                                >
                                  <KeyboardArrowRight />
                                </IconButton>
                              </Grid>
                            </Stack>
                          </Box>
                          <WeeklyBarChart
                            seriesData={chartData.seriesData}
                            categories={chartData.categories}
                          />
                        </MainCard>
                      </Grid>

                      <Grid
                        item
                        container
                        xs={12}
                        md={7}
                        sx={{
                          padding: "10px",
                          display: { xs: "none", md: "block" },
                        }}
                      >
                        <Grid
                          item
                          container
                          xs={12}
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography variant="h5" style={{}}>
                              Recent Orders
                            </Typography>
                          </Grid>
                          <Grid item />
                        </Grid>
                        <Grid item container xs={12}>
                          <MainCard
                            sx={{ width: "100%", height: "55dvh" }}
                            content={false}
                          >
                            <OrdersTable orders={filteredOrders} />
                          </MainCard>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}

              {overviewPage === 1 && (
                <Payouts
                  totalRevenue={totalRevenue}
                  totalProfit={totalProfit}
                  prevPayout={prevPayout}
                  futurePayout={futurePayout}
                  prevEvents={prevEvents}
                  futureEvents={futureEvents}
                />
              )}

              {!userData?.roles?.includes("admin") && (
                <OnboardingModal
                  userData={userData}
                  onboardingModalOpen={onboardingModalOpen}
                  setOnboardingModalOpen={setOnboardingModalOpen}
                  isOnboarded={isOnboarded}
                />
              )}
            </>
          )}

          {tabValue === 1 && (
            <ManageEvents
              isArtist={userData?.roles?.includes("artist")}
              orders={orders}
              myEvents={myEvents}
              setMyEvents={setMyEvents
              }
            />
          )}

          {tabValue === 2 && (
            <ManageCustomers
              isAdmin={userData?.roles?.includes("admin")}
              customers={customers}
            />
          )}
        </Grid>

      </Grid>
                
                

      <Modal open={filterModalOpen} onClose={() => setFilterModalOpen(false)}>
        <Grid
          item
          container
          xs={11}
          md={8}
          lg={6}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            outline: "none",
            background: "white",
            borderRadius: 12,
            padding: 20,
          }}
        >
          <Typography
            variant="h4"
            style={{ width: "100%", textAlign: "center", marginBottom: 20 }}
          >
            Filter
          </Typography>
          <Grid item container xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-label">Filter By:</InputLabel>
              <Select
                fullWidth
                labelId="select-label"
                label="Filter by:"
                name="filterBy"
                value={filterBy}
                onChange={(e) => handleChange(e, "filter")}
                style={{ marginBottom: 20 }}
              >
                {filterOptions.map((item) => {
                  return (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {filterBy !== "" && (
              <FormControl fullWidth>
                <InputLabel id="filtered-select-label">
                  Select {filterBy}:
                </InputLabel>
                <Select
                  fullWidth
                  labelId="filtered-select-label"
                  label={`Select ${filterBy}:`}
                  name={filterBy.toLowerCase()}
                  value={
                    filterBy === "Artist"
                      ? filteredArtist
                      : filterBy === "Promoter"
                      ? filteredPromoter
                      : filteredEvent
                  }
                  onChange={(e) => handleChange(e, filterBy.toLowerCase())}
                  style={{ marginBottom: 20 }}
                >
                  {filterBy === "Artist" &&
                    artists.map((artist) => {
                      return (
                        <MenuItem value={artist.uid} key={artist.uid}>
                          {artist.name}
                        </MenuItem>
                      );
                    })}
                  {filterBy === "Promoter" &&
                    promoters.map((promoter) => {
                      return (
                        <MenuItem value={promoter.uid} key={promoter.uid}>
                          {promoter.name}
                        </MenuItem>
                      );
                    })}
                  {(filterBy === "Event" &&
                    myEvents) && myEvents.map((event) => {
                      return (
                        <MenuItem value={event.id} key={event.id}>
                          {event.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            )}

            {filterBy === "Artist" && filteredArtist && (
              <FormControl fullWidth>
                <InputLabel id="eventFilter-select-label">
                  Filter by Event?
                </InputLabel>
                <Select
                  fullWidth
                  labelId="eventFilter-select-label"
                  label={`Filter by Event:`}
                  name="filterByEvent"
                  value={filteredEvent}
                  onChange={(e) => handleChange(e, "event")}
                  style={{ marginBottom: 20 }}
                >
                  {myEvents && myEvents
                    .filter(
                      (e) =>
                        e.artistId === filteredArtist ||
                        e.artistIds?.includes(filteredArtist)
                    )
                    .map((event) => {
                      return (
                        <MenuItem value={event.id} key={event.id}>
                          {event.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            )}
            <Grid
              item
              container
              xs={12}
              style={{ justifyContent: "space-evenly" }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClearFilter}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleFilter}
                style={{ marginRight: 10 }}
              >
                Filter
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </Box>
  );
};

export default Dashboard;
