// src/screens/AuthScreen.js
import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { motion } from "framer-motion";
import Login from "../components/Login";
import SignUp from "../components/SignUp";
import performer from "../assets/performer.jpeg";

const AuthScreen = ({ isNotAuthPage, isArtist }) => {
  const [activeTab, setActiveTab] = useState(isArtist ? 1 : 0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${performer})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "black",
        backgroundBlendMode: "luminosity",
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        style={{
          width: "100%",
          maxWidth: 400,
          padding: 30,
          background: "white",
          display: "flex",
          flexFlow: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 8,
          boxShadow: "0px 0px 10px grey",
        }}
      >
        <Tabs value={activeTab} onChange={handleTabChange} centered>
          <Tab label="Login" />
          <Tab label="Sign Up" />
        </Tabs>
        {activeTab === 0 && (
          <Login isNotAuthPage={isNotAuthPage} isArtist={isArtist} />
        )}
        {activeTab === 1 && <SignUp isArtist={isArtist} />}
      </motion.div>
    </Box>
  );
};

export default AuthScreen;
