// src/App.js
import React, { useState, createContext, useRef, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import SplashScreen from "./screens/SpashScreen";
import AuthScreen from "./screens/AuthScreen";
import NewHomeScreen from "./screens/NewHomeScreen";
import HomeScreen from "./screens/HomeScreen";
import ShowsScreen from "./screens/ShowsScreen";
import EventScreen from "./screens/EventScreen";
import ProfileScreen from "./screens/ProfileScreen";
import SettingsScreen from "./screens/SettingsScreen";
import MyTicketsScreen from "./screens/MyTicketsScreen";
import DashboardScreen from "./screens/DashboardScreen";
import AdminScreen from "./screens/AdminScreen";
import NotificationScreen from "./screens/NotificationScreen";
import SupportScreen from "./screens/SupportScreen";
import { Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MenuBar from "./components/MenuBar";
import { theme as defaultTheme } from "./Theme";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "./components/Footer";
import { setUserData, setCurrentUser } from "./globalState/authActions";
import firebase, { db, auth } from "./config/firebaseConfig";
import BottomNavBar from "./components/BottomNavBar";
import Toast from "./components/Toast";
import CheckInTicket from "./components/CheckInTicket";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsOfService from "./screens/TermsOfService";
import FAQs from "./screens/FAQs";
import InvitePromoter from "./components/InvitePromoter";
import { getTicketFee } from "./utils/contants";
import { testEvents } from "./utils/testData";
import TestScreen from "./screens/TestScreen";
import ArtistProfileScreen from "./screens/ArtistProfileScreen";

const adminUIDs = [process.env.REACT_APP_PLATFORM_ADMIN_ID];

auth
  .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .then(() => {
    // Existing and future Auth states will now persist locally across tabs and windows.
    // If a user opens the app in a new tab or window, they will be signed in automatically.
  })
  .catch((error) => {
    // Handle errors here
    console.error("Error setting auth persistence: ", error);
  });

export const AppContext = createContext({});

const App = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userData = useSelector((state) => state.auth.userData);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [info, setInfo] = useState(false);
  const [message, setMessage] = useState("");
  const [viewMenu, setViewMenu] = useState(false);
  const [deviceType, setDeviceType] = useState(null);

  //events
  const [events, setEvents] = useState(null);
  const [upcomingEvents, setUpcomingEvents] = useState(null);
  const [featuredEvents, setFeaturedEvents] = useState(null)
  const [personalizedEvents, setPersonalizedEvents] = useState(null)

  const [cart, setCart] = useState([]);
  const [artistID, setArtistID] = useState(null);
  const [artists, setArtists] = useState([]);
  const [promoters, setPromoters] = useState([]);

  //filtering events
  const [tagsFilters, setTagsFilters] = useState([]);
  

  const path = window.location.pathname;
  //   console.log("PATH => ", path);

  const homeRef = useRef(null);
  const showsRef = useRef(null);
  const contactRef = useRef(null);
  const pageContainerRef = useRef(null); // Create a ref

  const scrollToTop = () => {
    if (pageContainerRef.current) {
      pageContainerRef.current.scrollTo(0, 0); // Use the ref to scroll to top
    }
  };

  const values = {
    adminUIDs,
    scrollToTop,
    viewMenu,
    setViewMenu,
    defaultTheme,
    events,
    setEvents,
    upcomingEvents,
    setUpcomingEvents,
    featuredEvents,
    personalizedEvents,
    tagsFilters,
    setTagsFilters,
    userData,
    setCurrentUser,
    currentUser,
    setUserData,
    dispatch,
    useSelector,
    setLoading,
    success,
    setSuccess,
    info,
    error,
    message,
    setError,
    setInfo,
    setMessage,
    navigate,
    cart,
    setCart,
    artistID,
    deviceType,
    artists,
    setArtists,
    promoters,
    setPromoters,
    getTicketFee,
  };

  const fetchEvents = async () => {
    try {
      const eventsQuery = await db
        .collection("events")
        .limit(1000) //! Update this as needed!
        .orderBy("startDate", 'desc')
        .get();
      const eventsData = await eventsQuery.docs.map((doc) => doc.data());
      console.log("EVENTS DATA", eventsData);
      setEvents(eventsData);
      //   }
    } catch (error) {
      console.error("Error fetching events: ", error);
    } finally {
      setTimeout(() => setLoading(false), 1500);
    }
  };

  const fetchArtists = async () => {
    try {
      const artistsQuery = await db
        .collection("artists")
        // .collection("users")
        // .where("roles", "array-contains", "artist")
        .limit(500)
        .orderBy("name")
        .get();
      const artistsData = await artistsQuery.docs.map((doc) => doc.data());
      console.log("ARTISTS DATA", artistsData);
      setArtists(artistsData);
      //   }
    } catch (error) {
      console.error("Error fetching events: ", error);
    } finally {
      setTimeout(() => setLoading(false), 1500);
    }
  };

  useEffect(() => {
    fetchEvents();
    fetchArtists();

    if (!deviceType) {
      setDeviceType(window.innerWidth < 768 ? "mobile" : "desktop");
    }
    console.log("currently viewing as =>", deviceType);
  }, [deviceType]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log("USER ", user);
        try {
            const userDoc = await db
              .collection("users")
              .doc(user.uid)
              .get();
            if (userDoc.exists) {
              const userdata = userDoc.data();
              console.log("USER EXISTS =>", userData, userdata);

              dispatch(setUserData(userdata));
              dispatch(setCurrentUser(user));
            } else {
              const userdata = {
                uid: user.uid,
                name: "GUEST",
                roles: ["user"],
                guest: true,
              };
              console.log("USER DOES NOT EXIST =>", userdata);
              dispatch(setUserData(userdata));
              dispatch(setCurrentUser(user));
              }
  
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      } else {
        await auth.signInAnonymously().then(async (userCredential) => {
          const user = userCredential.user;
          dispatch(setCurrentUser(user));
          dispatch(
            setUserData({
              uid: user.uid,
              name: "GUEST",
              roles: ["user"],
              guest: true,
            })
          );
        });
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    console.log("DEBUG USERDATA", userData);
    if (userData && events) {
      if (events.length > 0 && !upcomingEvents) {
        let upcoming = events.filter(
          (e) =>
            new Date(e.startDate.seconds * 1000).getTime() > new Date().getTime()
        );
        let visible;
        visible = upcoming;
        
        if (adminUIDs.includes(userData.uid)) {
          // visible = upcoming;
        } else {
          visible = upcoming.filter(
            (x) =>
              (x.draft === false && !x.test && !x.isHidden) ||
              x.artistId === userData.uid
          );
        }
        
        setUpcomingEvents(visible);

        //TODO: Create logic for these two sections
        setFeaturedEvents(visible);
        setPersonalizedEvents(visible);
      }

      // const isAdmin = userData?.roles?.includes("admin");
      // if (!isAdmin) {
      //   if (userData?.roles?.includes("artist")) {
      //     if (events && events.length > 0) {
      //       setEvents(
      //         events.filter(
      //           (event) =>
      //             event.artistId === userData.uid ||
      //             event?.artists?.find((x) => x.uid === userData.uid)
      //         )
      //       );
      //     }
      //   } else if (userData?.roles?.includes("promoter")) {
      //     if (events?.length > 0) {
      //       let promoterEvents = events.filter(
      //         (event) =>
      //           event.promoter.email === userData.email ||
      //           event.artistId === userData.uid
      //       );
      //       setEvents(promoterEvents);
      //     }
      //   }
      // }

    }
  }, [userData, events, upcomingEvents]);

  return (
    <AppContext.Provider value={values}>
      <ThemeProvider theme={createTheme(defaultTheme)}>
        <div
          className="App"
          style={{
            height: "100dvh",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            overflowX:'hidden',
          }}
        >
          <Toast />
          {loading ? (
            <SplashScreen />
          ) : (
            <Grid
              ref={pageContainerRef}
              item
              container
              xs={12}
              justifyContent={"center"}
              style={{
                background: "white",
                // background: `linear-gradient(180deg, #ffffff 60%, #00000050 100%)`,
                maxHeight: "100dvh",
                overflowY: "scroll",
              }}
            >
              {path === "/" && (
                <img
                  alt=""
                  src={""}
                  style={{
                    position: "absolute",
                    position: "absolute",
                    top: "0%",
                    left: "50%",
                    transform: "translate(-50%, 0%)",
                    height: "100%",
                    width: "auto",
                    objectFit: "cover",
                    objectPosition: "top center",
                    zIndex: 0,
                    opacity: 0.25,
                  }}
                />
              )}
              <Grid
                item
                container
                xs={12}
                style={{
                  position: "sticky",
                  top: "0%",
                  left: "0%",
                  //   transform: "translate(-50%, -50%)",
                  background: "white",
                  // boxShadow: "2px 2px 6px #00000050",
                  overflow: "hidden",
                  zIndex: 100,
                }}
              >
                <MenuBar navigate={navigate} />
              </Grid>

              <Grid item container xs={12} style={{height:'fit-content', justifyContent:'center'}}>
                <Routes>
                  <Route path="/" element={<NewHomeScreen navigate={navigate} currentEvents={testEvents} />} />
                  {/* <Route path="/" element={<HomeScreen navigate={navigate} />} /> */}
                  <Route
                    path="/auth"
                    element={<AuthScreen navigate={navigate} />}
                  />
                  <Route
                    path="/auth-artist"
                    element={<AuthScreen navigate={navigate} isArtist={true} />}
                  />
                  <Route
                    path="/events"
                    element={<ShowsScreen navigate={navigate} />}
                  />
                  <Route
                    path="/events/:id"
                    element={<EventScreen navigate={navigate} />}
                  />
                  <Route
                    path="/events/:id/success"
                    element={<EventScreen navigate={navigate} />}
                  />
                  <Route
                    path="/events/:id/:artistId"
                    element={<EventScreen navigate={navigate} />}
                  />
                  <Route
                    path="/events/:id/:artistId/success"
                    element={<EventScreen navigate={navigate} />}
                  />
                  <Route
                    path="/account"
                    element={<ProfileScreen navigate={navigate} />}
                  />
                  <Route
                    path="/settings"
                    element={<SettingsScreen navigate={navigate} />}
                  />
                  <Route
                    path="/tickets"
                    element={<MyTicketsScreen navigate={navigate} />}
                  />
                  <Route
                    path="/dashboard"
                    element={<DashboardScreen navigate={navigate} />}
                  />
                  <Route
                    path="/dashboard/success-onboarding"
                    element={
                      <DashboardScreen navigate={navigate} isOnboarded={true} />
                    }
                  />
                  <Route
                    path="/admin"
                    element={<AdminScreen navigate={navigate} />}
                  />
                  <Route
                    path="/notifications"
                    element={<NotificationScreen navigate={navigate} />}
                  />
                  <Route
                    path="/support"
                    element={<SupportScreen navigate={navigate} />}
                  />
                  <Route
                    path="/:eventId/:userId/:ticketId"
                    element={<CheckInTicket />}
                  />
                  <Route
                    path="/invite/:eventId/:artistId"
                    element={<InvitePromoter />}
                  />
                  {/* <Route
                    path="/artists"
                    element={<ArtistsScreen />}
                  /> */}
                  <Route
                    path="/artists/:artistId"
                    element={<ArtistProfileScreen />}
                  />

                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/terms-of-service" element={<TermsOfService />} />
                  <Route path="/faqs" element={<FAQs />} />
                  <Route path="/test" element={<TestScreen />} />
                </Routes>
              </Grid>


              {/* <Grid
                item
                container
                xs={11}
                sx={{ display: { sx: "flex", md: "none" } }}
                style={{
                  position: "absolute",
                  bottom: "2%",
                  left: "50%",
                  transform: "translate(-50%, 0%)",
                  background: "#00000025",
                  boxShadow: "2px 2px 6px #00000050",
                  borderRadius: 12,
                  overflow: "hidden",
                  zIndex: 100,
                }}
              >
                <BottomNavBar navigate={navigate} />
              </Grid> */}

              <Footer />
            </Grid>
          )}
        </div>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default App;
