import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Modal,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Tooltip,
} from "@mui/material";
import { AppContext } from "../App";
import { SwapVert } from "@mui/icons-material";
import { db } from "../config/firebaseConfig";

const ManageCustomers = ({ customers }) => {
  const {
    defaultTheme,
    userData,
    setUserData,
    dispatch,
    setSuccess,
    setInfo,
    setError,
    setMessage,
  } = useContext(AppContext);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedCustomers, setSortedCustomers] = useState(customers);
  const [comedians, setComedians] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const handleSortBy = (value) => {
    if (value === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(value);
      setSortOrder("asc");
    }
  };

  useEffect(() => {
    if (sortBy) {
      if (sortOrder === "asc") {
        setSortedCustomers(
          customers.sort((a, b) => {
            if (sortBy === "Name") {
              return a.name.localeCompare(b.name);
            } else if (sortBy === "Email") {
              return a.email.localeCompare(b.email);
            } else if (sortBy === "Phone") {
              return a.phone.locale.compareTo(b.phone.locale);
            } else if (sortBy === "Orders") {
              return a.orders.length - b.orders.length;
            } else if (sortBy === "Amount") {
              return a.totalSpent - b.totalSpent;
            }
          })
        );
      } else {
        setSortedCustomers(
          customers.sort((a, b) => {
            if (sortBy === "Name") {
              return b.name.localeCompare(a.name);
            } else if (sortBy === "Email") {
              return b.email.localeCompare(a.email);
            } else if (sortBy === "Phone") {
              return b.phone.locale.compareTo(a.phone.locale);
            } else if (sortBy === "Orders") {
              return b.orders.length - a.orders.length;
            } else if (sortBy === "Amount") {
              return b.totalSpent - a.totalSpent;
            }
          })
        );
      }
    }
    console.log("sortedCustomers", sortedCustomers);
  }, [sortBy, sortOrder]);

  useEffect(() => {
    if (userData?.uid === "gScgxHKJRfNCg1YKhvpUyyCJtR42" && !comedians) {
      db.collection("promoters")
        .doc("gScgxHKJRfNCg1YKhvpUyyCJtR42")
        .collection("comedians")
        .get()
        .then((querySnapshot) => {
          const dbComedians = querySnapshot.docs.map((doc) => doc.data());
          setComedians(dbComedians);
          // console.log("comedians", dbComedians)
        });
    }
  }, [userData, comedians]);

  return (
    <Grid item container xs={12}>
      <Grid item container xs={12} style={{ padding: 10 }}>
        <Grid
          item
          container
          xs={12}
          md={12}
          style={{
            padding: 10,
            borderRadius: 8,
            border: "1px solid lightgrey",
          }}
        >
          {/* <Typography>Manage Customers</Typography> */}
          <Typography>
            Manage{" "}
            {userData?.uid === "gScgxHKJRfNCg1YKhvpUyyCJtR42"
              ? "People"
              : "Customers"}
          </Typography>

          {userData?.uid === "gScgxHKJRfNCg1YKhvpUyyCJtR42" ? (
            <Grid item container xs={12} style={{}}>
              <Button
                variant={tabValue === 0 ? "contained" : "outlined"}
                onClick={() => {
                  setTabValue(0);
                }}
              >
                Customers
              </Button>
              <Button
                variant={tabValue === 1 ? "contained" : "outlined"}
                style={{ marginLeft: 5 }}
                onClick={() => {
                  setTabValue(1);
                }}
              >
                Comedians
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={12}
        style={{
          minHeight: "60dvh",
          padding: 10,
        }}
      >
        <Grid
          item
          container
          xs={12}
          md={12}
          style={{
            height: "fit-content",
            border: "1px solid lightgrey",
            borderRadius: 8,
            padding: 10,
          }}
        >
          <Grid item container xs={12} md={12} style={{ padding: 10 }}>
            <Typography
              variant="caption"
              style={{
                width: "100%",
                fontWeight: "bold",
                color: "grey",
                marginBottom: 10,
              }}
            >
              {tabValue === 0 ? "All Customers" : "All Comedians"}
            </Typography>
            {tabValue === 0 && (
              <>
                {customers.length > 0 ? (
                  <TableContainer
                    // component={Paper}
                    style={{
                      border: "1px solid lightgrey",
                      borderRadius: 8,
                    }}
                  >
                    <Table sx={{ width: "100%" }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Grid item container xs={12} alignItems={"center"}>
                              Name{" "}
                              {
                                <Tooltip
                                  title={
                                    sortBy !== "Name"
                                      ? "Sort by Name"
                                      : sortOrder === "desc"
                                      ? "Sort Asc"
                                      : "Sort Desc"
                                  }
                                >
                                  <SwapVert
                                    style={{
                                      marginLeft: 5,
                                      cursor: "pointer",
                                      color: "grey",
                                    }}
                                    onClick={() => handleSortBy("Name")}
                                  />
                                </Tooltip>
                              }
                            </Grid>
                          </TableCell>
                          <TableCell>
                            <Grid
                              item
                              container
                              xs={12}
                              alignItems={"center"}
                              justifyContent={"flex-end"}
                            >
                              Email{" "}
                              {
                                <Tooltip
                                  title={
                                    sortBy !== "Email"
                                      ? "Sort by Email"
                                      : sortOrder === "desc"
                                      ? "Sort Asc"
                                      : "Sort Desc"
                                  }
                                >
                                  <SwapVert
                                    style={{
                                      marginLeft: 5,
                                      cursor: "pointer",
                                      color: "grey",
                                    }}
                                    onClick={() => handleSortBy("Email")}
                                  />
                                </Tooltip>
                              }
                            </Grid>
                          </TableCell>
                          <TableCell align="right">
                            <Grid
                              item
                              container
                              xs={12}
                              alignItems={"center"}
                              justifyContent={"flex-end"}
                            >
                              Phone{" "}
                              {
                                <Tooltip
                                  title={
                                    sortBy !== "Phone"
                                      ? "Sort by Phone"
                                      : sortOrder === "desc"
                                      ? "Sort Asc"
                                      : "Sort Desc"
                                  }
                                >
                                  <SwapVert
                                    style={{
                                      marginLeft: 5,
                                      cursor: "pointer",
                                      color: "grey",
                                    }}
                                    onClick={() => handleSortBy("Phone")}
                                  />
                                </Tooltip>
                              }
                            </Grid>
                          </TableCell>
                          <TableCell align="right">
                            <Grid
                              item
                              container
                              xs={12}
                              alignItems={"center"}
                              justifyContent={"flex-end"}
                            >
                              Orders{" "}
                              {
                                <Tooltip
                                  title={
                                    sortBy !== "Orders"
                                      ? "Sort by Orders"
                                      : sortOrder === "desc"
                                      ? "Sort Asc"
                                      : "Sort Desc"
                                  }
                                >
                                  <SwapVert
                                    style={{
                                      marginLeft: 5,
                                      cursor: "pointer",
                                      color: "grey",
                                    }}
                                    onClick={() => handleSortBy("Orders")}
                                  />
                                </Tooltip>
                              }
                            </Grid>
                          </TableCell>
                          <TableCell align="right">
                            <Grid
                              item
                              container
                              xs={12}
                              alignItems={"center"}
                              justifyContent={"flex-end"}
                            >
                              Amount Spent{" "}
                              {
                                <Tooltip
                                  title={
                                    sortBy !== "Amount"
                                      ? "Sort by Amount"
                                      : sortOrder === "desc"
                                      ? "Sort Asc"
                                      : "Sort Desc"
                                  }
                                >
                                  <SwapVert
                                    style={{
                                      marginLeft: 5,
                                      cursor: "pointer",
                                      color: "grey",
                                    }}
                                    onClick={() => handleSortBy("Amount")}
                                  />
                                </Tooltip>
                              }
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {customers.map((customer) => (
                          <TableRow
                            key={customer.uid}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {customer.name}
                            </TableCell>
                            <TableCell align="right">
                              {customer.email}
                            </TableCell>
                            <TableCell align="right">
                              {customer.phone}
                            </TableCell>
                            <TableCell align="right">
                              {customer.orders.length}
                            </TableCell>
                            <TableCell align="right">
                              ${customer.totalSpent.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography
                    variant="h6"
                    sx={{
                      width: "100%",
                      fontWeight: "bold",
                      color: "grey",
                      textAlign: "center",
                    }}
                  >
                    You do not have any Customers yet.
                  </Typography>
                )}
              </>
            )}
            {tabValue === 1 && (
              <>
                {comedians?.length > 0 ? (
                  <TableContainer
                    // component={Paper}
                    style={{
                      border: "1px solid lightgrey",
                      borderRadius: 8,
                    }}
                  >
                    <Table sx={{ width: "100%" }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Grid item container xs={12} alignItems={"center"}>
                              Name{" "}
                              {
                                <Tooltip
                                  title={
                                    sortBy !== "Name"
                                      ? "Sort by Name"
                                      : sortOrder === "desc"
                                      ? "Sort Asc"
                                      : "Sort Desc"
                                  }
                                >
                                  <SwapVert
                                    style={{
                                      marginLeft: 5,
                                      cursor: "pointer",
                                      color: "grey",
                                    }}
                                    onClick={() => handleSortBy("Name")}
                                  />
                                </Tooltip>
                              }
                            </Grid>
                          </TableCell>
                          <TableCell>
                            <Grid
                              item
                              container
                              xs={12}
                              alignItems={"center"}
                              justifyContent={"flex-end"}
                            >
                              Email{" "}
                              {
                                <Tooltip
                                  title={
                                    sortBy !== "Email"
                                      ? "Sort by Email"
                                      : sortOrder === "desc"
                                      ? "Sort Asc"
                                      : "Sort Desc"
                                  }
                                >
                                  <SwapVert
                                    style={{
                                      marginLeft: 5,
                                      cursor: "pointer",
                                      color: "grey",
                                    }}
                                    onClick={() => handleSortBy("Email")}
                                  />
                                </Tooltip>
                              }
                            </Grid>
                          </TableCell>
                          <TableCell align="right">
                            <Grid
                              item
                              container
                              xs={12}
                              alignItems={"center"}
                              justifyContent={"flex-end"}
                            >
                              Phone{" "}
                              {
                                <Tooltip
                                  title={
                                    sortBy !== "Phone"
                                      ? "Sort by Phone"
                                      : sortOrder === "desc"
                                      ? "Sort Asc"
                                      : "Sort Desc"
                                  }
                                >
                                  <SwapVert
                                    style={{
                                      marginLeft: 5,
                                      cursor: "pointer",
                                      color: "grey",
                                    }}
                                    onClick={() => handleSortBy("Phone")}
                                  />
                                </Tooltip>
                              }
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {comedians?.map((customer) => (
                          <TableRow
                            key={customer.uid}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {customer.name}
                            </TableCell>
                            <TableCell align="right">
                              {customer.email}
                            </TableCell>
                            <TableCell align="right">
                              {customer.phone}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography
                    variant="h6"
                    sx={{
                      width: "100%",
                      fontWeight: "bold",
                      color: "grey",
                      textAlign: "center",
                    }}
                  >
                    You do not have any Comedians yet.
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <div style={{ width: "100%", height: "80px" }} />
      </Grid>
    </Grid>
  );
};

export default ManageCustomers;
