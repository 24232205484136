import React, { useEffect, useState } from "react";
import OnboardingModal from "./OnboardingModal";
import { db } from "../config/firebaseConfig";
import { Grid } from "@mui/material";

const InvitePromoter = () => {
  const [event, setEvent] = useState();
  const [artist, setArtist] = useState();
  const [open, setOpen] = useState(false);

  const paths = window.location.pathname.split("/");
  const eventID = paths[2];
  const artistID = paths[3];

  console.log("paths =>", paths);

  useEffect(() => {
    const fetchData = async () => {
      const eventRef = db.collection("events").doc(eventID);
      const eventData = await eventRef.get();
      const event = eventData.data();
      setEvent(event);
      setOpen(true);
    };
    fetchData();
  }, []);

  return (
    <Grid item container xs={12} style={{ height: "100%" }}>
      {event && event.promoter && (
        <OnboardingModal
          userType={"promoter"}
          invitee={event.promoter}
          onboardingModalOpen={open}
          setOnboardingModalOpen={setOpen}
        />
      )}
    </Grid>
  );
};

export default InvitePromoter;
