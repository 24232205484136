// src/screens/DashboardScreen.js
import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Drawer,
  Button,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  BottomNavigation,
  ListItemIcon,
  BottomNavigationAction,
  Typography,
} from "@mui/material";
import { AppContext } from "../App";
import { motion } from "framer-motion";
import Dashboard from "../components/Dashboard";
import {
  AttachMoneyRounded,
  Close,
  Groups,
  Menu,
  Settings,
  StadiumRounded,
} from "@mui/icons-material";
const DashboardScreen = ({ isOnboarded }) => {
  const { userData, adminUIDs, events, defaultTheme, scrollToTop } =
    useContext(AppContext);
  const [tabValue, setTabValue] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    scrollToTop();
  };

  const getDrawer = () => {
    let amtOFNeededConfirmations = events.filter((event) =>
      event?.artists?.find((x) => x.uid === userData?.uid && !x?.confirmed)
    ).length;
    return (
      <Box
        sx={{
          width: "fit-content",
          height: "100%",
          position: "absolute",
          top: "0px",
          left: "0%",
          zIndex: 10,
          background: "white",
          display: { xs: "none", md: "block" },
          borderRight: "1px solid lightgrey",
        }}
      >
        <motion.div
          style={{ width: 60, background: "white", height: "inherit" }}
          animate={{ width: expanded ? 260 : 60 }}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => setExpanded(!expanded)}
                style={{
                  display: expanded ? "flex" : "block",
                  justifyContent: "flex-end",
                }}
              >
                <ListItemIcon
                  style={{
                    display: expanded ? "flex" : "block",
                    justifyContent: "flex-end",
                  }}
                >
                  {expanded ? <Close /> : <Menu />}
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding={!expanded}>
              <ListItemButton
                style={{
                  borderRadius: 8,
                  background:
                    tabValue === 0 && defaultTheme.palette.primary.main,
                  color: tabValue === 0 && "white",
                }}
                onClick={() => {
                  setTabValue(0);
                  if (expanded) {
                    setExpanded(!expanded);
                  }
                }}
              >
                <ListItemIcon>
                  <motion.div
                    animate={{
                      rotate: tabValue === 0 ? "-15deg" : "0deg",
                      color: tabValue === 0 ? "white" : "grey",
                    }}
                  >
                    <AttachMoneyRounded />
                  </motion.div>
                </ListItemIcon>
                <motion.div
                  animate={{
                    width: expanded ? "fit-content" : 0,
                    display: expanded ? "block" : "none",
                    opacity: expanded ? 1 : 0,
                  }}
                  transition={{
                    delay: expanded ? 0.35 : 0,
                  }}
                >
                  <ListItemText primary="Overview" />
                </motion.div>
              </ListItemButton>
            </ListItem>

            <ListItem
              disablePadding={!expanded}
              style={{ position: "relative" }}
            >
              {amtOFNeededConfirmations > 0 && (
                <Typography
                  variant="caption"
                  style={{
                    padding: "0px 6px",
                    borderRadius: 20,
                    background: "red",
                    border: "2px solid white",
                    top: 0,
                    right: -5,
                    position: "absolute",
                    zIndex: 1,
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {amtOFNeededConfirmations}
                </Typography>
              )}
              <ListItemButton
                style={{
                  borderRadius: 8,
                  background:
                    tabValue === 1 && defaultTheme.palette.primary.main,
                  color: tabValue === 1 && "white",
                }}
                onClick={() => {
                  setTabValue(1);
                  if (expanded) {
                    setExpanded(!expanded);
                  }
                }}
              >
                <ListItemIcon>
                  <motion.div
                    animate={{
                      rotate: tabValue === 1 ? "-15deg" : "0deg",
                      color: tabValue === 1 ? "white" : "grey",
                    }}
                  >
                    <StadiumRounded />
                  </motion.div>
                </ListItemIcon>
                <motion.div
                  animate={{
                    width: expanded ? "fit-content" : 0,
                    display: expanded ? "block" : "none",
                    opacity: expanded ? 1 : 0,
                  }}
                  transition={{
                    delay: expanded ? 0.35 : 0,
                  }}
                >
                  <ListItemText primary="Events" />
                </motion.div>
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding={!expanded}>
              <ListItemButton
                style={{
                  borderRadius: 8,
                  background:
                    tabValue === 2 && defaultTheme.palette.primary.main,
                  color: tabValue === 2 && "white",
                }}
                onClick={() => {
                  setTabValue(2);
                  if (expanded) {
                    setExpanded(!expanded);
                  }
                }}
              >
                <ListItemIcon>
                  <motion.div
                    animate={{
                      rotate: tabValue === 2 ? "-15deg" : "0deg",
                      color: tabValue === 2 ? "white" : "grey",
                    }}
                  >
                    <Groups />
                  </motion.div>
                </ListItemIcon>
                <motion.div
                  animate={{
                    width: expanded ? "fit-content" : 0,
                    display: expanded ? "block" : "none",
                    opacity: expanded ? 1 : 0,
                  }}
                  transition={{
                    delay: expanded ? 0.35 : 0,
                  }}
                >
                  <ListItemText primary="Customers" />
                </motion.div>
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding={!expanded}>
              <ListItemButton
                style={{
                  borderRadius: 8,
                  background:
                    tabValue === 3 && defaultTheme.palette.primary.main,
                  color: tabValue === 3 && "white",
                }}
                onClick={() => {
                  setTabValue(3);
                  if (expanded) {
                    setExpanded(!expanded);
                  }
                }}
              >
                <ListItemIcon>
                  <motion.div
                    animate={{
                      rotate: tabValue === 3 ? "-15deg" : "0deg",
                      color: tabValue === 3 ? "white" : "grey",
                    }}
                  >
                    <Settings />
                  </motion.div>
                </ListItemIcon>
                <motion.div
                  animate={{
                    width: expanded ? "fit-content" : 0,
                    display: expanded ? "block" : "none",
                    opacity: expanded ? 1 : 0,
                  }}
                  transition={{
                    delay: expanded ? 0.35 : 0,
                  }}
                >
                  <ListItemText primary="Settings" />
                </motion.div>
              </ListItemButton>
            </ListItem>
          </List>
        </motion.div>
      </Box>
    );
  };

  const getNavBar = () => {
    let amtOFNeededConfirmations = events.filter((event) =>
      event?.artists?.find((x) => x.uid === userData?.uid && !x?.confirmed)
    ).length;
    // let amtOFNeededConfirmations = 2;
    return (
      <Box
        sx={{
          width: "100dvw",
          height: "60px",
          position: "sticky",
          top: "calc(100dvh - 60px)",
          left: "0%",
          zIndex: 10,
          display: { xs: "block", md: "none" },
          borderRight: "1px solid lightgrey",
          background: "white",
        }}
      >
        {/* <div style={{ width: "100%", height: "100%", position: "relative" }}> */}
        {amtOFNeededConfirmations > 0 && (
          <Typography
            variant="caption"
            style={{
              padding: "0px 6px",
              borderRadius: 20,
              background: "red",
              border: "2px solid white",
              top: 0,
              left: "40%",
              position: "absolute",
              zIndex: 1,
              fontWeight: "bold",
              color: "white",
            }}
          >
            {amtOFNeededConfirmations}
          </Typography>
        )}
        <BottomNavigation
          sx={{ width: "100%" }}
          value={tabValue}
          onChange={handleChange}
        >
          <BottomNavigationAction
            label="Overview"
            value={0}
            icon={<AttachMoneyRounded />}
          />
          <BottomNavigationAction
            style={{ position: "relative" }}
            label="Events"
            value={1}
            icon={<StadiumRounded />}
          ></BottomNavigationAction>
          <BottomNavigationAction
            label="Customers"
            value={2}
            icon={<Groups />}
          />
          <BottomNavigationAction
            label="Settings"
            value={3}
            icon={<Settings />}
          />
        </BottomNavigation>
        {/* </div> */}
      </Box>
    );
  };

  return (
    <Box
      sx={{ width: "100%", position: "relative", height: "fit-content" }}
    >
      {getDrawer()}
      {getNavBar()}
      <Dashboard tabValue={tabValue} isOnboarded={isOnboarded} />
    </Box>
  );
};

export default DashboardScreen;
