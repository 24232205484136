// src/screens/AdminScreen.js
import React, {useState, useEffect} from "react";
import { Box, Grid, Select, Button, MenuItem, Menu, Typography } from "@mui/material";
import ManageEvents from "../components/ManageEvents";
import SalesAnalytics from "../components/SalesAnalytics";
import {db} from '../config/firebaseConfig'

const collections = ['admins', 'artists', 'promoters', 'users', 'events']
const actions = ['copy']

const AdminScreen = () => {
  const tabs = ['Data Migration', 'Users', 'Event Management', 'Coupon Codes', 'Test Features']
  const subCollections = ['orders - Artists', 'payouts - Artists', ]
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const [selectedCollection1, setSelectedCollection1] = useState('')
  const [selectedSubCollection, setSelectedSubCollection] = useState('')
  const [selectedCollection2, setSelectedCollection2] = useState('')
  const [selected1Docs, setSelected1Docs] = useState([])
  const [selected2Docs, setSelected2Docs] = useState([])

  const handleCollectionsSelection = async (set, selection) => {
    if(set === 1) {
      setSelectedCollection1(selection)
      await db.collection(selection).get().then((query) => {
        if(!query.empty){
          setSelected1Docs(query.docs.map((doc) => doc.data()))
        }
        return
      })
    }else{
      setSelectedCollection2(selection)
      await db.collection(selection).get().then((query) => {
        if(!query.empty){
          setSelected2Docs(query.docs.map((doc) => doc.data()))
        }
        return
      })
    }
  }

  const applyChanges = async () => {
    try {
      const batch = db.batch();
  
      // Loop through the documents in selected1Docs and prepare batch writes
      selected1Docs.forEach((doc) => {
        const docRef = db.collection(selectedCollection2).doc(doc.uid); // Use doc.uid as the new doc ID
        batch.set(docRef, doc); // Add document data to the batch operation
  
        // Uncomment this block if you want to delete the documents from selectedCollection1
        /*
        const deleteRef = db.collection(selectedCollection1).doc(doc.uid); // Reference to the document in the first collection
        batch.delete(deleteRef); // Add delete operation to the batch
        */
      });
  
      // Commit the batch operation
      await batch.commit();
  
      console.log("Documents successfully copied to the new collection!");
  
      // Uncomment the following line if you need confirmation that deletion is enabled
      // console.log("Documents were also deleted from the original collection.");
    } catch (error) {
      console.error("Error updating database:", error);
    }
  };



  return (
    <Grid item container xs={12} sx={{ padding: "1rem", justifyContent:'center' }}>
      {/* <ManageEvents />
      <SalesAnalytics /> */}
      <Grid item container xs={12} style={{padding:20}}>
        <Grid item container xs={12}>
        { tabs.map((tab, idx) => {
          return (
            <Button 
            size='small'
            variant={selectedTab === tab ? 'contained' : 'outlined'}
            sx={{mr:1}}
            onClick={() => setSelectedTab(tab)}>
              {tab}
            </Button>
          )
        })}
        </Grid>
       <Typography variant='h5' style={{fontWeight:'bold', margin:'20px 0px'}}>{selectedTab}</Typography>

       {selectedTab === tabs[0] &&
       <Grid item container xs={12}>
        { subCollections.map((tab, idx) => {
          return (
            <Button 
            size='small'
            variant={selectedTab === tab ? 'contained' : 'outlined'}
            sx={{mr:1}}
            onClick={() => setSelectedSubCollection(tab)}>
              {tab}
            </Button>
          )
        })}
        </Grid>
       }
      </Grid>

      {selectedTab === tabs[0] &&
      <Grid item container xs={10} md={6}>
                <Typography variant="body1">Category: {selectedCollection1} - {selected1Docs.length}</Typography>
                <Select
                  fullWidth
                  size='small'
                  labelId="eventFilter-select-label"
                  label={`Collection 1: ${selectedCollection1}`}
                  name="collection 1"
                  value={selectedCollection1}
                  //onChange={(e) => handleCollectionsSelection(e, "event")}
                  style={{ marginBottom: 20, width:'100%' }}
                >
                  {collections.map((item) => {
                      return (
                        <MenuItem value={item} key={item}
                        onClick={()=> handleCollectionsSelection(1, item)}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                </Select>

                <Typography variant="body1">Category: {selectedCollection2} - {selected2Docs.length}</Typography>
                <Select
                  fullWidth
                  size='small'
                  labelId="eventFilter-select-label"
                  label={`Collection 1: ${selectedCollection2}`}
                  name="collection 1"
                  value={selectedCollection2}
                  //onChange={(e) => handleCollectionsSelection(e, "event")}
                  style={{ marginBottom: 20 }}
                >
                  {collections.map((item) => {
                      return (
                        <MenuItem value={item} key={item}
                        onClick={()=> handleCollectionsSelection(2, item)}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                </Select>
                <Button fullWidth variant='outlined' onClick={applyChanges}>Apply Changes</Button>

      </Grid>
      }


      
    </Grid>
  );
};

export default AdminScreen;
