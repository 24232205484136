// src/components/Checkout.js
import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  Grid,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  IconButton,
  Tabs,
  Tab,
  CircularProgress,
  InputBase,
  MenuItem,
} from "@mui/material";
import { AppContext } from "../App";
import {
  ArrowBackIos,
  ArrowLeft,
  Close,
  Info,
  InfoOutlined,
  KeyboardArrowLeft,
  Payment,
} from "@mui/icons-material";
import OrderSummary from "./OrderSummary";
import Login from "./Login";
import SignUp from "./SignUp";
import firebase, { db } from "../config/firebaseConfig";
import { v4 as uuidv4 } from "uuid";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, AddressElement } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import axios from "axios";
import Toast from "./Toast";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

// const stripePromise = loadStripe(
//   "pk_test_51IM7l2JOpsxdxVe2jBUVEIs2gYbh42Q47k4LFmBpeHlcZaKj6ZNDD46vjBQrTm7WPDIDtrnFF6AYRlPj0eVQna9t00T3RvP1Ui"
// );

const CheckoutModal = ({
  open,
  setOpen,
  event,
  eventHost,
  eventTickets,
  ticketCounts,
  setTicketCounts,
  total,
  clientSecret,
  setClientSecret,
  showSuccess,
  setShowSuccess,
  artist,
  taxCalculated,
  setTaxCalculated
}) => {
  const { tickets } = event;
  const {
    userData,
    cart,
    defaultTheme,
    artistID,
    setError,
    setSuccess,
    setMessage,
    getTicketFee,
  } = useContext(AppContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [hasAddress, setHasAddress] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cardNumber, setCardNumber] = useState("");
  const [exp, setExp] = useState("");
  const [cvv, setCvv] = useState("");
  const [zip, setZip] = useState("");
  const [userIsSet, setUserIsSet] = useState(false);
  const [loading, setLoading] = useState(false);

  const [displayInfo, setDisplayInfo] = useState(false);
  const [displayAuth, setDisplayAuth] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [calculatedTax, setCalculatedTax] = useState(0)

  const stripePromise = event.test
    ? loadStripe(
        "pk_test_51IM7l2JOpsxdxVe2jBUVEIs2gYbh42Q47k4LFmBpeHlcZaKj6ZNDD46vjBQrTm7WPDIDtrnFF6AYRlPj0eVQna9t00T3RvP1Ui"
      )
    : loadStripe(
        "pk_live_51IM7l2JOpsxdxVe2sgvbNRrXl5wOl7atbigW1Ypva7qXB5JonKekA5nwGlWb5lZI9Mv17DifYkuOGbinq8HanViP00ZlDcHysm"
      );

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const subTotal = ticketCounts
    .map((amt, i) => amt * tickets[i].price)
    .reduce((val, acc) => {
      return val + acc;
    }, 0);

  const fees = ticketCounts
    .map((amt, i) => amt * getTicketFee(tickets[i].price))
    .reduce((val, acc) => {
      return val + acc;
    }, 0);

  const handleClose = () => {
    setOpen(false);
  };

  const calculateTax = async () => {

    console.log('CALCULATING TAXES..')


    let lineItems = [
         {
            amount: Math.floor(subTotal * 100),
            reference: `TicketShare tickets: ${event.title}`,
          },
    ] 

    //! Create call to cloud function to calculate tax
    // Set tax states if successful calculation

    try {
      //let testTax = Number((subTotal * .07).toFixed(2))

       const calculateTax = firebase.functions().httpsCallable("calculateTax");
       calculateTax({
        lineItems: lineItems,
        customerID: null
            }).then((res) => {
              console.log("RESULT => ", res);
              setCalculatedTax(res.calculation)
            setTaxCalculated(true)
            });
         

      
      // setCalculatedTax(testTax)
      // setTaxCalculated(true)
      
    } catch (error) {
      console.log('Cant calc tax => ', error)
      setError(true);
      setMessage("Error calculating tax.");
    }
  }

  useEffect(() => {
    if(!taxCalculated){
      // calculateTax()
      setTaxCalculated(true)
    }
    if (!userData.guest && taxCalculated && open) {
      createPaymentIntet();
    }
  }, [open, taxCalculated]);


 

  const calculateStripeFee = (totalCharge) => {
    if (Math.floor(totalCharge) !== 0) {
      const stripePercentage = 0.029;
      const stripeFlatFee = 0.3;

      // Calculate the Stripe fee
      const stripeFee = totalCharge * stripePercentage + stripeFlatFee;
      return Math.round(stripeFee * 100);
    }
  };


  const createPaymentIntet = async () => {
    setUserIsSet(true);

    const generateTicketString = (tickets, ticketCounts) => {
      return tickets
        .map(
          (ticket, index) =>
            `${ticketCounts[index]} ${ticket.name}${
              ticketCounts[index] > 1 ? "s" : ""
            }`
        )
        .join(", ");
    };

    setLoading(true);

    let metadata = {
      eventId: event.id,
      eventName: event.title,
      artistId: artist ? artist.uid : event.artistId,
      isAddedAct: artist ? true : false,
      ticketCounts: JSON.stringify(ticketCounts),
      userId: userData?.uid,
      name:
        userData?.name.toLowerCase() !== "guest"
          ? `${userData.name}`
          : `${firstName} ${lastName}`,
      email: userData?.email ? userData.email : email,
      total: total.toFixed(2),
      test: event.test,
    };

    console.log("THIS IS THE METADATA", metadata);

    const response = await axios
      .post(
        "https://us-central1-ticketshare-16a46.cloudfunctions.net/app/create-payment-intent",
        {
          total: Math.floor(total * 100),
          description: generateTicketString(eventTickets, ticketCounts),
          metadata: metadata,
          test: event.test,
          totalTicketFees: Math.floor(fees * 100) + calculateStripeFee(total),
          //   stripeId: "acct_1PvkKNQsffh7SShx", //! REPLACE WITH PROMOTER STRIPE ID!!
          stripeId: eventHost.stripeId, //! REPLACE WITH PROMOTER STRIPE ID!!
        }
      )
      .catch((err) => {
        console.log(err);
      });
    if (response && response.status === 200) {
      setClientSecret(response.data.clientSecret);
      setLoading(false);
    }
  };

  const createPaymentIntentSplit = async () => {
    setUserIsSet(true);

    const generateTicketString = (tickets, ticketCounts) => {
      return tickets
        .map(
          (ticket, index) =>
            `${ticketCounts[index]} ${ticket.name}${
              ticketCounts[index] > 1 ? "s" : ""
            }`
        )
        .join(", ");
    };

    setLoading(true);
    // Prepare metadata and other details for the event
    const metadata = {
      eventId: event.id,
      eventName: event.title,
      artistId: artist ? artist.uid : event.artistId,
      isAddedAct: artist ? true : false,
      ticketCounts: JSON.stringify(ticketCounts),
      userId: userData?.uid,
      name:
        userData?.name.toLowerCase() !== "guest"
          ? `${userData.name}`
          : `${firstName} ${lastName}`,
      email: userData?.email ? userData.email : email,
      total: total.toFixed(2),
      test: event.test,
    };

    try {
      const response = await axios.post(
        "https://us-central1-ticketshare-16a46.cloudfunctions.net/app/create-payment-intent-split",
        {
          total: Math.floor(total * 100), // total in cents
          description: generateTicketString(eventTickets, ticketCounts),
          metadata: metadata,
          test: event.test,
          totalTicketFees: Math.floor(fees * 100) + calculateStripeFee(total), // TicketShare + Stripe fee in cents
          stripeId: eventHost.stripeId, // Promoter/primary artist's Stripe ID
          artistStripeId: artist?.stripeId, // Artist's Stripe ID
          artistRevenueSplitPercentage: 20, // Default artist split percentage
        }
      );

      if (response && response.status === 200) {
        setClientSecret(response.data.clientSecret);
      }
    } catch (err) {
      console.log("Error:", err);
    }
  };

  //  !NOT BEING USED=======================
  const placeFreeOrder = async () => {
    try {
      const docTicketsLeft = await db
        .collection("events")
        .doc(event.id)
        .get()
        .then((doc) => {
          if (artist) {
            const ticketsLeft = doc
              .data()
              .artists.find((a) => a.uid === artist.uid)
              .assignedTickets.map((ticket) => {
                return ticket.remaining;
              });
            return ticketsLeft;
          } else {
            const ticketsLeft = doc.data().tickets.map((ticket) => {
              return ticket.remaining;
            });
            return ticketsLeft;
          }
        });
      console.log(`There are ${docTicketsLeft} tickets remaining`);

      //map over ticketCounts
      let notEnough = false;
      ticketCounts.forEach((count, idx) => {
        if (count > docTicketsLeft[idx]) {
          notEnough = true;
          setError(true);
          setMessage(
            `We cannot complete your purchase as there are only ${docTicketsLeft[idx]} ${event.tickets[idx].name} tickets left. Please refresh the page and adjust your order`
          );
          return;
        } else {
          console.log(
            `There are ${docTicketsLeft[idx]} ${event.tickets[idx].name} tickets remaining`
          );
        }
      });

      if (notEnough) {
        return;
      }

      let purchaseDate = new Date();
      let allTickets = [];
      ticketCounts.forEach((count, idx) => {
        Array.from({ length: count }).map((_, i) => {
          const ticketId = uuidv4();
          let ticket = {
            eventId: event.id,
            ticketId: ticketId,
            ticketPrice: tickets[idx].price,
            ticketFee: getTicketFee(tickets[idx].price),
            ticketName: tickets[idx].name,
            eventName: event.title,
            eventStartTime: event.startDate,
            eventEndTime: event.endDate,
            eventLocation: event.location,
            eventFlyer: event.image,
            purchaserId: userData.uid,
            purchaserName: userData.name,
            purchaserEmail: userData.email,
            purchaseDate: purchaseDate,
            qrCode:
              window.location.origin +
              "/" +
              event.id +
              "/" +
              userData.uid +
              "/" +
              ticketId,
            artistId: artist ? artist.uid : event.artistId,
            checkedIn: false,
          };
          allTickets.push(ticket);
        });
      });

      //   console.log("Tickets =>", allTickets);

      const order = {
        eventId: event.id,
        eventName: event.title,
        eventDate: event.startDate,
        eventLocation: event.location,
        eventImage: event.image,
        eventDescription: event.description,
        tickets: allTickets,
        orderTotal: total,
        orderNumber: Math.floor(Math.random() * 999999999),
        purchaseDate,
        purchaserId: userData.uid,
        purchaserName: userData.name,
        purchaserEmail: userData.email,
      };


      console.log("The artist => ", artist)
      debugger
      const artistOrdersRef = db
        .collection("artists")
        // .collection("users")
        .doc(artist ? artist.uid : artistID ? artistID : event.artistId)
        .collection("orders");

      console.log('artistOrdersRef', artistOrdersRef)
      debugger
      await artistOrdersRef.add(order);

      const eventOrdersRef = db
        .collection("events")
        .doc(event.id)
        .collection("orders");
      await eventOrdersRef.add(order);

      let eventCopy = { ...event };
      let ticketsCopy = eventCopy.tickets;
      let updatedTickets = ticketsCopy.map((t, idx) => {
        t.remaining -= ticketCounts[idx];
        t.sold += ticketCounts[idx];
        return t;
      });
      //   console.log("Updated tickets => ", updatedTickets);
      await db.collection("events").doc(event.id).update({
        tickets: updatedTickets,
      });

      allTickets.forEach((ticket) => {
        db.collection("users")
          .doc(userData.uid)
          .collection("tickets")
          .add(ticket);
      });

      setSuccess(true);
      setLoading(false);
      setMessage("Your order was processed successfully");
    } catch (error) {
      console.log(error);
      setError(true);
      setLoading(false);
      setMessage("There was an error processing your purchase");
    }
  };
  // !====================================

  useEffect(() => {}, [clientSecret]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <>
        <Toast />
        <Grid
          item
          container
          xs={12}
          md={8}
          lg={6}
          sx={{
            position: "absolute",
            bottom: { md: "50%", xs: "0%" },
            left: "50%",
            transform: {
              xs: "translate(-50%, 0%)",
              md: "translate(-50%, 50%)",
            },
            //   padding: "20px",
            background: "white",
            borderRadius: { xs: "12px 12px 0px 0px", md: "12px" },
            outline: "none",
          }}
        >
          <Grid
            item
            container
            xs={12}
            md={8}
            style={{ borderRight: "1px solid lightgrey" }}
          >
            <Grid
              item
              container
              xs={12}
              md={12}
              style={{
                textAlign: "center",
                borderBottom: "1px solid lightgrey",
                justifyContent: "space-between",
                height:'fit-content'
              }}
            >
              <IconButton style={{width:40, height:40}}>
                <KeyboardArrowLeft onClick={handleClose} />
              </IconButton>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                Checkout
              </Typography>
              <IconButton style={{width:40, height:40}}>
                <Close onClick={handleClose} />
              </IconButton>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sx={{
                maxHeight: { xs: "80dvh", md: "70dvh" },
                overflowY: "scroll",
                padding: { xs: "20px", md: "20px 8%" },
              }}
            >
              <Typography
                variant="h5"
                style={{ width: "100%", fontWeight: "bold" }}
              >
                Billing Information
              </Typography>
              <Grid
                item
                container
                xs={12}
                style={{ marginTop: 20, justifyContent: "space-between" }}
              >
                <Typography variant="caption">
                  {userData && !userData.guest ? (
                    `Logged in as, ${userData.name.split(" ")[0]}`
                  ) : (
                    <>
                      <span
                        onClick={() => setDisplayAuth(true)}
                        style={{
                          color: defaultTheme.palette.info.main,
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                      >
                        Login
                      </span>{" "}
                      for a faster experience.
                    </>
                  )}
                </Typography>
                <Typography variant="caption">
                  {userData && !userData.guest ? "" : "* Required"}
                </Typography>
              </Grid>

              <Grid
                item
                container
                xs={12}
                style={{ marginTop: 20, justifyContent: "space-between" }}
              >
                <Grid item container xs={12} md={6} style={{ padding: 10 }}>
                  <TextField
                  size='small'
                    disabled={userData && !userData.guest}
                    variant="outlined"
                    fullWidth
                    label="First Name"
                    value={
                      userData && !userData.guest
                        ? userData.name.split(" ")[0]
                        : firstName
                    }
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item container xs={12} md={6} style={{ padding: 10 }}>
                  <TextField
                  size='small'
                    disabled={userData && !userData.guest}
                    variant="outlined"
                    fullWidth
                    label="Last Name"
                    value={
                      userData && !userData.guest
                        ? userData.name.split(" ")[1]
                        : lastName
                    }
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item container xs={12} md={6} style={{ padding: 10 }}>
                  <TextField
                  size='small'
                    disabled={userData && !userData.guest}
                    variant="outlined"
                    fullWidth
                    label="Email"
                    value={userData && !userData.guest ? userData.email : email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item container xs={12} md={6} style={{ padding: 10 }}>
                  <TextField
                  size='small'
                    disabled={userData && !userData.guest}
                    variant="outlined"
                    fullWidth
                    label="Confirm Email"
                    value={
                      userData && !userData.guest
                        ? userData.email
                        : confirmEmail
                    }
                    onChange={(e) => setConfirmEmail(e.target.value)}
                    required
                  />
                </Grid>

                {userIsSet && (
                  <Typography
                    variant="h6"
                    style={{ width: "100%", fontWeight: "bold" }}
                  >
                    {subTotal === 0 ? "No Payment Req." : "Pay With"}
                  </Typography>
                )}

                {userIsSet ? (
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      padding: 10,
                      border: "1px solid lightgrey",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <>
                      {subTotal === 0 ? (
                        <Button
                          disabled={
                            (firstName === "" ||
                              lastName === "" ||
                              email === "" ||
                              confirmEmail === "" ||
                              email !== confirmEmail) &&
                            userData.guest
                          }
                          onClick={placeFreeOrder}
                          variant="contained"
                          color="primary"
                        >
                          Complete Order
                        </Button>
                      ) : (
                        <>
                          {!!loading ? (
                            <CircularProgress />
                          ) : (
                            <>
                              {clientSecret && (
                                <Elements
                                  options={options}
                                  stripe={stripePromise}
                                >
                                  <form
                                    style={{ width: "100%", marginBottom: 20 }}
                                  >
                                    <AddressElement
                                      options={{
                                        mode: "shipping",
                                        defaultValues: {
                                          name:
                                            userData?.name &&
                                            userData?.name.toLowerCase() !==
                                              "guest"
                                              ? userData.name
                                              : firstName + " " + lastName,
                                          autocomplete: {
                                            mode: "google_maps_api",
                                            apiKey:
                                              process.env
                                                .REACT_APP_GOOGLE_MAPS_API,
                                          },
                                        },
                                      }}
                                      onChange={(event) => {
                                        if (event.complete) {
                                          // Extract potentially complete address
                                          const address = event.value.address;
                                          console.log(
                                            "THIS IS THE ADDRESS",
                                            address
                                          );
                                          setHasAddress(true);
                                        }
                                      }}
                                    />
                                  </form>

                                  {hasAddress && (
                                    <CheckoutForm
                                      firstName={firstName}
                                      lastName={lastName}
                                      email={email}
                                      confirmEmail={confirmEmail}
                                      subTotal={subTotal}
                                      tax={calculatedTax}
                                      fees={fees}
                                      setDisplayInfo={setDisplayInfo}
                                      event={event}
                                      artist={artist}
                                      ticketCounts={ticketCounts}
                                      clientSecret={clientSecret}
                                      setClientSecret={setClientSecret}
                                      loading={loading}
                                      setLoading={setLoading}
                                      showSuccess={showSuccess}
                                      setShowSuccess={setShowSuccess}
                                      open={open}
                                      setOpen={setOpen}
                                      setTicketCounts={setTicketCounts}
                                    />
                                  )}
                                </Elements>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  </Grid>
                ) : (
                  <Button
                    disabled={
                      !firstName ||
                      !lastName ||
                      !email ||
                      !confirmEmail ||
                      email !== confirmEmail ||
                      //   !address ||
                      userIsSet
                    }
                    onClick={createPaymentIntet}
                    variant="contained"
                    color="primary"
                  >
                    {userIsSet ? (
                      <CircularProgress style={{ width: 24, height: 24 }} />
                    ) : (
                      "Next"
                    )}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={4}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                height: "28%",
                background: "lightgrey",
                borderRadius: "0px 12px 0px 0px",
                overflow: "hidden",
              }}
            >
              <img
                src={event.image}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </Grid>
            <OrderSummary
              ticketCounts={ticketCounts}
              items={tickets}
              subTotal={subTotal}
              fees={fees}
              tax={calculatedTax}
            />
          </Grid>

          <Modal
            open={displayAuth}
            onClose={() => {
              setDisplayAuth(false);
            }}
          >
            <Grid
              imte
              container
              xs={11}
              md={5}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: defaultTheme.palette.white,
                padding: 20,
                borderRadius: 12,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tabs value={activeTab} onChange={handleTabChange} centered>
                <Tab label="Login" />
                <Tab label="Sign Up" />
              </Tabs>
              {activeTab === 0 && (
                <Login
                  isNotAuthPage={true}
                  close={() => setDisplayAuth(false)}
                />
              )}
              {activeTab === 1 && (
                <SignUp
                  isNotAuthPage={true}
                  close={() => setDisplayAuth(false)}
                />
              )}
            </Grid>
          </Modal>

          <Modal open={displayInfo} onClose={() => setDisplayInfo(false)}>
            <Grid
              item
              container
              xs={12}
              style={{
                position: "absolute",
                bottom: "0%",
                left: "50%",
                transform: "translate(-50%, 0%)",
                background: "white",
                borderRadius: "12px 12px 0px 0px",
              }}
            >
              <OrderSummary
                ticketCounts={ticketCounts}
                items={tickets}
                subTotal={subTotal}
                fees={fees}
                tax={calculatedTax}
              />
            </Grid>
          </Modal>
        </Grid>
      </>
    </Modal>
  );
};

export default CheckoutModal;
