import React, { useState, useContext, useEffect } from "react";
import { Box, Typography, Grid, TextField, Card, CardContent, Button, IconButton } from "@mui/material";
import {
    TimerOutlined,
    Search,
    Clear,
    ArrowForward,
    ArrowBack,
    FilterList,
    LocationOnRounded,
    MusicNote,
    LocalDining,
    LocalBar,
    SportsFootball,
    BusinessCenter,
    Brush,
  } from "@mui/icons-material";
  import { Link } from "react-router-dom";

import { motion } from "framer-motion";
import { AppContext } from "../App";
import { eventTags, locationsUS } from "../utils/contants"; 
import performer from "../assets/performer.jpeg";
import { EventPreviewCard } from "../components/EventPreviewCard";




const NewHomeScreen = ({currentEvents}) => {
    const { setViewMenu, defaultTheme, navigate, events, adminUIDs, userData, tagsFilters, setTagsFilters, featuredEvents,
      personalizedEvents, } = useContext(AppContext);
    const [searchTerm, setSearchTerm] = useState('')
    const [eventLocation, setEventLocation] = useState('')
    const [selectedLocation, setSelectedLocation] = useState('')

    
      useEffect(() => {
        setViewMenu(true);
        handleClear()
      }, []);

      
      const handleTagClick = (tag) => {
        setTagsFilters((prevTags) =>
          prevTags.includes(tag)
            ? prevTags.filter((t) => t !== tag)
            : [...prevTags, tag]
        );
        navigate('/events')
      };

      const handleClear = () => {
        setSearchTerm("");
        setTagsFilters([]);
        setSelectedLocation("");
      };


      const handleLocationChange = (event) => {
        const selectedLoc = event.target.value;
        console.log(selectedLoc);
        setSelectedLocation(selectedLoc);
        }
    

      const handleSearch = () => {
        navigate('/events')
      }


  return (
    <Grid item container xs={12} style={{ height: "fit-content", zIndex: 1 }}>
      {/* HERO */}
      <Grid item container xs={12} sx={{height:{xs:'80dvh', md:'70dvh'}, position:'relative', justifyContent:'center', alignItems:'center'}}>
        <img src={performer} alt='' style={{position:'absolute', zIndex:0, width:'100%', height:'100%', objectFit:'cover', top:0, left:0 }} />
        
        <Box sx={{height:'fit-content', width:'100%', display:'flex', flexFlow:'column', alignItems:'center',  zIndex:1}}>
            <Typography variant='h4' sx={{fontWeight:'bold', color:defaultTheme.palette.white, mb:2, textShadow:'2px 2px 5px black'}}>Discover events near you.</Typography>
            <Grid item
        container
        xs={12}
        sx={{
          padding: 2,
          //   marginBottom: "20px",
          justifyContent: { xs: "flex-end", md: "center" },
        }}>
            <Box sx={{width: { xs: "100%", md: "calc(100% - 200px)" },
            marginRight: { xs: 0, md: "10px" }, display:'flex', flexFlow:'row', alignItems:'center', justifyContent:'center'}}>
            <TextField
            label="Search Events"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: "60%",
             "& .MuiOutlinedInput-root": {
                    backgroundColor:'white',
                    }
         }}
            InputProps={{
              style: { borderRadius: "50px 0px 0px 50px" },
              endAdornment: searchTerm.length > 0 && (
                <IconButton onClick={handleClear} color="primary">
                  <Clear />
                </IconButton>
              ),
            }}
          />
          <TextField
            select
            // label="Location"
            value={selectedLocation}
            onChange={handleLocationChange}
            variant="outlined"
            size="small"
            sx={{ width: "40%" ,
            "& .MuiOutlinedInput-root": {
                    backgroundColor:'white',
                    } }}
            SelectProps={{
              native: true,
            }}
            InputProps={{
              style: { borderRadius: "0px 50px 50px 0px" },
              startAdornment: (
                <LocationOnRounded style={{ color: "lightgrey" }} />
              ),
            }}
          >
            <option value="">All Locations</option>
            {locationsUS.map((location) => (
              <option key={location.name} value={location.value}>
                {location.name}
              </option>
            ))}
          </TextField>

            </Box>
            <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<Search />}
                      onClick={handleSearch}
                      sx={{
                        borderRadius: "50px",
                        height: "36px",
                        marginTop: { xs: 1, md: 0 },
                      }}
                    >
                      Search
                    </Button>
        </Grid>
        </Box>
      </Grid>

      {/* FEATURED EVENTS SECTION */}
      <Grid item container xs={12} sx={{padding:'32px 16px', minHeight:{xs:'80dvh', md:'70dvh', lg:'60dvh'}, position:'relative'}}>
        <Typography variant='h6' sx={{fontWeight:'bold', width:'100%', textAlign:'left'}}>
            Featured & Recommended Events
        </Typography>

        <Grid container>
        {featuredEvents.length > 0 ? (
          featuredEvents.slice(0,4).map((event) => {
            const lowestTicket = event?.tickets?.sort((a,b) => a.price - b.price)[0]?.price
            return (
            <Grid
              item
              xs={11}
              md={4}
              lg={3}
              key={event.id}
              style={{ padding: 10 }}
            >
              <motion.div whileHover={{scale:.98, opacity:.95, cursor:'pointer', boxShadow:'3px 3px 15px lightgrey'}}>
                <Card>
                  <CardContent style={{ padding: 0 }}>
                    <Grid
                      item
                      container
                      xs={12}
                      sx={{ height: { xs: 150, md: "25dvh" } }}
                    >
                      <img
                        src={event.image}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      sx={{
                        p: 2,
                        height: { xs: "30dvh", md: "25dvh" },
                        position: "relative",
                      }}
                    >
                      <Grid item container xs={12} sx={{ height: "fit-content" }}>
                        <Typography variant="h6" style={{ textAlign: "left", width:'100%' }}>
                          {event.title}
                        </Typography>
                        <Typography variant="body2" style={{ textAlign: "left", width:'100%' }}>
                          {/* {new Date(event.startDate.seconds * 1000).toLocaleDateString()} | {event.location} */}
                          {new Date(event.startDate).toLocaleDateString("en-US",{month: "short", day: "numeric", year:'2-digit'})} | {event.location}
                        </Typography>
                        
                        <Typography
                          style={{ textAlign: "left", fontWeight:'bold', color: defaultTheme.palette.secondary.main  }}
                        >
                          {lowestTicket === 0 ? 'FREE' : `From $${lowestTicket} +`}
                        </Typography>
                      </Grid>
                      <Button
                        variant="contained"
                        size="small"
                        fullWidth
                        sx={{
                          marginTop: 1,
                          height: "38px",
                          
                          borderRadius: 40,
                        }}
                        component={Link}
                        to={`/events/${event.title
                          .toLowerCase()
                          .split(" ")
                          .join("-")}_ev_${event.id}`}
                      >
                        View Details
                      </Button>
                    
                    </Grid>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          )}
        )
        ) : (
          <Typography variant="h6">No events found.</Typography>
        )}
      </Grid>

      </Grid>

      {/* CATEGORIES SECTION */}
      <Grid item container xs={12} sx={{padding:'32px 16px', height:'fit-content', position:'relative'}}>
        <Typography variant='h6' sx={{fontWeight:'bold', width:'100%', textAlign:'left', mb:3}}>
            Popular Categories
        </Typography>

        <Grid container>
          {['Music', 'Food & Drink', 'Nightlife', 'Sports', 'Business', 'Arts',].map((category) => {
            let icon = null
            switch (category) {
                case 'Music':
                    icon = <MusicNote style={{width:30, height:30, color:defaultTheme.palette.secondary.main}}/>
                    break;
                case 'Nightlife':
                    icon = <LocalBar style={{width:30, height:30, color:defaultTheme.palette.secondary.main}}/>
                    break;
                case 'Food & Drink':
                    icon = <LocalDining style={{width:30, height:30, color:defaultTheme.palette.secondary.main}}/>
                    break;
                case 'Sports':
                    icon = <SportsFootball style={{width:30, height:30, color:defaultTheme.palette.secondary.main}}/>
                    break;
                case 'Business':
                    icon = <BusinessCenter style={{width:30, height:30, color:defaultTheme.palette.secondary.main}}/>
                    break;
                case 'Arts':
                    icon = <Brush style={{width:30, height:30, color:defaultTheme.palette.secondary.main}}/>
                    break;
                
                default:
                    break;
            }
            return (
                <motion.div 
                onClick={()=>handleTagClick(category)}
                style={{margin:10, borderRadius:'10px', boxShadow:'1px 1px 10px grey'}}
                whileHover={{scale:.98, opacity:.95, cursor:'pointer', boxShadow:'3px 3px 15px lightgrey'}}>
                    <Box sx={{width:'100px', height:'100px', display:'flex', flexFlow:'column', justifyContent:'center', alignItems:'center'}}>
                        {icon}
                        <Typography variant='caption' style={{fontWeight:'bold', color:defaultTheme.palette.secondary.main}}>{category}</Typography>
                    </Box>
                </motion.div>
            )
       })
       }
      </Grid>
      </Grid>


      {/* FEATURED EVENTS SECTION */}
      <Grid item container xs={12} sx={{padding:'32px 16px', minHeight:{xs:'80dvh', md:'70dvh', lg:'60dvh'}, position:'relative'}}>
        <Typography variant='h6' sx={{fontWeight:'bold', width:'100%', textAlign:'left'}}>
            Personalized For You
        </Typography>

        <Grid container>
        {personalizedEvents.length > 0 ? (
          personalizedEvents.map((event) => {
            const lowestTicket = event?.tickets?.sort((a,b) => a.price - b.price)[0]?.price
            return (
            <Grid
              item
              xs={11}
              md={4}
              lg={3}
              key={event.id}
              style={{ padding: 10 }}
            >
              <motion.div whileHover={{scale:.98, opacity:.95, cursor:'pointer', boxShadow:'3px 3px 15px lightgrey'}}>
                <EventPreviewCard event={event} lowestTicket={lowestTicket}/>
              </motion.div>
            </Grid>
          )}
        )
        ) : (
          <Typography variant="h6">No events found.</Typography>
        )}
      </Grid>

      </Grid>


    </Grid>
  )
}

export default NewHomeScreen